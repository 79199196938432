@charset "UTF-8";

/// Strips the unit from a number.
///
/// @param {Number (With Unit)} $value
///
/// @example scss - Usage
///   $dimension: strip-units(10em);
///
/// @example css - CSS Output
///   $dimension: 10;
///
/// @return {Number (Unitless)}

@function strip-units($value) {
	@return ($value / ($value * 0 + 1));
}

// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)

$em-base: 16px !default;

@function em($pxval, $base: $em-base) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}
	@if not unitless($base) {
		$base: strip-units($base);
	}

	@return ($pxval / $base) * 1em;
}


// Convert pixels to rems
// eg. for a relational value of 12px write rem(12)
// Assumes $em-base is the font-size of <html>

@function rem($pxval) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}

	$base: $em-base;
	@if not unitless($base) {
		$base: strip-units($base);
	}

	@return ($pxval / $base) * 1rem;
}
