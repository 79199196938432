.end-lp-featured-resource {
    
    &__media {
        margin-bottom: 26px;

        img {
            max-width: 100%;
            object-fit: cover;
        }
    }

    &__title {
        margin-bottom: 26px;
        font-size: rem(24);
    }

    &__description {
        margin-bottom: 22px;
    }

    &__cta {
        &-text {
            font-weight: bold;
            color: $endocrine-dark-navy;
        }

        .see-more-arrow {
            display: inline-block;
            border-right: 2px solid $endocrine-purple;
            border-bottom: 2px solid $endocrine-purple;
            transform: rotate(-45deg);
            width: 8px;
            height: 8px;
        }
    }
}