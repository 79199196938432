.dynamic-content {

  &__filters {
    margin-bottom: 41px;

    label {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5;
      display: inline-block;
      margin-bottom: 4px;
      margin-right: 6px;
    }

    &-wrapper {
      position: relative;
	    border-bottom: 1px solid $hormone-black;
	    padding-bottom: 8px;
	    margin-bottom: 54px;
    }
  }

  &__az {

    ul {
      margin: 0;
    }

    li {
      padding: 0;
      margin: 0 10px 0 0;
      display: inline-block;
      vertical-align: top;

      &:before {
        display: none;
      }

      &:last-child {
        margin-right: 0;
      }

      span,
      a {
        display: block;
      }

      span {
        opacity: .25;
        font-weight: 800;
      }

      a {
        text-decoration: none;
        font-weight: 800;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
