// grid-helpers.scss
// Define grid helpers here - NOTE: prefixed with 'g-'

// outer container
.g-outer-container {
    width: 100%;
}

/// column container - makes an element a outer container by centring it in the viewport, clearing its floats, and setting its `max-width`
/// @author Andrew Rodgers, Shelby Staab, Thoughtbot
/// @link http://thoughtbot.github.io/neat-docs/latest/#outer-container
///
/// NOTE: Do not set the `outer-container()` locally, rather to override this on a per-component basis, use the `max-width` property directly
/// NOTE: To change the `max-width` globally for your grid - set the `$max-width` variable in `_grid-settings.scss`
.g-column-container {
    @include outer-container();
}

// rows
.g-row {
    @include row();
    margin-bottom: rem(30);
}

// omega helper
.g-omega {
    @include omega();
}

// primary column
.g-primary {

}

// secondary column
.g-secondary {

}

.g-truncate {
  margin-bottom: 0;
}
