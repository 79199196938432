.text-banner {
	// @include component-whitespace();
	// padding: 4rem 1rem;
	text-align: center;
	padding: 70px 0 0;
	padding-bottom: rem(80);

	@media(max-width: $bp-max-medium) {
		margin-left: 0;
		width: 100%;
	}

	@media(max-width: $bp-max-small) {
		padding-top: 32px;
		padding-bottom: rem(48);
	}
}

.text-banner__inner {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.text-banner__inner-content {
	width: percentage(10/12);
	margin-left: percentage(1/12);
}

.text-banner__title {
	letter-spacing: -.75px;
}

.text-banner__description {
	font-size: rem(16);
	line-height: 1.5;
	max-width: 779px;
	margin-left: auto;
	margin-right: auto;
}
//
.text-banner__cta {
	margin-top: 12px;
}
