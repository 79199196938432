.utility-nav {
	padding-left: 15px;

	@media(max-width: $bp-max-medium) {
		padding-left: 0;
	}

	ul {
		@include zero-list;
		display: flex;
	}

	li {
		margin-right: rem(25);
		font-weight: 500;
		font-size: 14px;
		padding: 0;

		@media(max-width: $bp-max-medium) {
			margin: 0;
		}

		&:before {
			display: none;
		}

		&:last-child {
			margin-right: 0;
		}

		&.is-active a {
			text-decoration: underline;
		}

		a {
			@media(max-width: $bp-max-medium) {
				display: block;
				padding: 8px 0;
			}
		}
	}
}
