.end-info {
    @include horizontal-container-padding();

    & > * {
        @include outer-container();
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        margin-top: 95px;

        h2 {
            color: #5F2167;
            flex: none;
            width: 20%;

            @media (max-width: $bp-max-medium) {
                font-size: rem(32);
                align-self: flex-start;
            }
        }

        @media (max-width: $bp-max-medium) {
            flex-direction: column;
            width: 100%;

            h2 {
                width: 100%;
            }
        }

        @media (max-width: $bp-max-small) {
            width: 100%;
            margin-top: 50px;
        }
    }

    &__inside {
        padding: 0 60px 90px 60px;

        @media (max-width: $bp-max-large) {
            padding-left: 0;
            padding-right: 0;
        }

        @media (max-width: $bp-max-medium) {
            padding-bottom: 50px;
        }

        @media (max-width: $bp-max-small) {
            padding-bottom: 0;
            margin-bottom: 20px;
        }
    }

    .end-info-body {
        margin-bottom: 70px;

        @media (max-width: $bp-max-medium) {
            margin-bottom: 50px;
        }
    }
}
