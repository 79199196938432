.end-mobile-nav {

}

.end-mobile-nav__mobile-navigation {
  display: block;

  @include media($bp-end-header-min-large) {
    display: none;
  }
}

.end-mobile-nav__primary-navigation {
  background-color: transparentize($endocrine-light-blue, 0.40);
  display: none;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  position: fixed;
    top: 0;
    left: 0;
  width: 100%;
  z-index: 2;
}

.end-mobile-nav__primary-navigation-inner {
  background: $endocrine-navy;
  margin-left: auto;
  margin-right: 50px;
  max-width: calc(100% - 50px);
  padding-bottom: 20px;
  position: relative;
  width: 100%;

  & > * {
    padding-left: 30px;
    padding-right: 30px;
  }

  & .end-mobile-member-login {
    padding: 0;
  }

  @include media($bp-end-header-min-small) {
    width: 325px;
    background: $endocrine-navy;
  }
}

.end-mobile-nav__primary-navigation-close {
  align-items: center;
  background-color: $endocrine-navy;
  border: none;
  color: $endocrine-light-blue;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  margin-top:  -60px;
  outline: none;
  position: sticky;
    // bottom: calc(100% - 120px);
    bottom: calc(100% - 142px);
    left: calc(100% - 50px);
  width: 50px;
  z-index: 3;

  svg {
    display: inline-block;
    fill: currentColor;

      path {
        fill: currentColor;
      }

      polygon {
        fill: currentColor;
      }
  }
}
