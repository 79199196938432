.end-news-grid {
    h6 {
        color: #004a8d;
        text-transform: uppercase;
        margin-bottom: 40px;

        @media (max-width: $bp-max-large) {
            width: 80%;
            margin-left: 0;
            margin-right: auto;
        }

        @media (max-width: $bp-max-medium) {
            width: 100%;
        }

        @media (max-width: $bp-max-small) {
            padding-top: 30px;
            margin-bottom: 20px;
            font-size: rem(16);
        }
    }

    &__content {
        display: flex;

        @media (max-width: $bp-max-large) {
            flex-direction: column;
            width: 80%;
            margin: 0 auto;
        }

        @media (max-width: $bp-max-large) {
            width: 100%;
        }
    }

    .article-container {
        display: flex;
        flex: none;
        flex-direction: column;
        width: calc(30% - 25px);

        .end-article-preview__title {
            font-size: rem(24);
            letter-spacing: -0.25px;
        }

        @media (max-width: $bp-max-large) {
            width: 100%;
        }

        &:not(:last-of-type) {
            padding-right: 50px;
            width: calc(30% + 25px);

            @media (max-width: $bp-max-large) {
                padding-right: 0;
                width: 100%;
            }
        }
    }

    .article-container-few-items {
        display: flex;
        flex-wrap: wrap;

        .end-article-preview.article--1,
        .end-article-preview.article--2 {
            flex: none;
            width: calc(50% - 25px);

            @media (max-width: $bp-max-large) {
                padding-right: 0;
                width: 100%;
            }

            &:not(:last-child) {
                padding-right: 50px;

                @media (max-width: $bp-max-large) {
                    padding-right: 0;
                }
            }

            &:first-child {
                flex: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .article-container-single-item {
        margin: 0 auto;
        width: auto;

        .end-article-preview.article--1 {
            margin: 0 auto;
            max-width: 480px;
            padding-right: 0;
            width: 100%;
        }
    }

    .end-article-preview {
        &.article--1 {
            flex: none;
            padding-right: 50px;
            width: 40%;

            @media (max-width: $bp-max-large) {
                padding-right: 0;
                width: 100%;
            }
        }

        &:first-child:not(.article--1) {
            margin-bottom: 60px;
        }

        @media (max-width: $bp-max-large) {
            margin-bottom: 40px;
        }

        @media (max-width: $bp-max-small) {
            margin-bottom: 30px;
        }
    }
}
