.multiselect-list {
	max-height: rem(200);
	overflow-y: auto;
  	overflow-x: hidden;
  	margin-left: 0;
  	margin-bottom: 0;
  	margin-top: 0;
  	padding: 0 0;
  	list-style: none;

  li {
    cursor: pointer;
		padding-left: 0;
		font-size: rem(14);
		line-height: 1.71;
		position: relative;

		&:not(:last-child) {
			margin-bottom: 6px;
		}

		&:before {
			display: none;
		}

		input {
  		border: 0;
  		clip: rect(0 0 0 0);
  		height: 1px;
  		margin: -1px;
  		overflow: hidden;
  		padding: 0;
  		position: absolute;
  		width: 1px;
		}

		input:checked ~ label::after {
  		display: block;
		}

		input:focus ~ label::before {
  		border-color: $hormone-blue;
		}

		input:checked ~ label {
			font-weight: 800;
		}

		label {
  			position: relative;
			padding: 3px 0 2px 28px;
			line-height: 1.2;
			display: block;
			white-space: initial;

  		&:before {
    		content: '';
    		width: 22px;
    		height: 22px;
    		position: absolute;
    		display: block;
    		background: #fff;
    		top: 0;
				left: 0;
    		border: 2px solid $hormone-dark-blue;
  		}

  		&:after {
    		display: none;
    		content: '';
    		background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.684.02c.307-.056.586.009.835.195.25.186.403.432.461.74.058.306-.01.59-.202.85l-5.96 8.428c-.193.242-.438.39-.735.447a1.01 1.01 0 0 1-.82-.196c-.154-.111-.558-.507-1.21-1.186A99.866 99.866 0 0 1 1.21 7.331l-.893-.949A1.135 1.135 0 0 1 0 5.572c0-.316.115-.585.346-.809.23-.223.499-.33.806-.32.307.009.566.134.777.376l2.852 2.986L9.964.465a1.19 1.19 0 0 1 .72-.446z' fill='%23007DBA' fill-rule='evenodd'/%3E%3C/svg%3E");
    		background-color: transparent;
    		background-repeat: no-repeat;
    		background-position: center center;
    		width: 12px;
    		height: 11px;
    		background-size: cover;
    		position: absolute;
    		left: 5px;
    		top:6px;
		  }
		}

		ul {
			margin-left: 22px;
			overflow: hidden;
		}
	}

	// absolute positioning from u-visuallyhidden
	// causes scroll jump. explicit left/top appears to fix it
	input {
		left: 0;
		top: 0;
	}

	.multiselect-list {
		max-height: none;
		overflow-y: visible;
	}
}
