$form-input-width: 280px;

.form {
    width: calc(100% - 120px);
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;

    @include media($bp-g-max-large) {
        width: calc(100% - 140px);
    }

    @include media($bp-g-max-medium) {
        width: calc(100% - 80px);
    }

    h2 {
        font-weight: 800;
        font-size: 14px;
        letter-spacing: 1.5px;
        line-height: 1.29;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    label {
        clear: both;
        display: inline-block;
        float: left;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 4px;
        margin-right: 6px;
        vertical-align: middle;
    }

    h2 label {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        margin: 0;
    }

    .form-item {
        display: inline-block;
        vertical-align: top;
        margin-right: 32px;
        margin-bottom: 24px;
        max-width: calc(48% - 32px);
        min-width: $form-input-width;
        width: 100%;

        @media (max-width: $bp-max-small) {
            margin-right: 0;
        }

        &--full {
            display: block;
            margin-right: 0;
            max-width: 100%;
        }

        &::after {
            clear: both;
            content: "";
            display: table;
        }
    }

    .form-group {
        margin-bottom: 8px;
        border-top: 1px solid $hormone-black;
        padding-top: 32px;
    }

    .radiobox-list,
    .checkbox-list {
        columns: 2;
        column-gap: 40px;
        margin-bottom: 32px;

        @media (max-width: $bp-max-small) {
            columns: 1;
            column-gap: 0;
        }

        .form-item {
            display: block;
            margin-bottom: 0;
            margin-right: 0;
        }
    }

    input[type="number"],
    input[type="date"],
    input[type="text"] {
        -webkit-appearance: none;
        clear: both;
        color: $hormone-black;
        border: 2px solid #cbd8e0;
        border-radius: 0;
        display: inline-block;
        float: left;
        font-size: rem(16);
        font-weight: 500;
        height: auto;
        min-height: 43px;
        max-width: $form-input-width;
        padding: 0 12px;
        position: relative;
        vertical-align: middle;
        width: 100%;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $endocrine-dark-gray;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $endocrine-dark-gray;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: $endocrine-dark-gray;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: $endocrine-dark-gray;
        }
        &:placeholder {
            color: $endocrine-dark-gray;
        }

        &:focus {
            outline: none;
            border-color: $hormone-blue;
        }
    }

    .form-item--radio,
    .form-item--checkbox {
        font-size: rem(14);
        line-height: 1.71;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        input:checked + label::after {
            display: block;
        }

        input:focus + label::before {
            border-color: $hormone-blue;
        }

        label {
            position: relative;
            padding-left: 30px;
            text-indent: -30px;

            &:before {
                content: "";
                width: 22px;
                height: 22px;
                position: relative;
                display: inline-block;
                background: #fff;
                margin-right: 8px;
                top: 5px;
                border: 2px solid #cbd8e0;
            }

            &:after {
                display: none;
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.684.02c.307-.056.586.009.835.195.25.186.403.432.461.74.058.306-.01.59-.202.85l-5.96 8.428c-.193.242-.438.39-.735.447a1.01 1.01 0 0 1-.82-.196c-.154-.111-.558-.507-1.21-1.186A99.866 99.866 0 0 1 1.21 7.331l-.893-.949A1.135 1.135 0 0 1 0 5.572c0-.316.115-.585.346-.809.23-.223.499-.33.806-.32.307.009.566.134.777.376l2.852 2.986L9.964.465a1.19 1.19 0 0 1 .72-.446z' fill='%23007DBA' fill-rule='evenodd'/%3E%3C/svg%3E");
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center center;
                width: 12px;
                height: 11px;
                background-size: cover;
                position: absolute;
                left: 5px;
                top: 10px;
            }
        }
    }

    .form-item--radio label {
        &:before {
            border-radius: 50%;
        }

        &:after {
            background-image: none;
            background-color: $hormone-dark-blue;
            border-radius: 50%;
            height: 10px;
            left: 6px;
            top: 11px;
            width: 10px;
        }
    }

    .form-item--radio-min {
        margin: 0;

        label {
            font-size: 0;
            margin: 0;
        }
    }

    .interior-container__col:first-child & {
        max-width: 827px;
    }
}

.form__button-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -20px;
    margin-top: -20px;
    padding: 30px 0;
    width: calc(100% + 20px);

    &:last-child {
        padding-bottom: 0;
    }

    & > * {
        flex: none;
        margin-left: 20px;
        margin-top: 20px;
    }

    .form-item + & {
        padding-top: 10px;
    }
}

.select-wrap {
    background: transparent;
    border-radius: 0;
    clear: both;
    display: inline-block;
    float: left;
    height: 43px;
    max-width: $form-input-width;
    position: relative;
    width: 100%;

    select:focus {
        border-color: $hormone-blue;
    }

    select {
        -webkit-appearance: none;
        background: transparent;
        border: none;
        border-radius: 0;
        font-size: 1rem;
        min-height: 43px;
        padding: 0 54px 0 16px;
        position: relative;
        z-index: 2;
        width: 100%;

        &:focus {
            outline: none;
        }

        option {
            color: $hormone-black;
        }
    }

    &__box {
        background-image: linear-gradient(0deg, #cbd8e0, #cbd8e0);
        background-position: top right 40px;
        background-repeat: no-repeat;
        background-size: 2px 100%;
        border: 2px solid #cbd8e0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;

        &:after,
        &:before {
            content: "";
            display: block;
            position: absolute;
            transition: transform 0.35s ease;
            width: 3px;
            height: 10px;
            background: $hormone-blue;
        }

        &:before {
            top: 16px;
            right: 22px;
            transform: rotate(-45deg);
        }

        &:after {
            top: 16px;
            right: 17px;
            transform: rotate(45deg);
        }
    }

    select:focus + &__box {
        background-image: -webkit-linear-gradient(
            bottom,
            $hormone-blue,
            $hormone-blue
        );
        background-image: -o-linear-gradient(
            bottom,
            $hormone-blue,
            $hormone-blue
        );
        background-image: linear-gradient(to top, $hormone-blue, $hormone-blue);
        border-color: $hormone-blue;
    }
}

.field-validation-error,
.validation-summary-errors {
    color: red;
}
