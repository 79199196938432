.child-page-list {
    max-width: 827px;
    padding-left: 60px;
    padding-right: 60px;

    .container--70-30 .container__col:first-child &,
    .container--30-70 .container__col:last-child & {
        @include media($bp-g-max-large) {
            margin-left: auto;
            margin-right: auto;
            width: calc(100% - 140px);
            padding-left: 0;
            padding-right: 0;
        }

        @include media($bp-g-max-medium) {
            width: calc(100% - 80px);
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-bottom: 0;
        width: calc(100% + 40px);

        @media (max-width: $bp-max-small) {
            width: 100%;
            display: block;
        }

        li {
            padding: 0;
            margin: 0 20px 40px;
            display: flex;
            width: calc(50% - 40px);

            @media (max-width: $bp-max-medium) {
                padding-right: 0;
                margin-bottom: 24px;
            }

            @media (max-width: $bp-max-small) {
                margin-bottom: 20px;
                width: 100%;
            }

            &:before {
                display: none;
            }
        }
    }

    &__item {
        background: $hormone-tan;

        a {
            display: block;
            padding: 28px 40px 48px;
            text-decoration: none;

            @media (max-width: $bp-max-small) {
                padding-right: 28px;
                padding-left: 28px;
                padding-bottom: 36px;
            }

            &:hover {
                .child-page-list__item-title {
                    text-decoration: underline;
                }
            }
        }

        &-title {
            font-weight: 800;
            font-size: 24px;
            line-height: 1.25;
            margin: 0;
        }

        &-description {
            font-size: 15px;
            color: black;
            line-height: 1.47;
            margin: 8px 0 0;
        }

        &-content {
            margin-top: 12px;
        }

        &-media + &-content {
            margin-top: 0;
        }
    }
}
