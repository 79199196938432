hr {
    display: block;
    height: 1px;
    border: 0;
    padding: 0;
    width: calc(100% - 120px);
    margin-left: 60px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: $endocrine-light-blue;

    @media(max-width: $bp-max-medium) {
        width: calc(100% - 80px);
        margin-left: 40px;
    }
}