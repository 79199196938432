// end-video-block.scss
// define video styles here

.container--50-50 .container__inner > .end-video-block {
    @include media($bp-g-max-medium) {
      margin-left: auto;
      margin-right: auto;
      width: calc(100% - 80px);
    }
  }

// base
.end-video-block{
    @include component-whitespace();
}

// title
.end-video-block__title{}

// media
.end-video-block__media{}

// media wrapper
// Intrinsic Ratios - http://alistapart.com/article/creating-intrinsic-ratios-for-video
.end-video-block__media-wrapper{
    position: relative;
    padding-bottom: 56.25%; // 16x9 Aspect Ratio
    height: 0;
    overflow: hidden;
    max-width: 100%;

    // video, iframe, object and embed
    video,
    iframe,
    object,
    embed{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-bottom: rem(20);
    }
}

// media placeholder
// we position this appropriately but hide it be default.
// only sitecore authors should see this display.
.end-video-block__media-placeholder{
    display: none;
    width: 100%;
}

// figcaption
.end-video-block__caption{
    padding-top: rem(10);
    padding-bottom: 0;
    font-size: rem(14);
    line-height: 1.45;
    max-width: 700px;
}
