// connect variables
$connect-icon-size: rem(35) !default;
$connect-icon-spacing: rem(4) !default;
$connect-icon-fill: currentColor !default;
$connect-phone-size: rem(24) !default;
$connect-promo-size: rem(24) !default;
$connect-bp-small: em(570px) !default;

.connect__button {
  @media (max-width: $bp-max-large) {
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: 23px;
    margin-right: 30px;
  }

  @media (max-width: em(540)) {
    display: block;
    top: 0;
    margin-right: 0;
  }

  svg {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 59px;
    height: 43px;
    margin-right: 4px;
    top: -6px;
  }
}

.connect__social {
  margin-top: 46px;

  @media (max-width: $bp-max-large) {
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
  }

  @media (max-width: em(540)) {
    display: block;
    margin-top: 46px;
  }

  &-label {
    font-weight: 800;
    font-size: rem(16);
    color: $hormone-green;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}

.social-links {
  ul {
    margin: 0;
  }

  li {
    margin: 0 8px 0 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;

    &:before {
      display: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: white;
    display: inline-block;
    height: 26px;
    text-decoration: none;
    width: 30px;

    .blue-container &:hover,
    .blue-container &:focus {
      color: $hormone-lightest-blue;
    }

    & > svg {
      height: 100%;
      width: 100%;
    }
  }

  svg {
    width: 26px;
    height: 26px;
  }
}

.connect {
  &__donate {
    border-top: 1px solid $hormone-dark-blue;
    margin-top: 32px;
    padding-top: 42px;

    @media (max-width: $bp-max-large) {
      float: right;
      border-top: none;
      margin-top: 12px;
      padding-top: 0;
    }

    @media (max-width: em(750)) {
      float: none;
      border-top: 1px solid $hormone-dark-blue;
      margin-top: 32px;
      padding-top: 42px;
    }
  }

  a.btn.btn--box-arrow:hover,
  a.btn.btn--box-arrow:focus {
    text-decoration: none;
  }

  a:hover .btn__text,
  a:focus .btn__text {
    text-decoration: underline;
  }
}
