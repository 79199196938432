.share-bar {
  text-align: right;
  max-width: 827px;

  @include media($bp-g-max-large) {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 140px);
    max-width: none;
  }

  @include media($bp-g-max-small) {
    display: none;
  }

  @media(max-width: em(374)) {
		position: relative;
		left: -20px;
		width: calc(100% + 40px);
	}

  &__label {
    font-weight: 800;
    font-size: 14px;
    color: #9D968D;
    letter-spacing: 1.5px;
    line-height: 1.29;
    text-transform: uppercase;
  }

  &__label,
  ul,
  li {
    display: inline-block;
    vertical-align: middle;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__label {
    margin-right: 10px;
    position: relative;
    top: -4px;

    @media(max-width: em(600)) {
      display: none;
    }
  }

  li {
    margin-right: 18px;

    @media(max-width: em(600)) {
      margin-right: 10px;
    }

    &:before {
      display: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: block;
    transition: opacity .2s ease;

    &:hover {
      opacity: .6;
    }

    &[aria-label="Email"] {
      color: $endocrine-dark-blue;

      svg {
        width: 26px;
        height: 20px;
      }
    }

    &[aria-label="Facebook"] {
      color: $endocrine-dark-blue;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &[aria-label="Twitter"] {
      color: $endocrine-dark-blue;

      svg {
        width: 30px;
        height: 24px;
      }
    }

    &[aria-label="Pinterest"] {
      color: $endocrine-dark-blue;

      svg {
        width: 26px;
        height: 26px;
      }
    }

    &[aria-label="Print"] {
      color: $endocrine-dark-blue;

      svg {
        width: 26px;
        height: 23px;
      }
    }
	
	&[aria-label="Bluesky"]{
		color: #007DBA;
		
		svg{
			width: 28px;
			height: 28px;
		}
	}
  }
}
.share-bar__30-col {
  margin-top: 15px !important;
  margin-bottom: 30px !important;
  max-width: 100%;
}
// .secondary-nav + .share-bar {
.share-bar {
  margin-top: -24px;
  margin-bottom: 60px;

  @include media($bp-g-max-large) {
    text-align: left;
    margin-top: 0;
  }
}
