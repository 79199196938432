.end-citations {
    color: white;
    margin-bottom: 72px;

    margin-left: auto;
    margin-right: auto;
  
    @include media($bp-g-max-large) {
      width: calc(100% - 140px);
    }
    
    @include media($bp-g-max-medium) {
      width: calc(100% - 80px);
    }
  
    &__trigger {
      @include font(acumin, condensed-bold);
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1.25px;
      height: 40px;
      padding: 4px 20px 4px 58px;
      border: none;
      background: none;
      color: white;
      position: relative;
      background: #246AAA;
      border-radius: 5px;
      transition: border-radius 0.5s;
      
  
      &:hover,
      &:focus,
      &:active {
        outline: none;
        cursor: pointer;
      }

      &:hover {
        background: #2e84d3;
        text-decoration: underline;
      }
  
      span {
        position: absolute;
        width: 50px;
        height: 40px;
        top: 0;
        left: 0;
        border-radius: 5px 0 0 5px;
        background: $endocrine-light-blue;
        clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
  
        &:before,
        &:after {
          content: '';
          width: 12px;
          height: 3px;
          background: white;
          position: absolute;
          top: 18px;
          left: 14px;
          transition: transform .2s ease;
        }
      }
    }
  
    &.is-closed {
  
      .end-citations__trigger span:after {
        transform: rotate(90deg);
      }
    }
  
    &.is-open {
  
      .end-citations__trigger span:after {
        transform: rotate(0deg);
      }
    }
  
    &__content {
      display: none;
      background: #246AAA;
      padding: 22px 32px 22px 58px;
      font-weight: 400;
      font-size: 15px;
      line-height: 1.6;
  
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .js-citations-trigger--bottom-round {
    .js-citations-trigger,
    .js-citations-trigger span {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  