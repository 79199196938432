body.show-gdpr {
    // hide the chat widgets that sit on top of the GDPR banner
    #chat-widget-container,
    #livechat-eye-catcher {
        display: none !important;
    }
}

.end-gdpr-banner {
    background-color: $hormone-navy;
    bottom: 0;
    color: $white;
    padding: 25px 0;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;

    &.is-hidden {
        display: none;
    }

    &__container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        padding: 0 70px 0 20px;
        position: relative;

        p {
            display: inline-block;
            flex-basis: 70%;
            font-size: 14px;
            margin-bottom: 0;
            max-width: 70%;

            @media (max-width: $bp-max-small) {
                flex-basis: 100%;
                max-width: 100%;
            }

            a {
                color: $white;
                text-decoration: underline;
            }
        }
    }

    &__cta-container {
        flex-basis: 30%;
        max-width: 30%;

        .btn {
            float: right;
            padding: 7px 23px;
            width: auto;

            @media (max-width: $bp-max-medium) {
                font-size: 14px;
                padding: 5px 18px;
            }

            @media (max-width: $bp-max-small) {
                float: none;
                margin-top: 20px;
            }
        }
    }

    &__close-button {
        color: $white;
        display: block;
        height: 40px;
        position: absolute;
        right: 20px;
        text-decoration: none;
        top: 50%;
        transform: translateY(-50%);
        transition: color 0.3s ease-in-out;
        width: 40px;

        &:hover {
            color: darken($white, 20);
        }

        &::before {
            content: "+";
            color: currentColor;
            font-size: 28px;
            left: 33%;
            height: 28px;
            position: absolute;
            top: 0;
            transform: rotate(45deg);
            width: 28px;
        }
    }
}
