.end-social-links-header {
    display: none;
    align-items: center;
    // margin-left: auto;
    margin-left: 30px;

  	@include media($bp-end-header-min-large) {
        display: flex;
    }

    .end-mobile-nav__primary-navigation & {
      margin-bottom: 40px;
    }

    ul {
        display: flex;
        // padding: 15px 0 15px;
        font-size: 0; //removes whitespace below icons

        //temp
        margin: 0;

        & .end-social-links-header__link {
          font-size: 14px;
          
          a {
            color: $endocrine-black;
          }
        }

        li {
            padding: 0;

            &:not(:first-child) {
                margin-left: 12px;

              .end-mobile-nav__primary-navigation & {
                margin-left: 20px;
              }
            }

            svg {
                width: 25px;
                height: 22px;
                color: $endocrine-dark-gray;

                .end-mobile-nav__primary-navigation & {
                  color: $white;
                  height: 28px;
                  width: 28px;
                }
            }

            &:before {
                display: none;
            }
        }
    }
}
