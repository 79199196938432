// general.scss
// Define universal plain tag styles here
// NOTE: No classes or IDs!

// color variables
// $base-link-color: $hormone-blue-dark !default;
$base-link-color: $endocrine-dark-navy !default;
$base-hairline-color: $hormone-black !default;

// body element
body {
    overflow-x: hidden;
}

// Annotate breakpoints inside body psuedo-selector
// so they may be read by javascript
// More details: https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
body::before {
    content: "large";
    display: none;

    @media (max-width: $bp-max-medium) {
        content: "medium";
    }

    @media (max-width: $bp-max-small) {
        content: "small";
    }
}

// flexible media
object,
video {
    display: block;
    max-width: 100%;
}

picture {
    display: block;
}

img {
    height: auto;
    max-width: 100%;
}

// lists
ol,
ul {
    padding: 0;
    margin: 0;
    margin-bottom: rem(30);

    li {
        margin: 0;

        ol,
        ul {
            margin-left: 25px;
        }
    }
}

ul {
    list-style: none;

    li {
        position: relative;
        padding-left: rem(20);

        // &:before {
        //   content: '';
        //   position: absolute;
        //   background: #962693;
        //   width: 6px;
        //   height: 6px;
        //   border-radius: 50%;
        //   top: 11px;
        //   left: 7px;
        // }

        > ul {
            padding-left: rem(10);
        }
    }

    > ul {
        padding-left: rem(30);
    }

    ul {
        margin-top: rem(10);
        margin-bottom: rem(10);
    }
}

// hack for sitecore RTE
body > ol {
    list-style-position: inside;
}

// nested lists
ul ul,
ol ul,
ol ol,
ul ol {
    // margin-top: rem(10);
}

// definition lists
dl {
    padding: 0;
    margin-top: 0;
    margin-left: rem(60);
    margin-bottom: rem(30);

    dd,
    dt {
        margin-bottom: rem(10);
    }
}

// links
a {
    color: $base-link-color;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
        color: $endocrine-dark-navy;
        text-decoration: underline;
    }
}

// hr
hr {
    border-bottom: 1px solid $base-hairline-color;
    border-left: none;
    border-right: none;
    border-top: none;
    margin: -1px 0 0 0;
    box-sizing: content-box;
    width: 100%;

    // &:last-child {
    //   display:none;
    // }
}

figure {
    margin: 0;
}

table {
    border-collapse: collapse;

    caption {
        font-size: rem(20);
        text-align: left;
    }

    &.row-header {
        display: block;

        tr:first-child {
            background-color: $endocrine-gray;
            font-weight: 700;

            & td {
                text-align: center;
            }
        }
    }

    &.column-header {
        display: block;

        tr td:first-child {
            background-color: $endocrine-gray;
            font-weight: 700;
        }
    }
}

th,
td {
    // border: 1px solid $base-border-color;
    padding: rem(10);
    font-size: rem(16);
    &:not(:last-child) {
        border-right: 1px solid $base-border-color;
    }
}

th {
    background-color: $endocrine-gray;
}

tr {
    &:not(:last-child) {
        border-bottom: 1px solid $base-border-color;
    }
}

button:focus,
button:active {
    outline-style: dotted;
    outline-width: thin;
}
