.form-results {
    width: calc(100% - 120px);
    margin-left: auto;
    margin-right: auto;

    @include media($bp-g-max-large) {
        width: calc(100% - 140px);
    }

    @include media($bp-g-max-medium) {
        width: calc(100% - 80px);
    }
}

.form-results__top-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form-results__count {
    font-weight: bold;
}

.form-results__view-all {
}

.form-results__pagination {
    margin-bottom: 20px;
}

.form-results__list {
}

.form-results__result-group {
    margin-bottom: 30px;

    @media (min-width: 681px) {
        display: flex;
        flex-wrap: wrap;
    }

    & + & {
        border-top: 1px solid $endocrine-light-blue;
        padding-top: 30px;
    }
}

.form-results__member-name {
    flex: none;
    margin-bottom: 15px;
    width: 100%;
}

.form-results__contact {
    flex: none;
    margin-right: 30px;
    width: calc(50% - 15px);
}

.form-results__address {
    margin-bottom: 15px;
}

.form-results__bio {
    margin: 0;
}

.form-results__contact + .form-results__bio {
    width: calc(50% - 15px);
}

.form-results__bio-item {
    padding: 0;
    padding-bottom: 8px;
}

.form-results__link {
}

.form-results__label {
    display: inline-block;
    font-weight: 700;
    margin-right: 0.25em;
}

.form-results__committee-info {
    flex: none;
    margin-top: 24px;
    width: 100%;
}

.form-results__accordion-button {
    padding: 8px 16px;
    width: auto;
}

.form-results__committee-content {
    padding-top: 24px;
}
