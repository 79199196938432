.flyout {
  background: $hormone-dark-blue;
  color: white;
  z-index: 10;
  width: 250px;

  &.in-flow {
    position: static;
  }
}
