.dynamic-content__filter-bar-mount {
    margin-bottom: 80px;
}
.end-filtered-list {
    margin: 0 0 80px;
    padding: 0 20px;
    width: 100%;

    @include media($bp-g-max-large) {
        margin-left: auto;
        margin-right: auto;
    }

    @include media($bp-g-max-medium) {
        margin-top: 86px;
    }

    @include media($bp-g-max-small) {
        margin-bottom: 0;
    }

    .search-results {
        width: 100%;
    }

    .header {
        display: flex;
        margin-bottom: 50px;
        border-bottom: 1px solid $endocrine-light-blue;

        @include media($bp-g-max-small) {
            border-bottom: 1px solid $white;
            border-top: 1px solid $endocrine-light-blue;
        }

        &__heading {
            position: relative;
            top: 1px;
            padding: 10px 30px 0 0;
            background-color: $white;
            border-top: 1px solid $endocrine-light-blue;
            @include media($bp-g-max-small) {
                border-top: 0;
            }

            &:after {
                content: "";
                position: absolute;
                top: -1px;
                right: 0;
                width: 20px;
                height: calc(100% + 1px);
                background: linear-gradient(
                    to right top,
                    $white calc(50% - 1px),
                    $endocrine-light-blue 50%,
                    $endocrine-light-blue 50%,
                    $white calc(50% + 1px)
                );

                @include media($bp-g-max-small) {
                    display: none;
                }
            }

            h6 {
                @include font(acumin, condensed-bold);
                font-size: rem(17);
                color: #9e9e9e;
                margin-bottom: 0;
            }
        }
    }

    &__selection-menu {
        .filter-select-bar__list-labels {
            color: white;
        }
    }

    &__selection-menu {
        .filter-select-bar__group-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -1.25rem;
            margin-right: 0;
            width: calc(100% + 1.25rem);
        }
    }

    &__selection-menu {
        .filter-select-bar__group {
            border-radius: 5px;
            cursor: pointer;
            margin-left: 1.25rem;
            margin-right: 0;
            max-width: 300px;
            outline: none;
            position: relative;

            @media (min-width: $bp-min-small) {
                display: flex;
                flex-basis: 220px;
                flex-grow: 1;
                flex-shrink: 1;
            }

            li {
                flex-basis: 30%;
                max-width: 30%;
                width: 100%;
            }

            & > button {
                font-size: rem(20);
                @include font(acumin);
                background: $endocrine-gray;
                position: relative;
                border-radius: 5px;
                display: inline-block;
                max-width: none;
                top: -1px;
                -webkit-appearance: none;
                font-weight: 300;
                color: $endocrine-black;
                padding: 17px 65px 17px 17px;
                border: none;
                z-index: 2;
                text-align: left;
                width: 100%;

                &[disabled] {
                    background: rgba($endocrine-gray, 0.75);
                    opacity: 0.75;
                }

                &:after {
                    background-color: #bdc9d4;
                    content: "";
                    display: block;
                    height: 30px;
                    position: absolute;
                    right: 50px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                }

                .icon {
                    background: transparent;
                    transition: transform 0.3s ease-in-out;
                    width: 50px;

                    &:after,
                    &:before {
                        background: $endocrine-dark-blue;
                        width: 1px;
                    }

                    &:after {
                        left: 20px;
                    }

                    &:before {
                        left: 27px;
                    }
                }
            }
        }
    }
    &__selection-menu {
        .flyout {
            background: $white;
            box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
            color: $endocrine-black;

            .multiselect-list {
                padding: 0;
            }

            li {
                flex-basis: 100%;
                max-width: 100%;

                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }

            label {
                background-color: transparent;
                cursor: pointer;
                font-size: rem(15);
                padding: 12px;
                padding-left: 40px;
                transition: background-color 0.3s ease-in-out;

                &:before,
                &:after {
                }

                &::before {
                    left: 10px;
                    top: calc(50% - 11px);
                }

                &::after {
                    left: 15px;
                    top: calc(50% - 5px);
                }
            }

            input[checked] + label,
            label:hover {
                background-color: $grey-100;
            }
        }
    }
    &__selection-menu {
        .filter-select-bar__group {
            .filter-select-bar__list-top {
                padding: 0;

                .multiselect-list {
                    padding-bottom: 12px;

                    label {
                        background-color: transparent;
                        color: white;
                    }
                }
            }
            .filter-select-bar__list-labels {
                margin-bottom: 0;
                padding: 12px;

                &:first-child:last-child {
                    display: none;
                }
            }
        }
    }

    &__selection-menu {
        .end-select-box {
            @include media($bp-g-max-large) {
                margin-bottom: 20px;
            }
        }
    }

    &__content {
        margin-top: 90px;
        margin-bottom: 60px;

        .end-filtered-item:not(:last-child) {
            margin-bottom: 50px;
        }

        @include media($bp-g-max-small) {
            margin-top: 50px;
        }
    }

    &__view-btn {
        margin-top: 50px;

        .btn--primary {
            width: 130px;
            padding: 8px 0px 10px 8px;
            justify-content: space-around;
            background: linear-gradient(
                105deg,
                #246aaa 0%,
                #246aaa 70%,
                #418fde 70%
            );

            &__text {
                font-size: rem(13);
            }

            &__icon-box {
                color: $white;
                position: relative;
                width: 22px;

                &:before,
                &:after {
                    content: "";
                    width: 12px;
                    height: 3px;
                    background: $white;
                    position: absolute;
                    top: -1px;
                    right: 8px;
                }

                &:after {
                    transform: rotate(90deg);
                }

                svg {
                    display: none;
                }
            }

            &:hover {
                background: linear-gradient(
                    105deg,
                    #2e84d3 0%,
                    #2e84d3 70%,
                    #418fde 70%
                );
            }
        }
    }
}
