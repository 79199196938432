$blue: #418FDE;
$light-blue: #0194AC;
$maroon: #AA224B;
$orange: #EAAA00;
$purple: #7A2B84;

.end-article-preview {
  &__media {
    // border-bottom: 1px solid #EAAA00;
    
    a,
    picture {
      display: block;
      line-height: 0;
    }

    .end-article-preview__image {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &--blue {
      border-bottom: 1px solid $blue;
    }
    
    &--light-blue {
      border-bottom: 1px solid $light-blue;
    }

    &--maroon {
      border-bottom: 1px solid $maroon;
    }

    &--orange {
      border-bottom: 1px solid $orange;
    }
    
    &--purple {
      border-bottom: 1px solid $purple;
    }
  }

  &__category {
    font-weight: bold;
    display: inline-block;
    background-color: #EAAA00;
    text-transform: uppercase;
    color: $white;
    position: relative;
    padding: 1px 12px;
    margin-bottom: 10px;

    &--blue {
      background-color: $blue;
    }

    &--light-blue {
      background-color: $light-blue;
    }

    &--maroon {
      background-color: $maroon;
    }

    &--orange {
      background-color: $orange;
    }

    &--purple {
      background-color: $purple;
    }
  }

  &__content {
    p {
      font-size: rem(17);
      font-weight: 400;
    }

    a {
      color: $endocrine-black;
    }
  }

  &__title {
    margin-bottom: 5px;
    letter-spacing: -0.5px;

    @media (max-width: $bp-max-medium) {
      font-size: rem(26);
    }

    @media (max-width: $bp-max-small) {
      font-size: rem(22);
    }
  }

  &__date {
    color: $endocrine-dark-gray;

    @media (max-width: $bp-max-small) {
      margin-bottom: 10px;
    }
  }

  &__description {
    margin-bottom: 0;
  }
}
