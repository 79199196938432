$secondary-nav__parent-indent: rem(0);
$secondary-nav__child-indent: rem(12);
$secondary-nav__grandchild-indent: rem(24);

$secondary-nav__item-vert-padding: rem(8);
$secondary-nav__item-vert-margin: rem(8);
$secondary-nav__list-vert-margin: rem(16);

.end-lp-secondary-nav {
    // margin-top: 150px;

    position: relative;
    background: $hormone-navy;
    position: relative;
    z-index: 0;
    padding: 30px 50px 30px 50px;
    width: 100%;
    clip-path: polygon(0% 50px, 20px 0%, 100% 0%, 100% 100%, 0% 100%);

    @include media($bp-g-max-medium) {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 80px);
        clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%);
        transform: translateY(
            0
        ) !important; // '!important' needed to override translateY() inline style parameter found in 01-end-lp-secondary-nav.json
        margin-bottom: 40px !important;
    }

    .container--70-30 .container__col:last-child &,
    .container--30-70 .container__col:first-child & {
        display: inline-block;
    }

    .container--70-30 .container__col:first-child &,
    .container--30-70 .container__col:last-child & {
        display: none;
    }

    @include media($bp-g-max-medium) {
        .container--70-30 .container__col:last-child &,
        .container--30-70 .container__col:first-child & {
            display: none;
        }

        .container--70-30 .container__col:first-child &,
        .container--30-70 .container__col:last-child & {
            display: block;
        }
    }

    @include media($bp-g-max-medium) {
        padding: 0;
        background: none;
        margin-bottom: 30px;
    }

    @media (max-width: em(374)) {
        width: 100%;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #001f4e;
        z-index: -1;

        @include media($bp-g-max-medium) {
            display: none;
        }
    }

    &__icon {
        display: flex;
        flex: none;
        align-items: center;
        height: 26px;
        margin-right: 8px;
        padding-top: 4px;
        width: 22px;

        img {
            flex: none;
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }
}

.end-lp-secondary-nav__toggle {
    display: none;
    font-weight: 800;
    font-size: 15px;
    height: 40px;
    padding: 8px 20px 4px 58px;
    border: none;
    background: none;
    color: white;
    position: relative;
    background: $hormone-navy;
    text-decoration: none;

    @include media($bp-g-max-medium) {
        display: inline-block;
        vertical-align: top;
    }

    @media (max-width: em(374)) {
        font-size: 14px;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
        cursor: pointer;
        color: white;
    }

    span {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;
        left: 0;
        background: $hormone-dark-blue;

        &:before,
        &:after {
            content: "";
            width: 12px;
            height: 3px;
            background: white;
            position: absolute;
            top: 18px;
            left: 14px;
            transition: transform 0.2s ease;
        }

        &:after {
            transform: rotate(90deg);
        }
    }
}

.is-open .secondary-nav__toggle {
    span:after {
        transform: rotate(0deg);
    }
}

.end-lp-secondary-nav__title {
    @include font(acumin, condensed-bold);
    font-size: rem(17);
    letter-spacing: 1.5px;
    color: #9e9e9e;
    margin-bottom: 20px;

    &:hover {
        text-decoration: underline;
    }

    @include media($bp-g-max-medium) {
        display: none;
        background: $hormone-navy;
        padding: 15px 40px 20px 40px;
        margin-bottom: 0;
    }
}

.end-lp-secondary-nav__menu {
    @include zero-list;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;

    @include media($bp-g-max-medium) {
        display: none;
        background: $hormone-navy;
        padding: 0 40px 30px 40px;
    }

    @include media($bp-g-max-small) {
        padding: 12px 24px 24px 24px;
    }
}

.end-lp-secondary-nav__item,
.end-lp-secondary-nav__subitem,
.end-lp-secondary-nav__tertitem {
    & > a {
        display: block;
        font-weight: 800;
        // font-size: 14px;
        line-height: 1.3;
        color: #ffffff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &.btn {
            display: inline-block;
            font-size: 14px;
            color: #000000;
            padding: 8px 16px 8px 30px;
            text-decoration: none;
            background: #00bbff;
            position: relative;
            margin-top: 8px;
            width: auto;
            margin-bottom: 4px;

            &:hover,
            &:focus {
                background: $hormone-blue-lightest;
                text-decoration: underline;
            }

            &:after,
            &:before {
                right: auto;
                left: 13px;
                background: $hormone-black;
            }

            &:before {
                transform: rotate(135deg);
                margin-top: -2px;
            }

            &:after {
                transform: rotate(-135deg);
                margin-top: -6px;
            }
        }
    }

    &.is-active {
        & > a {
            text-decoration: underline;

            &.btn {
                text-decoration: none;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}

.end-lp-secondary-nav__category {
    display: flex;
    align-items: center;
}

.end-lp-secondary-nav__item {
    flex: none;
    padding: 10px 0;
    width: 100%;

    &:last-child {
        border-bottom: none;
    }

    &:before {
        display: none;
    }

    & a {
        width: 100%;
        color: $white;
        font-weight: bold;
    }

    & > a {
        padding-left: $secondary-nav__parent-indent;
    }
}

.end-lp-secondary-nav__submenu,
.end-lp-secondary-nav__tertmenu {
    width: 100%;
    margin: 6px 0 5px 26px;
    padding: 0;

    li {
        display: block;
    }
}

.end-lp-secondary-nav__subitem,
.end-lp-secondary-nav__tertitem {
    clear: both;
    display: block;
    font-size: rem(13);

    &:before {
        width: 5px;
        height: 5px;
        top: 15px;
        // background: #DB30DB;
    }

    a {
        padding: 8px 0;
    }
}

.end-lp-secondary-nav__subitem {
    padding-left: rem(32);

    // &:first-child {
    // 	& > a:before {
    // 		top: 6%;
    // 	}
    // }
}

.end-lp-secondary-nav__tertmenu {
    clear: both;
    margin: 0;
}

.end-lp-secondary-nav__tertitem {
    & > a {
        padding: 6px 0;
    }
}

.end-lp-secondary-nav__subitem,
.end-lp-secondary-nav__category.no-icon {
    // .secondary-nav__tertitem {
    a:before {
        content: "";
        position: absolute;
        height: 100%;
        border-right: 2px solid $endocrine-light-blue;
        border-bottom: 2px solid $endocrine-light-blue;
        width: 8px;
        height: 8px;
        transform: rotate(-45deg);
        left: 15px;
        // top: 35%;
        top: 9px;
    }
}

.end-lp-secondary-nav__category.no-icon {
    a {
        // margin-left: 30px;
        margin-left: 16px;
    }

    a:before {
        // top: 38%;
        left: 0;
        top: 16px;
        margin-right: 4px;
    }
}

.end-lp-secondary-nav__category.no-icon
    + .secondary-nav__submenu
    .secondary-nav__subitem {
    padding-left: rem(16);

    a:before {
        left: 0;
    }
}

.end-lp-secondary-nav__tertitem {
    a:before {
        left: 7px;
    }

    padding-left: 25px;
}
