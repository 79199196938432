.button-back-to-top {
  position: fixed;
  right: 35px;
  bottom: 50px;
  display: block;
  z-index: 99;
  visbility: hidden;
  opacity: 0;
  transition: opacity .2s ease, background .2s ease, transform .2s ease, visibility .2s ease;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $hormone-blue;
  transform: translatey(20px);

  @include media($bp-g-max-medium) {
    bottom: 16px;
    right: 16px;
  }

  &.is-active {
    opacity: 1;
    transform: translatey(0);
    visibility: visible;
  }

  &:hover {
    background: $hormone-blue-dark;
  }

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    left: 14px;
    transition: transform .35s ease;
    width: 3px;
    height: 10px;
    background: white;
  }

  &:before {
    top: 14px;
    left: 21px;
    transform: rotate(135deg);
  }

  &:after {
    top: 14px;
    left: 16px;
    transform: rotate(-135deg);
  }
}
