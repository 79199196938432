.page-title {
	display: flex;
	flex-direction: column;
	margin-bottom: 56px;
	max-width: 750px;

	@media(max-width: $bp-max-medium) {
		margin-bottom: 40px;
	}

	@media(max-width: em(600)) {
		margin-top: 20px;
	}

	.share-bar ~ &.has-type {
		margin-top: -18px;

		@media(max-width: $bp-max-medium) {
			margin-top: 0;
		}
	}

	@include media($bp-g-max-large) {
		margin-left: auto;
		margin-right: auto;
		width: calc(100% - 140px);
	}

	@include media($bp-g-max-medium) {
		width: calc(100% - 80px);
	}
}

// For the benefit of screen readers,
// the source order of the titles should go:
// 1) Title 2) Subtitle 3) Content Type
// Visually, the Content Type should be first
.page-title__type 		{ order: 1; }
.page-title__title 		{ order: 2; }
.page-title__subtitle	{ order: 3; }

.page-title__title {
	margin: 0;
}

.page-title__subtitle {
	opacity: 0.5;
	font-weight: 300;
	font-size: 32px;
	color: #000000;
	letter-spacing: -0.53px;
	line-height: 1.25;
	margin-bottom: 0;
	margin-top: 14px;

	@media(max-width: $bp-max-medium) {
		font-size: 24px
	}

	@media(max-width: $bp-max-small) {
		font-size: 21px;
	}
}

.page-title__type {
	text-transform: uppercase;
	margin-bottom: 0;
	font-weight: 800;
	font-size: 16px;
	color: #BB29BB;
	letter-spacing: 1.5px;
}
