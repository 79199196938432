.filter-select-bar {
  display: flex;
  align-items: baseline;
  margin-bottom: rem(30);

  @media (max-width: $bp-max-medium) {
    flex-direction: column;
  }
  @media (max-width: $bp-max-small) {
    background-color: $grey-100;
  }

  .collapse-pane__content {
    margin-top: rem(8);
  }
}

.filter-select-bar__title,
.filter-select-bar__toggle {
  font-size: rem(15);
  color: $grey-500;
  margin-right: 1rem;
  margin-bottom: 0;
  border: 0;
  background: 0;
}

.filter-select-bar__title {
  font-weight: 500;
  font-size: 1rem;
  min-width: 65px; // require a min-width to prevent a abs position shift on the dropdowns. maybe due to flex pixel rounding?
  @media (max-width: $bp-max-medium) {
    margin-bottom: rem(16);
  }
}

.filter-select-bar__toggle {
  display: none;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  padding: rem(10) 0;

  span:first-child {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 15px;
    color: white;
    padding-right: 20px;
    position: relative;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translatey(-50%);
      width: 3px;
      height: 10px;
      background: white;
    }

    &:before {
      top: 50%;
      right: 0;
      transform: translatey(-50%) rotate(45deg);
    }

    &:after {
      top: 50%;
      right: 5px;
      transform: translatey(-50%) rotate(-45deg);
    }
  }

  span:last-child {
    font-weight: 400;
    font-size: rem(13);
    line-height: 1;
    color: white;
  }

  &:focus {
    outline: none;
  }
}

.is-open .filter-select-bar__toggle::after {
  transform: rotate(180deg);
}

@media (max-width: $bp-max-small) {
  .filter-select-bar__title {
    display: none;
  }

  .filter-select-bar__toggle {
    display: flex;
  }
}

.filter-select-bar__list-top {
  padding: 11px 20px 16px;
  background: $hormone-navy;

  @media (max-width: $bp-max-small) {
    background: #0073ab;
  }

  .multiselect-list {
    overflow: hidden;

    li {
      padding-left: 0;
  
      &:before {
        display: none;
      }
    }
  }  
}

.filter-select-bar__list-labels {
  display: flex;
  margin-bottom: 4px;
}

.filter-select-bar__list-label {
  display: block;
  font-size: 10px;
  margin-top: 5px;
  width: 100%;
}

.filter-select-bar__group-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.filter-select-bar__group {
  margin-bottom: 0;
  margin-right: rem(20);
  margin-bottom: rem(12);
  padding-left: 0;
  white-space: nowrap;

  &:before {
    display: none;
  }

  &:last-child {
    margin-right: 0;
  }

  & > button {
    background: $hormone-dark-blue;
    position: relative;
    border-radius: 0;
    display: inline-block;
    max-width: 260px;
    top: -1px;
    -webkit-appearance: none;
    font-weight: 600;
    font-size: 1rem;
    color: $white;
    padding: 12px 112px 12px 16px;
    border: none;
    z-index: 2;
    text-align: left;

    .icon {
      width: 43px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: $hormone-blue;
      border-radius: 0;

      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translatey(-50%);
        width: 3px;
        height: 10px;
        background: white;
      }

      &:before {
        top: 50%;
        left: 22px;
        transform: translatey(-50%) rotate(45deg);
      }

      &:after {
        top: 50%;
        left: 17px;
        transform: translatey(-50%) rotate(-45deg);
      }
    }

    &:focus {
      outline-color: black;
    }
  }

  .flyout {
    width: 100%;

    > .multiselect-list {
      padding: 18px 20px 19px;
    }
  }

  &.is-open {
    & > button {
      border-bottom: none;
    }
  }

  &.is-open > button .icon {
    transform: rotate(180deg);
  }

  @media (max-width: $bp-max-medium) {
    padding: rem(4) 0;

    &.is-open,
    &:hover {
      background-color: transparent;
      border-bottom: none;
    }
  }
}

.filter-select-bar__clear-group {
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 800;
  color: white;
  text-decoration: underline;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.71;

  &:disabled {
    opacity: .4;
  }
}

@media (max-width: $bp-max-small) {
  .filter-select-bar {
    .collapse-pane {
      width: 100%;
      padding: 8px 20px 4px 20px;
      color: white;
      position: relative;
      background: #00376B;
    }
  }
  .filter-select-bar__group-list {
    flex-direction: column;
  }
  .filter-select-bar__group {
    width: 100%;
    margin-right: 0;
    margin-bottom: rem(8);
    display: block;

    .flyout {
      width: 100%;
    }

    button {
      width: 100%;
      text-align: left;
      display: block;
      max-width: none;
    }
  }
}
