.end-cta-promos {
    padding: 0 60px;

    @media(max-width: $bp-max-medium) {
        padding: 0 40px;
    }

    .end-slideshow {
        padding: 40px 50px;
        background-color: $endocrine-gray;

        @media(max-width: $bp-max-medium) {
            padding: 40px 20px;
        }

        .slick-next,
        .slick-prev {
            display: none !important; // override inline styling
        }
        
        
        .slick-dots {
            display: flex;
            justify-content: center;
            // padding-top: 50px;
            background-color: $endocrine-gray;
            
            button {                
                background-color: $white;                
            }
        }

        .slick-list {
            background-color: $endocrine-gray;
        }

        .end-slideshow__slider .slick-list .slick-track .slick-slide {
            background-color: $endocrine-gray;
        }

        .slick-dotted.slick-slider {
            margin-bottom: 0;
        }
    }
}