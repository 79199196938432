.end-copyright-links {
    font-size: rem(12);
    line-height: rem(12);
    margin-left: 5px;

    @include media($bp-g-max-medium) {
        margin-left: 0;
        margin-top: 5px;
    }

    ul {
        @include zero-list();
        display: flex;
    }

    li {
        padding-left: 0;

        &:after {
            content: '|';
            width: 1px;
            margin: 0 5px;
        }

        &:last-child:after {
            display:none;
        }
    }
    
    a { 
        &:hover {
            text-decoration: underline;
        }
	}
}