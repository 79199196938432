.page-featured-image {
  margin-bottom: 28px;
  max-width: 827px;

  @include media($bp-g-max-large) {
    max-width: none;
  }

  @media(max-width: $bp-max-medium) {
    margin-bottom: 20px;
  }

  @media(max-width: em(600)) {
    margin-bottom: 12px;
  }

  @include media($bp-g-max-large) {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 140px);
  }

  @include media($bp-g-max-medium) {
    width: calc(100% - 80px);
  }

  @media(max-width: em(374)) {
		position: relative;
		left: -20px;
		width: calc(100% + 40px);
	}

  img {
    width: 100%;
    display: block;
  }

  figcaption {
    font-weight: 500;
    font-size: 12px;
    color: $hormone-black;
    text-align: right;
    line-height: 1.5;
    margin-top: 7px;

  	@include media($bp-g-max-medium) {
      margin-left: auto;
      margin-right: auto;
      width: calc(100% - 80px);
    }
  }
}
