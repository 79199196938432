.end-advantages {
    &__header {
        font-weight: bold;
        margin-bottom: 36px;

        h4 {
            @media (max-width: $bp-max-medium) {
                font-size: rem(20);
            }
        }
        @media (max-width: $bp-max-medium) {
            width: 100%;
            margin-bottom: 20px;
        }

        @media (max-width: $bp-max-small) {
            width: 100%;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;

        @media (max-width: $bp-max-medium) {
            flex-direction: column;
            width: 100%;
        }

        .list {
            &__title {
                text-transform: uppercase;
                color: #5F2167;
                margin-bottom: 16px;

                @media (max-width: $bp-max-medium) {
                    font-size: rem(16);
                }
            }

            &__item {
                font-size: rem(17);
                font-weight: 400;

                &:before {
                    content: "";
                    position: absolute;
                    height: 100%;
                    border-right: 2px solid $endocrine-purple;
                    border-bottom: 2px solid $endocrine-purple;
                    width: 8px;
                    height: 8px;
                    transform: rotate(-45deg);
                    left: 0;
                    top: 30%;
                }
            }
            
            a {
                color: $endocrine-black;
            }
        }

        .cta {
            &__button {
                margin-bottom: 32px;
            }

            &__subtitle {
                @include font(acumin, bold);
                font-size: rem(15);
                color: #5F2167;
                margin-bottom: 0;
            }

            &__copy {
                font-size: rem(15);
                font-weight: 400;

                a {
                    font-weight: bold;
                    text-decoration: underline;

                    &:hover {
                        color: $endocrine-dark-blue;
                    }
                }
            }
        }
    }
}
