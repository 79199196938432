.search-item {
	opacity: 0;
	transition: opacity 250ms;
	margin-bottom: rem(32);
	padding-bottom: rem(32);
	border-bottom: 1px solid $grey-200;

	&.is-visible {
		opacity: 1;
	}

	&:last-child {
		border-bottom: none;
	}

	a {
		// remove transitions when graying out results
		// while search results refreshing
		transition: none;
	}
}

.search-item__meta {
	margin-bottom: rem(6);
	&>span {
		padding-right: rem(16);
	}
}

.search-item__title {
	margin-bottom: rem(8);
}

.search-item__content-type {
	text-transform: uppercase;
}

.search-item__body {
	margin-bottom: rem(12);
}

.search-item__url {
	@include font(acumin, bold);
	font-size: rem(14);
}
