// content-list.scss
// Define content list styles here

// base
.content-list {
    //@include component-whitespace();
    padding-bottom: rem(80);

    @media (max-width: $bp-max-small) {
        padding-bottom: rem(48);
    }

    @include media($bp-g-max-large) {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 140px);
    }

    @include media($bp-g-max-medium) {
        width: calc(100% - 80px);
    }

    .interior-container__col & {
        max-width: 827px;
    }

    &__heading {
        font-weight: 800;
        font-size: 14px;
        letter-spacing: 1.5px;
        line-height: 1.29;
        padding-top: 24px;
        text-transform: uppercase;
        margin-bottom: 50px;
    }

    &__filters {
        margin-bottom: 41px;

        label {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5;
            display: inline-block;
            margin-bottom: 4px;
            margin-right: 6px;
        }
    }

    &__az {
        margin-bottom: 54px;
        padding-bottom: 13px;
        border-bottom: 1px solid $base-hairline-color;

        ul {
            margin: 0;
        }

        li {
            padding: 0;
            margin: 0 5px 0 0;
            display: inline-block;
            vertical-align: top;

            &:before {
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }

            span,
            a {
                display: block;
            }

            span {
                opacity: 0.25;
                font-weight: 800;
            }

            a {
                text-decoration: none;
                font-weight: 800;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__overflow {
        display: none;
        margin-top: rem(45);
    }

    // list
    &__list {
        @include zero-list;
    }

    // items
    &__item {
        //overflow: hidden;
        margin-bottom: rem(45);
        padding-left: 0;

        @media (max-width: $bp-max-small) {
            margin-bottom: rem(40);
        }

        &:before {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    &__more {
        margin-top: 32px;
        text-align: center;
    }

    &__results-text {
        border-bottom: 1px solid $hormone-black;
        margin-bottom: 60px;
    }
}

.content-list--taxonomy-list {
    li {
        margin-bottom: 1rem;
        border-bottom: 1px solid $grey-400;

        &:last-child {
            border-bottom: none;
        }
    }
}
