.end-society-news {
    @include horizontal-container-padding();
    margin-bottom: 95px;

    @media (max-width: $bp-max-small) {
        margin-bottom: 50px;
    }

    .container-wide--50-50 & {
        padding: 0;

        .end-society-news__content {
            @media (min-width: 640px) {
                display: flex;
                margin-left: -50px;
                width: calc(100% + 50px);
            }
        }

        .end-society-news__article-preview {
            @media (min-width: 640px) {
                flex: none;
                margin-left: 50px;
                width: calc(33.33% - 50px);
            }
        }
    }

    .header {
        display: flex;
        margin-bottom: 50px;
        border-bottom: 1px solid $endocrine-light-blue;

        &__heading {
            position: relative;
            top: 1px;
            padding: 10px 30px 10px 0;
            background-color: $white;
            border-top: 1px solid $endocrine-light-blue;

            @media (max-width: $bp-max-small) {
                display: block;
                width: 100%;
            }

            &:after {
                content: "";
                position: absolute;
                top: -1px;
                right: 0;
                width: 20px;
                height: calc(100% + 1px);
                background: linear-gradient(
                    to right top,
                    $white calc(50% - 1px),
                    $endocrine-light-blue 50%,
                    $endocrine-light-blue 50%,
                    $white calc(50% + 1px)
                );

                @media (max-width: $bp-max-small) {
                    display: none;
                }
            }

            h4 {
                margin-bottom: 0;
                font-size: em(26);
                letter-spacing: -0.5px;

                @media (max-width: $bp-max-medium) {
                    font-size: rem(20);
                }
            }
        }

        @media (max-width: $bp-max-medium) {
            margin-bottom: 25px;
            width: 100%;
        }

        @media (max-width: $bp-max-small) {
            border-bottom: $white;
            width: 100%;
        }
    }

    &__content {
        p {
            font-size: rem(15);
        }

        .end-society-news__date {
            font-size: 13px;
            color: #002d72;
        }

        @media (min-width: $bp-min-large) {
            display: flex;
            margin-left: -50px;
            width: calc(100% + 50px);
        }
    }

    &__article-preview {
        @media (min-width: $bp-min-large) {
            flex: none;
            margin-left: 50px;
            width: calc(33.33% - 50px);
        }
    }

    &__title {
        font-size: rem(20);

        @media (max-width: $bp-max-medium) {
            font-size: rem(16);
        }
    }

    // &__date {
    //     font-size: em(13);
    //     color: $endocrine-dark-navy;
    // }
}
