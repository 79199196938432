// global-header.scss
// Define global header styles here
$global-header__bottom-space: rem(30);
// base
.global-header {
    background-color: $hormone-dark-blue;
    padding-top: 9px;

    &:before {
      content: '';
      height: 9px;
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='1400' height='10' viewbox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none'%3E%3Cpath fill='%230BF' d='M0 0h100v10H0z'/%3E%3Cpath fill='%2300D1C7' d='M260 0h70v10h-70z'/%3E%3Cpath fill='%23DB30DB' d='M100 0h160v10H100z'/%3E%3Cpath fill='%2372246C' d='M330 0h200v10H330z'/%3E%3Cpath fill='%2300B2A9' d='M660 0h120v10H660z'/%3E%3Cpath fill='%2300A3E0' d='M530 0h70v10h-70z'/%3E%3Cpath fill='%230BF' d='M600 0h60v10h-60z'/%3E%3Cpath fill='%23004B87' d='M780 0h100v10H780z'/%3E%3Cpath fill='%23685BC7' d='M880 0h160v10H880z'/%3E%3Cpath fill='%23571C52' d='M1160 0h40v10h-40z'/%3E%3Cpath fill='%23BB29BB' d='M1040 0h120v10h-120z'/%3E%3Cpath fill='%2300D1C7' d='M1200 0h200v10h-200z'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 9px;
    }

    &, a {
      color: white;
      text-decoration: none;
    }

    a:hover,
    a:active,
    a:focus {
      text-decoration: underline;
    }

    .search-box {
      width: 300px;
      margin-left: auto;
      margin-top: 26px;

      @media(max-width: 1120px) {
        margin-top: 18px;
        width: 250px;
      }
    }

    .container {
      margin-bottom: 0;
    }

    .container--bleed {

      @media(max-width: 374px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
}

.global-header__top {
    @include clearfix;
    margin-bottom: rem(16);

    @media(max-width: $bp-max-medium) {
        width: 100%;
        margin-bottom: 0;
    }
}


.global-header__top-right {
    float: right;
    padding-top: 24px;

    @media(max-width: $bp-max-medium) {
      padding-top: 46px;
    }

    @media(max-width: 750px) {
      position: absolute;
      top: 78px;
      left: 100px;
    }

    @media(max-width: 600px) {
      position: absolute;
      top: 178px;
      left: auto;
      right: 0;
    }

    .utility-nav {
      display: inline-block;
      vertical-align: top;
    }

    .utility-nav,
    .social-links-header,
    .search-box {
      @media(max-width: $bp-max-medium) {
          display: none;
      }
    }
}

.global-header__top-left {
    float: left;
    padding-top: 31px;

    @media(max-width: 1120px) {
      position: absolute;
      top: 24px;
      left: 0;
    }

    @media(max-width: $bp-max-medium) {
        display: inline-block;
        float: none;
        position: relative;
        top: 0;
        padding-top: 27px;
    }
}

.global-header__bottom {
    position: relative;

    @media(max-width: $bp-max-medium) {
      padding: 18px 0 0;
    }

    @media(max-width: 600px) {
      padding: 77px 0 0;
    }

    .search-box {
        display: none;
        float: right;

        @media(max-width: $bp-max-medium) {
          display: block;
          position: absolute;
          right: 0;
          top: -2px;
          max-width: 300px;
          margin-top: 0;
          width: calc(100% - 303px);
        }

        @media(max-width: 750px) {
          top: 4px;
        }

        @media(max-width: 500px) {
          width: calc(100% - 268px);
        }

        @media(max-width: 600px) {
          width: 100%;
          max-width: none;
          top: 12px;
        }
    }
}

.global-header__search-form {
    margin-left: rem(7);

    // responsive layout
    @media(max-width: 740px) {
        display: block;
        margin-left: 0;
        margin-bottom: rem(12);
    }
    @media(max-width: 560px) {
        margin: rem(12) auto;
        width: 90%;
    }
}

.social-links-header {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-right: 20px;

  &:after {
    content: '';
    height: 32px;
    width: 1px;
    background: rgba(255,255,255,.66);
    position: absolute;
    top: -4px;
    right: 0;
  }

  &__link {
    display: inline-block;
    vertical-align: top;
    margin-right: 14px;
    margin-top: 1px;
    font-weight: 500;
    font-size: 14px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    verical-align: top;

    a {
      display: block;
      color: white;
    }

    a:hover,
    a:active,
    a:focus {
      color: $hormone-lightest-blue;
    }
  }

  li {
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;

    &:before {
      display: none;
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }

  a[aria-label="Twitter"] {
    position: relative;
    top: 1px;

    svg {
      width: 25px;
      height: 21px;
    }
  }

  a[aria-label="YouTube"] svg {
    width: 22px;
    height: 22px;
  }

  path {
    fill: white;
    transition: fill .2s ease;
  }
}

#google_translate_element {
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: -2px;
  margin-left: 16px;
  z-index: 10;

  .goog-te-menu-value:hover,
  .goog-te-menu-value:active,
  .goog-te-menu-value:focus {
    color: black;
    text-decoration: none;
  }
}

.skiptranslate.goog-te-gadget,
.goog-logo-link,
.goog-logo-link:link,
.goog-logo-link:visited,
.goog-logo-link:hover,
.goog-logo-link:active {
  color: white !important;
}

.goog-logo-link {
  font-size: 11px !important;
}

.goog-te-combo {
  margin: 0 !important;
  background: white !important;
}
