.end-secondary-nav {
    // font styles - temporary
    @include font(acumin, bold);
    display: none;
    font-size: rem(13);
    width: 600px;
    margin-right: auto;

    .end-mobile-nav__primary-navigation & {
        color: $white;
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        width: 100%;
    }

    @include media($bp-end-header-min-large) {
        display: block;
    }

    a,
    li > span {
        display: block;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        color: $black;

        .end-mobile-nav__primary-navigation & {
            color: $white;
        }
    }

    a:hover {
        text-decoration: underline;
    }

    .end-secondary-nav__menu-wrap {
        display: block;

        .end-mobile-nav__primary-navigation & {
            // border-bottom: 1px solid $endocrine-light-blue;
            margin-bottom: 30px;
            padding-bottom: 20px;
        }
    }

    .end-secondary-nav__menu {
        @include zero-list;
        display: flex;
        flex-wrap: wrap;
        // align-items: flex-start;

        li:before {
            display: none;
        }

        .end-mobile-nav__primary-navigation & {
            display: block;
        }
    }

    .end-secondary-nav__item {
        align-items: center;
        display: flex;
        flex-basis: calc(33.33% - 8px);
        justify-content: flex-end;
        margin-right: 8px;
        padding-left: 0;

        // select all nav items except for last three
        &:not(:nth-last-child(-n + 3)) {
            margin-bottom: 10px;
        }

        .end-secondary-nav__icon {
            display: flex;
            align-items: center;
            height: 22px;
            margin-right: 8px;
            width: 22px;

            .end-mobile-nav__primary-navigation & {
                width: 24px;
                height: 24px;
            }

            img {
                display: block;
                flex-basis: 22px;
                flex-shrink: 1;
                flex-grow: 0;
                height: 100%;
                object-fit: contain;
                width: 100%;
            }
        }

        span {
            width: calc(100% - 30px);
        }

        .end-mobile-nav__primary-navigation
            .end-mobile-nav__primary-navigation-inner
            & {
            padding: 10px 0;
            margin-bottom: 10px;
        }
    }
}
