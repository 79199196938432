.taxonomy-item {
	display: block;
	font-weight: 800;
	font-size: 12px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-bottom: 16px;
	margin-top: 2px;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}
