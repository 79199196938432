.end-connect {
  a.btn.btn--box-arrow:hover,
  a.btn.btn--box-arrow:focus {
    text-decoration: none;
  }
  
  a:hover .btn__text,
  a:focus .btn__text {
    text-decoration: underline;
  }

  @media(max-width: $bp-max-large) {
    display: flex;
    align-items: center;
  }

  &__button {
  
    a {
      text-decoration: none;
  
      .btn__text {
        font-weight: bold;
        font-size: rem(15);
        color: $white;
      }
  
      .btn__box {
        border-color: $endocrine-light-blue;
      }
    }
  
    @media(max-width: $bp-max-large) {
      display: inline-block;
      margin-left: 40px;
    }
  }

  &__social {
    margin-bottom: 50px;
    font-size: 0;
  
    @media(max-width: $bp-max-large) {
      display: inline-block;
      // vertical-align: top;
      margin-top: 0;
      margin-bottom: 0;
    }
    
    // @media(max-width: em(540)) {
    //   display: block;
    //   margin-top: 46px;
    // }
  
    @media(max-width: $bp-max-small) {
      margin-top: 0;
    }
  }

  .end-mobile-nav__primary-navigation & {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;

    .social-links {
      margin-bottom: 40px;
    }

    &__button {
      margin-left: 0;
    }
  }
}