$pull-quote-fill: transparent;

.end-pull-quote {
    background-color: $pull-quote-fill;
    width: 100%;

    @include media($bp-g-max-large) {
        width: 100%;
        padding-left: 70px;
        padding-right: 70px;
    }

    @include media($bp-g-max-medium) {
        display: block;
        padding-left: 40px;
        padding-right: 40px;
    }

    .rich-text & {
        padding-left: 0;
        padding-right: 0;
    }

    blockquote {
        &:before,
        &:after {
            display: none;
        }

        &.end-pull-quote__quote {
            margin-bottom: 0;
        }
    }

    &__text {
        border-top: 1px solid $endocrine-light-blue;
        flex-grow: 0;
        margin-top: 50px;
        padding: 20px 12px 58px 0;

        .end-pull-quote__quote {
            margin-top: 0;
            margin-bottom: 0;
        }

        @media (max-width: em(1200)) {
            margin-left: 0;
            padding-bottom: 78px;
            width: 100%;
        }

        @media (max-width: $bp-max-medium) {
            padding-bottom: 40px;
        }

        @media (max-width: $bp-max-small) {
            padding: 0;
        }

        .l-pull-right &,
        .l-pull-left & {
            margin-top: 0;
            padding-bottom: 0;
        }
    }

    &__quote-icon {
        align-self: flex-start;
    }

    &__quote-text {
        margin-left: 14px;
    }

    &__quote {
        @include zero;
        position: relative;
        display: flex;

        &:before {
            display: none;
        }

        p {
            font-size: rem(28);
            font-weight: 300;
            color: $hormone-darkest-pink;
            line-height: 1.46;

            @media (max-width: $bp-max-medium) {
                font-size: rem(24);
            }

            @media (max-width: $bp-max-small) {
                font-size: rem(21);
            }
        }
    }

    &__caption {
        cite {
            display: block;
            font-weight: 600;
            font-size: rem(16);
            color: $endocrine-light-blue;
            font-style: normal;
        }
    }

    &__caption-dots {
        color: $endocrine-light-blue;
        width: 34px;
        height: 3px;
        display: block;
        margin-top: 28px;
        margin-bottom: 20px;
    }

    &__eyebrow {
        font-size: rem(16);
        color: $hormone-green;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 26px;
    }

    // image media
    &__media {
        //width: percentage(4/12);
        flex-basis: percentage(4/12);
        flew-grow: 0;
        margin-top: auto;
        padding-top: 10px;
        max-width: 400px;

        @media (max-width: $bp-max-medium) {
            display: none;
        }

        img {
            display: block;
            width: 100%;
            //max-width: 100%;
        }

        figcaption {
            padding: 0;
        }
    }
}

.testimonial {
    background-color: $hormone-tan;
}
