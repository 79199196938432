.end-hero {
  
  @include horizontal-container-padding;
  overflow: hidden;
  position: relative;
  color: $white;
  background-image: linear-gradient(
        to bottom,
        rgba(0,98,114, 0.8) 25%,
        $endocrine-navy 40%),
    url(/assets/img/common/cells-hero.png);
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(-1px);
  background-position: center top;

  @media(max-width: $bp-max-medium) {
    padding-left: 0;
    padding-right: 0;
    background-position: top center;
  }

  & > * {
    z-index: 2;
    position: relative;
  }

  &::before {
    background-image: linear-gradient(
      to bottom,
      rgba(0,98,114, 0.8) 25%,
      $endocrine-navy 40%);

    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    @media(max-width: em(1250)) {
      background-image: linear-gradient(
        to bottom,
        rgba(0,98,114, 0.8) 25%,
        $endocrine-navy 35%);
    }

    @media(max-width: $bp-max-large) {
      background-image: linear-gradient(
        to bottom,
        rgba(0,98,114, 0.8) 5%,
        $endocrine-navy 15%);
    }

    @media(max-width: $bp-max-medium) {
      background-image: linear-gradient(
        to bottom,
        rgba(0,98,114, 0.8) 0%,
        $endocrine-navy 9.5%);
      padding-left: 0;
      padding-right: 0;
      background-size: cover;
      background-position: top center;
    }

    @media(max-width: $bp-max-small) {
      background-image: linear-gradient(
        to bottom,
        rgba(0,98,114, 0.8) 0%,
        $endocrine-navy 8%);
    }
  }

  &__inside {
    z-index: 3;
    max-width: 920px;
    // max-width: 1200px;
    margin: 0 auto;
    position: relative;

    @media(max-width: $bp-max-medium) {
      // padding-left: 70px;
      // padding-right: 70px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @media(max-width: $bp-max-small) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &__caption {
    flex-basis: 100%;
    z-index: 4;
    // padding: 110px 60px 60px 0;
    padding: 110px 0 60px 0;

    @media(max-width: $bp-max-medium) {
      padding: 60px 0;
    }

    @media(max-width: $bp-max-small) {
      padding: 40px 0;
    }
  }
  
  &__eyebrow {
    font-weight: 800;
    font-size: rem(18);
    color: #D6C68D;
    letter-spacing: 1.5px;
    line-height: 1.29;
    text-transform: uppercase;

    @media(max-width: $bp-max-medium) {
      font-size: rem(16);
    }

    @media(max-width: $bp-max-small) {
      font-size: rem(14);
      margin-bottom: 5px;
    }
  }

  &__title {
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.1;
    text-shadow: 0 2px 20px rgba(0,55,107,0.25);
    color: $white;
    margin: 0;
    padding-right: 200px;

      a {
        display: block;
        text-decoration: none;
        color: white;
      }
    
    @media(max-width: $bp-max-medium) {
      font-size: rem(48);
      line-height: 1.1;
      padding-right: 0;
    }

    @media(max-width: $bp-max-small) {
      font-size: rem(36);
      padding-right: 0;
    }
  }

  &__subtitle {
    font-size: rem(20);
    color: white;
    line-height: 1.58;
    text-shadow: 0 2px 20px rgba(0,55,107,0.25);
    margin: 30px 0 1rem 0;

    @media(max-width: $bp-max-medium) {
      font-size: rem(16);
    }

    @media(max-width: $bp-max-small) {
      margin-bottom: 0;
      font-size: rem(14);
    }
  }

  &-menu {
    @include outer-container();
    margin-bottom: 120px;
    
    @media(max-width: $bp-max-medium) {
      margin-bottom: 50px;
    }

    @media(max-width: $bp-max-small) {
      margin-bottom: 30px;
    }
  }

  .end-news-grid {
    @include outer-container();
    padding: 50px 40px;
    background-color: $white;
    color: $black;
    margin-bottom: 100px;

    @media(max-width: $bp-max-medium) {
      margin-bottom: 50px;
    }

    @media(max-width: $bp-max-small) {
      margin-bottom: 30px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

}

// hero gradient: background: linear-gradient(180deg, rgba(0, 98, 114, 0.6) -6.4%, #001F4E 62.51%);