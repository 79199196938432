.end-footer-links {
	display: inline-block;
	margin-bottom: 68px;

	ul {
		@include zero-list();
	}

	li {
		display: inline;
		margin-right: 14px;
		padding-right: 14px;
		font-size: rem(12);
		padding-left: 0;
		white-space: nowrap;

    .end-mobile-nav__primary-navigation & {
			display: block;
			margin: 0 0 16px 0;
    }

		&:after {
			content: '';
			height: 22px;
			width: 1px;
			background: $endocrine-dark-blue;
			display: block;
			position: absolute;
			top: -4px;
			right: 0;

	    .end-mobile-nav__primary-navigation & {
				display: none;
	    }
		}

		&:before {
			display: none;
		}

		&:last-child {

			&:after {
				display: none;
			}
		}
	}

	a {
		// @include font(acumin, normal);
		text-decoration: none;
		font-weight: bold;
		font-size: rem(14);
		color: $white;
	}
}
