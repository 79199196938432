@media print {
.end-global-header, .end-page-banner, .page-title__type, .end-global-header, .breadcrumbs, .end-secondary-nav, .share-bar, .js-secondary-nav, .related-taxonomy, .end-related-links, .end-flp-promo, .end-promo, .end-disclaimers, .content, .end-newsletter-subscribe, .end-global-footer, .container container--100, .content-list, .end-citations, .end-video-block, .end-slideshow {display: none;}
.container--70-30 .container__col:first-child {
  display: block;
  margin: 1em;
  width: 100%;
}

a { text-decoration: none; color: #434343; cursor: default; font-weight: 400; font-style:inherit; }
.container--70-30 .container__col:last-child {
  display: none;
}

.btn {
  display: none;
}

.container--70-30 .container__col:first-child::after{
  content: "\A All Patient Guides are the property of the Endocrine Society. All Endocrine Society materials are protected by copyright and all rights are reserved. Individual or personal use only of the Patient Guides is allowed without permission from the Endocrine Society. To license this content: licensing@endocrine.org";
  display: block;
  text-align: center;
  white-space: pre-wrap;
}
hr {
  display: none;
}

@page {
  margin-top: 50pt;
  margin-bottom: 50pt;
}

// dynamic content listing (filtered list) print styles
.end-filtered-item__title a {
  font-weight: 700;
}

.end-filtered-list {
  .header__heading {
    border-top: none;
    top: 0;
    padding-bottom: 5px;

    &:after {
      display: none;
    }
  }
}

//accordion print styles	
	div[class^="panel-"]
	{
		display: block;
		border: none;
	}
	button[class^="accordion-"] 
	{
		background-color: #ffffff;
		color: #2C2A29;
		font-weight: bold;
	}
}

 
 