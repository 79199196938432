.search {
}

.search__query-mount {
	background: $hormone-tan;
	padding: 40px 40px 40px 0;
	position: relative;

	@include media($bp-g-max-large) {
		padding-left: 70px;
		padding-right: 70px;
	}

	@media(max-width: $bp-max-medium) {
		padding-left: 40px;
		padding-right: 40px;
	}

	input[type="text"] {
		border: 1px solid darken($hormone-tan,10%);
		border-right-color: white;
	}
}

.search-facets-outer-wrapper {
	background: $hormone-tan;
	padding: 0 40px 40px 0;
	position: relative;

	@include media($bp-g-max-large) {
		padding-left: 70px;
		padding-right: 70px;
	}

	@media(max-width: $bp-max-medium) {
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 0;
	}
}

.container--30-70 {
	.search__query-mount {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 100vw;
			height: 100%;
			background: inherit;
			z-index: -1;
		}
	}

	.search-facets-outer-wrapper {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 100vw;
			height: 100%;
			background: inherit;
			z-index: -1;
		}
	}
}

.container--70-30 {
	.search-facets-outer-wrapper {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background: inherit;
			z-index: -1;
		}
	}
}

@media (max-width: $bp-max-medium) {
	.search__facets-wrapper {
		padding-bottom: 40px;

		&.is-left,
		&.is-right {
			display: none;
			&.is-open {
				display: block;
			}
		}

		&.is-open {
			display: block;
		}
	}
}
