$multi-promo__bp-stack: rem(600);
$multi-promo__promo-space: rem(30);
.multi-promo {
    @include component-whitespace;
    display: flex;
    flex-wrap: wrap;
    padding-left: $multi-promo__promo-space;
}

.multi-promo__promo {
    background-color: $endocrine-gray;
    margin-right: $multi-promo__promo-space;
    margin-bottom: $multi-promo__promo-space;
    width: calc(100% - #{$multi-promo__promo-space});

    @media (min-width: $bp-min-large) {
        width: calc(50% - #{$multi-promo__promo-space});

        @include exactly(3) {
            width: calc(33.33% - #{$multi-promo__promo-space});
        }
    }

    @media (min-width: $bp-min-medium) {
        width: calc(50% - #{$multi-promo__promo-space});
    }

    @media (width: $multi-image__bp-stack) {
        width: calc(100% - #{$multi-promo__promo-space});
    }

    // fix IE flexbox bug to prevent text overflow
    .end-promo {
        display: block;
        height: 100%;
        width: 100%;
    }
}

// 70-column
.container--70-30 .container__col:first-child,
.container--30-70 .container__col:last-child {
    .multi-promo {
        @include media($bp-g-max-large) {
            margin-left: auto;
            margin-right: auto;
            width: calc(100% - 140px);
        }

        @include media($bp-g-max-medium) {
            width: calc(100% - 80px);
        }
    }

    .multi-promo__promo {
        @media (width: $bp-max-large) {
            width: 100%;
        }

        @media (width: $bp-min-large) {
            width: 50%;
        }
    }
}

// 30-column and 50-column
.container--70-30 .container__col:last-child,
.container--30-70 .container__col:first-child,
.container--50-50 .container__col {
    .multi-promo__promo {
        width: 100%;
    }
}
