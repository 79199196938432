.end-flp-promo {
    @include horizontal-container-padding;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    padding-bottom: 40px;
    padding-top: 100px;

    @include media($bp-g-max-large) {
        padding-left: 70px;
        padding-right: 70px;
        width: 100%;
    }

    @include media($bp-g-max-medium) {
        padding-left: 40px;
        padding-right: 40px;
    }

    // @include media($bp-g-max-medium) {
    //     padding-left: 40px;
    //     padding-right: 40px;
    // }

    .container--50-50 {
        border-top: 1px solid $endocrine-light-blue;
        padding-top: 20px;

        @include media($bp-g-max-small) {
            .container__col {
                margin-bottom: 12px;
            }
        }
    }

    &__title {
        font-size: rem(42);
        letter-spacing: -0.75px;

        @include media($bp-g-max-small) {
            font-size: rem(24);
        }
    }

    &__text {
        font-size: rem(20);

        @include media($bp-g-max-small) {
            font-size: rem(16);
        }
    }

    &__cta-btn {
        .btn--secondary {
            width: 180px;

            &__text {
                @include font(acumin, condensed-bold);
                font-size: rem(16);
                letter-spacing: 1.88px;
            }

            @include media($bp-g-max-medium) {
                // margin: 0 auto;
            }
        }
    }
}
