.end-global-nav {
    width: 50%;
    font-weight: bold;
	  font-size: rem(13);

  	@include media($bp-end-header-min-small) {
        width: 60%;
    }

  	@include media($bp-end-header-min-medium) {
        width: 50%;
    }

    a, li > span {
        display: block;
        text-decoration: none;
        color: $black;
        cursor: pointer;
    }


    &__menu {
        @include zero-list;
        display: flex;

        li:before {
            display: none;
        }
    }

    &__item {
        border-right: 1px solid $endocrine-light-blue;
        padding: 4px 14px 0 0;
        position: relative;
        margin-right: 14px;
        white-space: nowrap;

        .current:after {
          content: "";
          display: block;
          border-left: 10px solid transparent;          
          border-right: 10px solid transparent;
          border-top: 10px solid #EAEDEF;
          position: absolute;
          left: 50%;
          top: 40px;
          transform: translateX(-70%);
        }

        &:last-child {
          border-right: none;
          margin-right: 30px;
        }

        .arrow {
            border: solid $endocrine-dark-navy;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            position: relative;
              left: 4px;
              top: -3px;

            &--down {
                transform: rotate(45deg);
            }
        }
    }

    &__sub-list {
      display: none;
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
      position: absolute;
        top: 100%;
        left: 0;

      .is-open > & {
        display: block;
      }
    }

    &__sub-item {
      margin-bottom: 12px;
      white-space: normal;

      &:last-child {
        margin-bottom: 0;
      }
    }
}
