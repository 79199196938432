.related-taxonomy {
  display: table;
  width: 100%;
  margin-bottom: 60px;
  max-width: 750px;

  @media(max-width: em(540)) {
    display: block;
  }

  @include media($bp-g-max-large) {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 140px);
  }

  @include media($bp-g-max-medium) {
    width: calc(100% - 80px);
  }

  &__col:nth-last-child(-n+2):first-child,
  &__col:nth-last-child(-n+2):first-child ~ &__col {
    display: table-cell;
    width: 50%;
    vertical-align: top;

    @media(max-width: em(540)) {
      width: 100%;
      display: block;
    }
  }

  @media(min-width: em(540)) {
    // quantity query for there just being one
    &__col:nth-last-child(-n+1):first-child,
    &__col:nth-last-child(-n+1):first-child ~ &__col {
      ul {
        column-count: 2;
      }
    }
  }
  
  &__col {
    border-top: 1px solid $hormone-black;
    border-bottom: 1px solid $hormone-black;

    &:not(:last-child) {

      @media(max-width: em(540)) {
        border-bottom: none;
      }

    }

    &:not(:first-child) {
      border-left: 1px solid $hormone-black;

      @media(max-width: em(540)) {
        border-left: none;
        border-top: none;
        margin-top: 28px;
      }

      .related-taxonomy__col-header {
        padding-left: 15px;

        @media(max-width: em(540)) {
          padding-left: 0;
        }
      }

      ul {
        padding-left: 15px;

        @media(max-width: em(540)) {
          padding-left: 0;
        }
      }
    }

    &-header {
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 6px;
        background: transparent url('/assets/img/common/svg-sprite/dot-pattern-black.svg') repeat-x top left;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &-title {
      font-weight: 800;
      font-size: 14px;
      color: $hormone-black;
      letter-spacing: 1.5px;
      line-height: 1.29;
      text-transform: uppercase;
      padding: 15px 8px 23px 12px;
      margin: 0;
    }

    &-icon {
      flex-shrink: 0;
    }
  }

  ul {
    margin: 14px 0 20px;
  }

  li {
    margin-bottom: 4px;
    padding-left: 19px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      width: 5px;
      height: 5px;
      top: 7px;
    }

    a {
      display: block;
      font-weight: 800;
      font-size: 16px;
      text-align: left;
      line-height: 1.25;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
