.search-box {
  width: 100%;

  .end-mobile-nav__primary-navigation & {
    margin-bottom: 40px;

    @include media($bp-end-header-min-small) {
        margin-bottom: 60px;
    }
  }
}

.search-box__input {
    border: none;
    position: relative;
    padding-right: 40px;

    input {
      -webkit-appearance: none;
        font-size: 16px;
        color: $hormone-black;
        text-align: left;
        width: 100%;
        border: 2px solid white;
        box-shadow: none;
        padding: 8px 10px;
        transition: border .2s ease;
        border-radius: 0;
        border-style:solid;

        &:focus {
          outline: none;
          border-color: $hormone-blue-light;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $hormone-black;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $hormone-black;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $hormone-black;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $hormone-black;
        }
        &:placeholder {
          color: $hormone-black;
        }

    }
}

.search-box__button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40px;
    border: 0;
    background: $hormone-darkest-pink;
    cursor: pointer;
    z-index: 2;
    transition: background .2s ease;

    .end-mobile-nav__primary-navigation & {
      background: $endocrine-light-blue;
    }

    &:hover,
    &:focus {
      background: $hormone-dark-pink;

      .end-mobile-nav__primary-navigation & {
        background: $endocrine-dark-blue;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      margin-top: -1px;
      width: 20px;
      height: 20px;
      color: white;
    }
}

.search-box__loader {
    .search-results__spinner {
        position: absolute;
        top: 0;
        right: rem(45);
        left: auto;
        text-align: left;

        img {
            margin-top: rem(4);
            width: rem(32);
            height: rem(32);
        }
    }
}

svg#search {
	path {
		fill: currentColor;
	}
}

.search-container__col {
  .search-box {
    &__button {
      .icon {
        margin-top: -2px;
      }
    }
  }
}
