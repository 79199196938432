.end-page-meta {
	margin: 0;
    padding: 0;
    margin-bottom: 1.1875rem;
    font-size: 1.1875rem;
	line-height: 1.58;
	clear: both;

	margin-left: auto;
	margin-right: auto;

	@include media($bp-g-max-large) {
		width: calc(100% - 140px);
	}

	@include media($bp-g-max-medium) {
		width: calc(100% - 80px);
	}

	&__byline-label,
	&__time-label {
		@include font(acumin, condensed-bold);
		letter-spacing: 1.5px;
		font-weight: 700;
		font-size: 15px;
		line-height: 1.1;
		color:$endocrine-light-blue;
		// letter-spacing: 1.41px;
		text-transform: uppercase;
	}

	&__byline,
	&__date {
		margin-bottom: 4px;
	}

	&__byline-content,
	&__date {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.5;
	}
}
