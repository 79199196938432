.rich-text .end-sidebar,
.end-sidebar {
  background: $endocrine-navy;
  color: white;
  padding: 35px 40px 44px 40px;
  position: relative;
  clip-path: polygon(0% 50px, 20px 0%, 100% 0%, 100% 100%, 0% 100%);

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
    margin: 0 0 20px;
    color: $endocrine-light-blue2;
    padding-bottom: 15px;
    border-bottom: 1px solid $endocrine-light-blue;
  }

  p,
  span {
    font-size: 15px;
    line-height: 1.47;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
