// colors.scss
// Define color variables here

/// wireframe colors
/// @author Google
/// @link https://www.google.com/design/spec/style/color.html
/// NOTE: this uses Google's Material Design color scheme - "Grey"
$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eeeeee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default;
$grey-800: #424242 !default;
$grey-900: #212121 !default;

// base colors

// hormone - still used
$hormone-blue: #00a3e0;
$hormone-dark-blue: #007dba;
$hormone-blue-dark: $hormone-dark-blue;
$hormone-light-blue: #43cdff;
$hormone-blue-light: $hormone-light-blue;
$hormone-lightest-blue: #7cdcff;
$hormone-blue-lightest: $hormone-lightest-blue;
$hormone-navy: #00376b;
$hormone-dark-navy: #002e59;
$hormone-navy-dark: $hormone-dark-navy;
$hormone-green: #00b2a9;
$hormone-dark-green: #008675;
$hormone-green-dark: $hormone-dark-green;
$hormone-black: #222222;
$hormone-tan: #f1f1eb;
$hormone-pink: #db30db;
$hormone-dark-pink: #9d279b;
$hormone-pink-dark: $hormone-dark-pink;
$hormone-darkest-pink: #72246c;
$hormone-pink-darkest: $hormone-darkest-pink;

// endocrine
$endocrine-darker-blue: #004A8D;
$endocrine-dark-blue: #007DBA; $endocrine-blue-dark: $endocrine-dark-blue;
$endocrine-light-blue: #418FDE;
$endocrine-light-blue2: #55A2F1;
$endocrine-navy: #001f4e; 
// $endocrine-dark-navy: #002E59; $endocrine-navy-dark: $endocrine-dark-navy;
$endocrine-dark-navy: #002D72; $endocrine-navy-dark: $endocrine-dark-navy;
$endocrine-green: #00B2A9;
$endocrine-gray: #EAEDEF;
$endocrine-dark-gray: #98A4AE;
$endocrine-purple: #9636A3;
$endocrine-black: #2C2A29;

// variables
$white: #fff !default;
$black: #2c2a29 !default;

// borders
$base-border-color: $grey-400 !default;

// bgs
$base-background-color: $white !default;
