.end-lp-resource-list {

    @include media($bp-g-max-large) {
        margin-right: 60px;
        margin-left: 60px;

        &.sidebar {
            margin-right: 0;
            margin-left: 0;
        }
    }

    @include media($bp-g-max-medium) {
        padding-left: 40px;
        padding-right: 40px;
        margin: 0;
    }

    &__title {
        color: #9D968D;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__container {
        .end-promo:not(:last-child) {
            .end-promo__content {
                margin-bottom: 74px;
            }
        }
    }
}
