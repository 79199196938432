.end-cta-promo {
    &__title {
        margin-bottom: 30px;
        min-height: em(25);
        letter-spacing: -0.25px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        overflow-y: hidden;

        @media (max-width: $bp-max-small) {
            flex-direction: column;
        }
    }

    &__description {
        font-size: rem(15);
    }

    &__link {
        margin-bottom: 18px;
        margin-right: 50px;

        .btn.btn--solid {
            width: 150px;
            padding: 18px 32px;
            
            .btn--primary__text {
                font-size: rem(16);
                letter-spacing: 1.88px;
            }

            &:hover {
                background: #ad3ebd;
                border: 1px solid #ad3ebd;
            }
        }
    }

    &__second-cta {
        p {
            font-size: rem(13);
            margin-bottom: 3px;
        }

        a {
            text-decoration: underline;

            &:hover {
                color: $endocrine-dark-blue;
            }
        }
    }

    &__left {
        flex-basis: 70%;
        height: em(240);
        margin-right: 50px;
        overflow-y: hidden;

        @media (max-width: $bp-max-medium) {
            height: em(200);
            margin-bottom: 18px;
        }

        @media (max-width: $bp-max-small) {
            flex-basis: auto;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        flex-basis: 25%;

        @media (max-width: $bp-max-small) {
            margin-bottom: 18px;
        }
    }
}
