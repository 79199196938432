.end-info-body {
    display: flex;
    color: #5f2167;

    @media (max-width: $bp-max-medium) {
        flex-direction: column;
    }

    &__left-area {
        flex: none;
        width: 40%;
        font-size: rem(32);

        @media (max-width: $bp-max-large) {
            width: 50%;
        }

        @media (max-width: $bp-max-medium) {
            width: 100%;
        }

        .left-figure {
            img {
                width: 100%;
                object-fit: cover;
                padding-right: 25px;

                @media (max-width: $bp-max-medium) {
                    // width: 80%;
                    // margin: 0 auto;
                    width: 100%;
                    display: block;
                    padding: 0;
                    margin-bottom: 25px;
                }

                @media (max-width: $bp-max-small) {
                    width: 100%;
                }
            }
        }

        .left-figure-caption {
            line-height: 1.25;
            @media (max-width: $bp-max-large) {
                font-size: rem(26);
            }

            @media (max-width: $bp-max-medium) {
                font-size: rem(22);
                // padding-right: 30%;
                // width: 80%;
                // margin-left: auto;
                // margin-right: auto;
                width: 100%;
                margin-bottom: 25px;
            }

            @media (max-width: $bp-max-small) {
                width: 100%;
                font-size: rem(18);
            }
        }
    }

    &__right-area {
        flex: none;
        width: 60%;

        @media (max-width: $bp-max-large) {
            width: 50%;
        }

        @media (max-width: $bp-max-medium) {
            width: 100%;
        }

        .right-area-top {
            color: $black;
            display: flex;
            flex-direction: column;
            margin-bottom: 100px;
            width: 100%;

            @media (max-width: $bp-max-medium) {
                display: block;
                margin-bottom: 50px;
            }

            @media (max-width: $bp-max-small) {
                margin-bottom: 30px;
            }

            &__map {
                align-self: center;
                flex: none;
                width: 100%;

                img {
                    width: 100%;

                    @media (max-width: $bp-max-medium) {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            &__text {
                align-self: flex-end;
                flex: none;
                font-size: rem(16);
                margin-top: -50px;

                @media (max-width: $bp-max-large) {
                    margin-top: 5px;
                    align-self: center;
                }

                @media (max-width: $bp-max-medium) {
                    width: 80%;
                    margin-top: 5px;
                    align-self: center;
                }

                @media (max-width: $bp-max-small) {
                    font-size: rem(14);
                    width: 100%;
                    margin-top: 0px;
                }

                .callout {
                    color: $endocrine-purple;
                    line-height: 1;
                    margin-bottom: 8px;

                    &__number {
                        @include font(acumin, bold);
                        font-size: rem(58);
                        line-height: 1;

                        @media (max-width: $bp-max-large) {
                            font-size: rem(42);
                        }

                        @media (max-width: $bp-max-small) {
                            font-size: rem(36);
                        }
                    }

                    &__text {
                        font-size: rem(26);

                        @media (max-width: $bp-max-large) {
                            font-size: rem(22);
                        }

                        @media (max-width: $bp-max-small) {
                            font-size: rem(18);
                        }
                    }
                }
            }
        }

        .right-area-bottom {
            display: flex;
            flex-direction: row-reverse;
            font-size: rem(26);

            @media (max-width: $bp-max-medium) {
                flex-direction: row;
                // width: 80%;
                width: 100%;
                // margin: 0 auto;
            }

            @media (max-width: $bp-max-small) {
                flex-direction: column;
                width: 100%;
                margin: 0;
            }

            &__profile {
                width: 100%;
                img {
                    display: block;
                    width: 100%;

                    @media (max-width: $bp-max-medium) {
                        width: 80%;
                    }

                    @media (max-width: $bp-max-small) {
                        width: 50%;
                        margin: 0;
                    }
                }
            }

            &__quote {
                text-align: right;
                padding-left: 20%;
                padding-right: 30px;
                line-height: 1.4;
                font-weight: 400;

                @media (max-width: $bp-max-large) {
                    font-size: rem(18);
                }

                @media (max-width: $bp-max-medium) {
                    padding-left: 0;
                    text-align: left;
                }

                @media (max-width: $bp-max-small) {
                    padding: 0;
                    font-size: rem(16);
                }
            }
        }
    }
}
