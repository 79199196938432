.end-mobile-member-login {
    display: flex;
    flex-direction: column;
    color: $white;

    &__header {
        display: flex;
        align-items: center;
        background-color: #002d72;
        padding: 18px 30px;
        position: relative;
        cursor: pointer;

        .header-icon {
            width: 20px;
            height: 20px;
        }

        &.is-open {
            .end-mobile-member-login__button {
                transform: rotate(585deg);
            }
        }
    }

    &__greeting {
        margin-left: 15px;

        .title {
            display: block;
            font-weight: 600;
            font-size: 17px;
        }

        .login-link,
        .signup-link {
            font-weight: 600;
            font-size: 17px;
            color: $white;
        }

        .login-link {
            border-right: 1px solid $endocrine-light-blue;
            padding-right: 25px;
            padding-bottom: 2px;
            margin-right: 25px;
        }
    }

    &__button {
        border: 3px solid $endocrine-light-blue;
        background: transparent;
        padding: 0;
        border-left-width: 0;
        border-top-width: 0;
        display: block;
        width: 14px;
        height: 14px;
        transform: translateY(-50%) rotate(45deg);
        transform-origin: center;
        transition: transform 0.25s;
        position: absolute;
        top: 45%;
        right: 27px;
        // top: 30px;
        // right: 27px;
    }

    &__dropdown {
        padding: 0 30px 35px;
        background-color: #002d72;
        display: none;
    }

    &__menu-top {
        border-top: 1px solid $endocrine-light-blue;
        padding-top: 24px;

        .list {
            &__title {
                color: rgba(216, 216, 216, 0.5);
                font-size: 18px;
                letter-spacing: 0.75px;

                a {
                    color: currentColor;
                }
            }

            &__item {
                color: $white;
                font-weight: 700;
                font-size: 18px;
                padding: 10px 0;

                &:after {
                    content: "";
                    position: absolute;
                    border-right: 3px solid $endocrine-light-blue;
                    border-bottom: 3px solid $endocrine-light-blue;
                    width: 12px;
                    height: 12px;
                    transform: translateY(-50%) rotate(-45deg);
                    top: 50%;
                    margin-left: 5px;
                }
            }
        }
    }

    &__menu-bottom {
        border-top: 1px solid $endocrine-light-blue;
        display: flex;
        flex-direction: column;

        a {
            display: flex;
            align-items: center;
            padding: 20px 0 10px;
        }
        span {
            margin-left: 8px;
            font-size: 18px;
            font-weight: 700;
            color: $white;
        }

        svg {
            width: 22px;
            height: 22px;
        }
    }
}
