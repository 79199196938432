.end-footer-partner {
    color: $endocrine-dark-navy;

    &__ctas{
      display: flex;
      flex-direction: column;
      
      .btn__text {
          color: $endocrine-dark-navy;
          font-weight: bold;
          font-size: rem(17);
          line-height: 32px;
      }
    }
  }