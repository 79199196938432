.end-site-logo {
    display: block;

    @include media($bp-end-header-min-small) {
        margin-right: 10px;
    }

    @include media($bp-end-header-min-medium) {
        margin-right: 50px;
    }

    a {
        text-decoration: none;
    }

    img {
        max-width: 100%;
        display: block;
    }
}

.end-site-logo__link {
    display: table-cell;
    vertical-align: middle;
    // padding-right: 38px;

    @media (max-width: 1360px) {
        // padding-right: 30px;
    }

    @media (max-width: 600px) {
        // display: block;
        // padding: 0;
    }
}
