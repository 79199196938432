// buttons.scss
// Define button styles here
// color variables
// base
$base-btn-color: $grey-600 !default;
$base-btn-bg: lighten($base-btn-color, 50%) !default;
$base-btn-border: darken($base-btn-bg, 20%) !default;
$base-btn-color--active: darken($base-btn-color, 10%) !default;
$base-btn-bg--active: darken($base-btn-bg, 10%) !default;
$base-btn-border--active: darken($base-btn-border, 10%) !default;
// primary
$primary-btn-color: $grey-50 !default;
$primary-btn-bg: darken($primary-btn-color, 60%) !default;
$primary-btn-border: darken($primary-btn-color, 60%) !default;
$primary-btn-color--active: $primary-btn-color !default;
$primary-btn-bg--active: darken($primary-btn-bg, 10%) !default;
$primary-btn-border--active: darken($primary-btn-border, 10%) !default;
// secondary
$secondary-btn-color: $white !default;
$secondary-btn-bg: $endocrine-light-blue !default;
$secondary-btn-border: $secondary-btn-bg !default;
$secondary-btn-color--active: darken($secondary-btn-color, 10%) !default;
$secondary-btn-bg--active: darken($secondary-btn-bg, 10%) !default;
$secondary-btn-border--active: darken($secondary-btn-border, 10%) !default;

// base
// .btn {
//     display: inline-block;
//     padding: 6px 9px;
//     color: $base-btn-color;
//     text-decoration: none;
//     border: none;
//     background-color: $base-btn-bg;
//     line-height: 1;
//     /* Prevent button from inheriting line-height */
//     vertical-align: middle;
//     transition: color 0.35s, background-color 0.35s, border-color 0.35s;
//     cursor: pointer;
//     font-size: rem(16);
//     font-weight: bold;
//     text-transform: uppercase;
//     letter-spacing: 0;

//     &:active,
//     &:hover {
//         outline: 0;
//         color: $base-btn-color--active;
//         background-color: $base-btn-bg--active;
//         border-color: $base-btn-border--active;
//     }

//     &:focus {
//         outline: 0;
//     }
// }

// modifiers
// primary
.btn--primary {
    @include font(acumin, condensed-bold);
    background: linear-gradient(105deg, #5f2167 0%, #5f2167 70%, #9636a3 70%);
    border: 0;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: rem(16);
    letter-spacing: 1.25px;
    padding: 13px 20px 15px 35px;
    text-transform: uppercase;
    width: 200px;

    &:hover {
        background: linear-gradient(
            105deg,
            #ad3ebd 0%,
            #ad3ebd 70%,
            #9636a3 70%
        );
        text-decoration: underline;
    }

    &__text {
        display: inline-block;
        color: $white;

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }

    &__icon-box {
        display: flex;
        align-items: center;
    }
}

// .btn--primary {
//     color: $primary-btn-color;
//     border: 1px solid $primary-btn-border;
//     background-color: $primary-btn-bg;

//     &:active,
//     &:hover {
//         color: $primary-btn-color--active;
//         background-color: $primary-btn-bg--active;
//         border-color: $primary-btn-border--active;
//     }
// }

// primary and solid {
.btn--primary.btn--solid {
    color: $white;
    border: 1px solid #5f2167;
    background: #5f2167;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    border-radius: 5px;
    justify-content: center;

    &:active,
    &:hover {
        background: #5f2167;
        border-color: #5f2167;
    }
}

// primary and disabled
.btn--primary.btn--disabled {
    opacity: 0.3;
}

// primary and ghost
.btn--primary.btn--ghost {
    color: $primary-btn-border;

    &:active,
    &:hover {
        color: $primary-btn-border--active;
    }
}

// secondary
.btn--secondary {
    color: $secondary-btn-color;
    border: 1px solid $secondary-btn-border;
    background-color: $secondary-btn-bg;
    padding: 13px 30px 15px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(14);
    font-weight: 700;
    width: 300px;

    &__text {
        color: currentColor;

        &:hover {
            color: currentColor;
        }
    }

    &:active,
    &:hover {
        color: $secondary-btn-color--active;
        background-color: $secondary-btn-bg--active;
        border-color: $secondary-btn-border--active;
    }
}

.btn--white {
    color: $hormone-darkest-pink;
    border: 1px solid $white;
    background-color: $white;
    padding: 15px 30px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border 0.3s ease-in-out;
    font-size: rem(14);
    font-weight: 700;
    width: 300px;

    &__text {
        color: $hormone-darkest-pink;

        &:hover {
            color: $hormone-darkest-pink;
        }
    }

    &:active,
    &:hover {
        color: darken($hormone-darkest-pink, 10%);
        background-color: darken($white, 10%);
        border-color: darken($white, 10%);
    }
}

// secondary and disabled
.btn--secondary.btn--disabled {
    opacity: 0.35;
}

// link and reset
.btn--link,
.btn--reset {
    border: 0;
    background-color: transparent;
    text-decoration: underline;

    &:active,
    &:hover {
        text-decoration: none;
        background-color: transparent;
    }

    &[disabled] {
        color: $grey-400;
        cursor: default;
        text-decoration: none;
    }
}

// disabled
.btn--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

// ghost
.btn--ghost {
    background-color: transparent;

    &:active,
    &:hover {
        background-color: transparent;
    }
}
// btn tags

.btn--tag {
    padding: 2px 4px;
    font-weight: bold;

    .icon {
        height: 16px;
        width: 16px;
        margin-left: 4px;
        fill: $base-btn-border;
        transition: fill 0.35s;
    }

    &:hover {
        .icon {
            fill: $base-btn-color;
        }
    }
}

// btn sorts
.btn--sort {
    border: 0;
    background-color: transparent;
    overflow: hidden;
}
// btn icon group

.btn--icon-group {
    position: relative;
    padding-right: 24px;

    .btn_icon-group {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .icon {
            position: absolute;
            right: 0;
            height: 1.846em;
            max-height: 24px;
            width: 1.846em;
            max-width: 24px;
            fill: currentColor;
            opacity: 1;
            visibility: visible;
            transition: opacity 0.35s;

            &:first-child {
                bottom: 35%;
                transform: translateY(35%);
            }

            &:last-child {
                top: 35%;
                transform: translateY(-35%);
            }
        }
    }
}

// from hormone button stylesheet
.btn--box-arrow {
    padding: 0;
    position: relative;
    color: $hormone-blue-dark;
    background: transparent;
    line-height: 1.5;
    display: inline-block;

    &:after,
    &:before {
        display: none;
    }

    .btn__box {
        cursor: pointer;
        display: inline-block;
        width: 10px;
        height: 10px;
        border: solid $endocrine-purple;
        border-width: 0 3px 3px 0;
        transform: rotate(-45deg);
    }
}

.btn--iconbox {
    position: relative;
    padding-left: rem(85);
    padding-right: rem(32);

    &:after,
    &:before {
        display: none;
    }

    .btn__icon {
        width: 56px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // the path below should change once sprites are working
        background-color: $hormone-blue;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .btn__svg {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 26px;
        height: 28px;

        &.btn__svg--email {
            margin-top: -2px;
        }

        &.btn__svg--heart {
            height: 25px;
            width: 30px;
        }

        &.btn__svg--zoom {
            height: 32px;
            width: 30px;
        }
    }

    &.btn--primary {
        .btn__icon {
            background-color: rgba(187, 41, 187, 0.6);
        }
    }

    &.btn--secondary {
        .btn__icon {
            background-color: rgba(0, 178, 169, 0.66);
        }
    }
}

.btn--small {
    width: auto;
}
