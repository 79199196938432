.collapse-pane {
}

.collapse-pane__content {
  transition: height 0.3s ease-out;
  overflow-y: hidden;
}

.collapse-pane--well {
  .collapse-pane__content {
    width: 100%;
  }
}
