$copyright-symbol-size: rem(12) !default;
$copyright-text-size: rem(12) !default;

.copyright {
    @include zero;
    display: inline-block;
    font-size: $copyright-text-size;
    line-height: $copyright-symbol-size;
    text-align: left;

    @media(max-width: $bp-max-medium) {
      display: block;
    }
}

.copyright__symbol {
    display: inline-block;
    font-size: $copyright-symbol-size;
    vertical-align: top;
}
