.end-lp-promo {
    & + & {
        margin-top: 80px;
    }

    a {
        text-decoration: none;
    }

    &__media {
        margin-bottom: 8px;
    }

    &__title {
        font-size: rem(30);
        letter-spacing: -0.5px;
    }

    &__description {
        margin-bottom: 0;
        font-size: rem(17);
    }

    .see-more-arrow {
        display: inline-block;
        border-right: 2px solid $endocrine-purple;
        border-bottom: 2px solid $endocrine-purple;
        transform: rotate(-45deg);
        width: 8px;
        height: 8px;
    }

    &__content {
        color: $endocrine-black;
    }
}
