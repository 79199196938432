// icons.scss
// Define icon styles here

// base

// icon styles
// best viewed at 16, 24, 36, and 48
.icon {
    display: inline-block;
    // width: rem(16);
    // height: rem(16);
    width: rem(20);
    height: rem(20);
    vertical-align: middle;
    fill: currentColor;
}

// sizing classes

// 24x24
.icon--24x24{
    height: rem(24);
    width: rem(24);
}

// 36x36
.icon--36x36{
    height: rem(36);
    width: rem(36);
}

// 48x48
.icon--48x48{
    height: rem(48);
    width: rem(48);
}
