.CoveoPager:not(.increase-specificity) {
    margin: 0;

    .coveo-pager-list-item {
        border: 0;
        padding: 0;

        a {
            color: white;
            border: none;
            background-color: $hormone-blue;
            display: inline-block;
            font-size: rem(16);
            font-weight: bold;
            padding: 2px 10px;
            position: relative;
            text-decoration: none;
            transition: background-color 0.35s;
            vertical-align: middle;

            &:hover {
                background-color: $hormone-blue-light;
            }
        }

        &.coveo-active a {
            background-color: $hormone-dark-navy;
            cursor: default;

            &:hover {
                background-color: $hormone-dark-navy;
            }
        }
    }

    li.coveo-pager-anchor {
        svg {
            color: currentColor;
            height: 14px;
            margin-bottom: 3px;
            width: auto;
        }
    }
}
