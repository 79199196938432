.end-footer-menu {
    @include outer-container;
    font-weight: bold;
    font-size: rem(13);

    a,
    li > span {
        display: block;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
    }

    &__list-wrap {
        display: block;
    }

    &__list {
        @include zero-list;
        display: flex;
        flex-wrap: wrap;
        margin-top: -22px;

        li:before {
            display: none;
        }
    }

    &__item {
        align-items: center;
        display: flex;
        flex-basis: 16.66%;
        margin-top: 22px;
        padding-left: 0;

        .end-footer-menu__icon {
            align-items: center;
            display: flex;
            flex: none;
            height: 48px;
            justify-content: flex-end;
            margin-right: 15px;
            width: 48px;

            img {
                flex: none;

                height: 100%;
                object-fit: contain;
                width: 100%;
            }
        }
    }
}
