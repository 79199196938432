.end-disclaimers {
  // @include horizontal-container-padding;
  background: $white;
  padding-top: 54px;
  padding-bottom: 52px;

  @media(max-width: $bp-max-large) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media(max-width: $bp-max-medium) {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.white {
    background: white;
  }

  &__inside {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
  }

  &__grid {
    display: flex;
    width: calc(100% + 50px);
    position: relative;
    left: -25px;

    @media(max-width: $bp-max-medium) {
      width: 100%;
      left: auto;
      display: block;
    }
  }

  &__item {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 25px;
    display: flex;
    flex-direction: column;

    @media(max-width: $bp-max-medium) {
      padding: 0;
      margin-bottom: 40px;
    }

    &:last-child {
      @media(max-width: $bp-max-medium) {
        margin-bottom: 0;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 17px;
      color: #000000;
      margin: 0;
      line-height: 1.4;
    }

    &-description {
      font-size: 13px;
      color: #000000;
      line-height: 1.65;
      margin: 8px 0 20px;
    }

    &-link {
      margin: 0;
      font-size: 15px;
      line-height: 1.58;
      font-weight: 800;
      margin-top: auto;

      .btn__text {
        color: $endocrine-dark-navy;
      }

      a:hover,
      a:focus {
        // text-decoration: none;
      }
    }
  }

}
