.end-info-nav {
    font-size: rem(16);

    @media(max-width: $bp-max-medium) {
        align-self: flex-start;
        font-size: rem(14);
    }

    a, li > span {
        display: block;
        color: $black;
        cursor: pointer;
    }

    &__menu {
        @include zero-list;
        display: flex;

        @media(max-width: $bp-max-small) {
            flex-wrap: wrap;
        }

        li:before {
            display: none;
        }
    }

    &__item {

        &:first-child {
            padding-left: 0;
        }

        .btn__text {
            font-size: rem(17);
            color: #002D72;

            @media(max-width: em(558)) {
                font-size: 12px;   
            }

            @media(max-width: $bp-max-small) {
                display: inline-block;
            }
        }

        .btn__box {
    
            @media(max-width: em(558)) {
                width: 8px;
                height: 8px;
            }

            @media(max-width: $bp-max-small) {
                display: inline-block;
            }
        }
        
        @media(max-width: $bp-max-small) {
            &:last-child { // only works if last item is wrapped
                padding-left: 0;
            }
        }
    }
}