$secondary-nav__parent-indent: rem(0);
$secondary-nav__child-indent: rem(12);
$secondary-nav__grandchild-indent: rem(24);

$secondary-nav__item-vert-padding: rem(8);
$secondary-nav__item-vert-margin: rem(8);
$secondary-nav__list-vert-margin: rem(16);

.secondary-nav {
	position: relative;
	background: $hormone-navy;
	position: relative;
	z-index: 0;
	padding: 50px 40px 50px 40px;
	width: 100%;
	
	@include media($bp-g-max-medium) {
		margin-left: auto;
		margin-right: auto;
		width: calc(100% - 80px);
	}

	.container--70-30 .container__col:last-child &,
	.container--30-70 .container__col:first-child & {
		display: inline-block;
	}

	.container--70-30 .container__col:first-child &,
	.container--30-70 .container__col:last-child & {
		display: none;
	}

	@include media($bp-g-max-medium) {
		.container--70-30 .container__col:last-child &,
		.container--30-70 .container__col:first-child & {
			display: none;
		}

		.container--70-30 .container__col:first-child &,
		.container--30-70 .container__col:last-child & {
			display: block;
		}
	}

	@include media($bp-g-max-medium) {
		padding: 0;
		background: none;
		margin-bottom: 30px;
	}
	
	@media(max-width: em(374)) {
		width: 100%;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100%;
		background: $hormone-navy;
		z-index: -1;

		@include media($bp-g-max-medium) {
			display: none;
		}
	}
}

.secondary-nav__toggle {
	display: none;
	font-weight: 800;
	font-size: 15px;
	height: 40px;
	padding: 8px 20px 4px 58px;
	border: none;
	background: none;
	color: white;
	position: relative;
	background: $hormone-navy;
	text-decoration: none;

	@include media($bp-g-max-medium) {
		display: inline-block;
		vertical-align: top;
	}

	@media(max-width: em(374)) {
		font-size: 14px;
	}

	&:hover,
	&:focus,
	&:active {
		outline: none;
		cursor: pointer;
		color: white;
	}

	span {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 0;
		left: 0;
		background: $hormone-dark-blue;

		&:before,
		&:after {
			content: '';
			width: 12px;
			height: 3px;
			background: white;
			position: absolute;
			top: 18px;
			left: 14px;
			transition: transform .2s ease;
		}

		&:after {
			transform: rotate(90deg);
		}
	}
}

.is-open .secondary-nav__toggle {
	span:after {
		transform: rotate(0deg);
	}
}

.secondary-nav__menu {
	@include zero-list;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 0;

	@include media($bp-g-max-medium) {
		display: none;
		background: $hormone-navy;
		padding: 15px 40px 30px 40px;
	}

	@include media($bp-g-max-small) {
		padding: 12px 24px 24px 24px;
	}
}

.secondary-nav__item,
.secondary-nav__subitem,
.secondary-nav__tertitem {

	&>a {
		display: block;
		font-weight: 800;
		font-size: 14px;
		line-height: 1.3;
		color: #FFFFFF;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		&.btn {
			display: inline-block;
			font-size: 14px;
			color: #000000;
			padding: 8px 16px 8px 30px;
			text-decoration: none;
			background: #00BBFF;
			position: relative;
			margin-top: 8px;
			width: auto;
			margin-bottom: 4px;

			&:hover,
			&:focus {
				background: $hormone-blue-lightest;
				text-decoration: underline;
			}

			&:after,
			&:before {
			  right: auto;
			  left: 13px;
				background: $hormone-black;
			}

			&:before {
				transform: rotate(135deg);
				margin-top: -2px;
			}

			&:after {
				transform: rotate(-135deg);
				margin-top: -6px;
			}
		}
	}

	&.is-active {
		&>a {
			text-decoration: underline;

			&.btn {
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}

	// @include media($bp-g-max-large) {
	// 	// hide all items except the
	// 	// active parent. But hide
	// 	// the text inside the active parent
	// 	// link
	// 	display: none;
	// 	&.is-current-parent {
	// 		display: block;
	// 	}
	// 	&>a {
	// 		display: none;
	// 	}
	// }
}

.secondary-nav__item {
	width: 100%;
	padding: 0;
	border-bottom: 1px solid $hormone-dark-blue;
	padding: 18px 0;

	&:last-child {
		border-bottom: none;
	}

	&:before {
		display: none;
	}

	&>a {
		width: 100%;
	}

	&>a {
		padding-left: $secondary-nav__parent-indent;
	}
}

.secondary-nav__submenu,
.secondary-nav__tertmenu {
	width: 100%;
	margin: 6px 0 5px 14px;
	padding: 0;

	li {
		display: block;
	}
}

.secondary-nav__subitem,
.secondary-nav__tertitem {
	clear: both;
	display: block;

	&:before {
		width: 5px;
		height: 5px;
		top: 15px;
		background: #DB30DB;
	}

	a {
		padding: 8px 0;
	}
}

.secondary-nav__tertmenu {
	clear: both;
	margin: 0;
}

.secondary-nav__tertitem {

	&>a {
		padding: 6px 0;
	}
}
