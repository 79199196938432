/* Note that this is a COMPLEMENTARY class to be used WITH a dropdown that should get the black background and positioning.  Primary styling should still be done through the elements main class.   */

.end-nav-bubble {
  background-color: $black;
  color: $white;
  min-width: 200px;
  padding: 30px;
  z-index: 3;
  white-space: nowrap;


  &:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $black;
    content: "";
    display: block;
    position: absolute;
      bottom: 100%;
      left: 30px;
  }

  ul, li {
    margin-left: 0;
    padding-left: 0;
  }

  &.member-login__menu {
    @include media($bp-end-header-min-medium) {
      li:after {
        content: '';
        position: absolute;
        border-right: 2px solid $endocrine-light-blue;
        border-bottom: 2px solid $endocrine-light-blue;
        width: 8px;
        height: 8px;
        transform: translateY(-50%) rotate(-45deg);
        top: 50%;
        margin-left: 5px;
      }
    }
  }

  a {
    color: $white !important;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .end-mobile-nav__primary-navigation & {
    background-color: transparent;
    padding: 0;
    white-space: normal;

    & ul {
      margin-top: 20px;
    }

    & li {
      margin-bottom: 15px;
    }
  }

  &.end-primary-nav__sub-drop {
    li {
      margin-bottom: 25px;
    }
  }
}
