// Slick Slider customization should happen per-component,
// however, there are some global settings that can be set in
// base/_slick-settings.scss

$slideshow-arrow-size: 64;
$slideshow-dots-size: 10;

.end-slideshow {
    // hide all slides except first until
    // slick slider initializes
    width: 100%;

    @include media($bp-g-max-large) {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 140px);
    }

    @include media($bp-g-max-medium) {
        width: calc(100% - 80px);
    }

    &__slider {
        position: relative;
    }

    .rich-text & {
        &__title {
            margin-top: 0;
        }
    }

    .end-image-block {
        opacity: 0;
        margin-bottom: 0;

        &__image {
            margin-bottom: 0;

            img {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &:focus {
            outline: none;
        }
    }

    .is-page-editor & .end-image-block,
    .slick-initialized .end-image-block {
        opacity: 1;
    }

    .end-image-block__title {
        display: none;
    }

    .end-image-block__media {
        border-bottom: none;

        figcaption {
            border-bottom: 0;
            max-width: 46.875rem;
            padding: 23px 25px;
        }
    }

    // SlickSlider customizations
    .slick-next,
    .slick-prev {
        z-index: 100;
        display: block;
        transition: background-color 0.3s;
        background: $endocrine-purple;
        color: white;
        border-radius: 0 !important;
        margin-top: -13px;
        top: 35%;
        transform: translateY(-20%);

        @media (max-width: em(700)) {
            display: none !important;
        }

        // the button, the icon container
        // and svg need to have dimenions
        // explicitly defined
        &,
        & .icon,
        & svg {
            width: 50px;
            height: 50px;
        }

        & .icon {
            position: relative;
        }

        & svg {
            // width: 12px;
            // height: 21px;
            width: 25px;
            height: 25px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:focus,
        &:hover {
            background-color: $hormone-blue-dark;
        }

        &::before {
            content: "";
        }
    }

    .slick-next {
        right: -26px;
    }

    .slick-prev {
        left: -26px;
    }

    .slick-dots {
        position: static;
        text-align: left;

        li {
            margin: 0 10px 0 0;
            padding: 0;

            &:before {
                display: none;
            }

            // match specificy in origin css
            button {
                &::before {
                    content: "";
                    display: none;
                }
            }

            &:focus {
                // add another box shadow to show
                // rounded outline
                box-shadow: 0 0 0 2px $white, 0 0 0 4px $hormone-blue,
                    0 0 0 6px #5b9dd9;
            }
        }

        li,
        button {
            width: rem($slideshow-dots-size * 5);
            height: rem($slideshow-dots-size);
            // border-radius: rem($slideshow-dots-size * 2);
        }

        button {
            width: rem(50);
            height: rem(10);
            margin: 0 auto;
            padding: 4px;
            // background-color: $white;
            background-color: $endocrine-gray;
            // box-shadow: 0 0 0 2px $white, 0 0 0 4px $hormone-blue;

            transition: background-color 0.3s;
        }

        .slick-active {
            button {
                background-color: $endocrine-dark-navy;
            }
        }
    }

    .slick-list {
        background-color: $white;
    }

    &__description {
        color: $grey-500;
    }

    &__caption-label {
        font-weight: 800;
        font-size: 15px;
        color: $endocrine-light-blue;
        letter-spacing: 1.25px;
        text-transform: uppercase;
    }
}
