$bp-product-overview-min: 681px;

.product-overview {
    margin-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;

    .container--70-30 .container__col:first-child &,
    .container--30-70 .container__col:last-child & {
        @include media($bp-g-max-large) {
            margin-left: auto;
            margin-right: auto;
            width: calc(100% - 140px);
            padding-left: 0;
            padding-right: 0;
        }

        @include media($bp-g-max-medium) {
            width: calc(100% - 80px);
        }
    }

    @media (min-width: $bp-product-overview-min) {
        display: flex;
    }

    &__gallery {
        margin-bottom: 45px;

        @media (min-width: $bp-product-overview-min) {
            flex: none;
            margin-bottom: 0;
            margin-right: 60px;
            width: 33.33%;
        }
    }

    &__carousel {
        .slick-list {
            background: none;
        }

        .slick-slide {
            font-size: 0;
        }

        &--main {}

        &--thumbs {
            .slick-list {
                padding: 10px 2px;
            }

            .slick-main-active {
                box-shadow: 0 0 0 2px $hormone-blue;
                position: relative;
                z-index: 1;
            }
        }

        img {}

        .slick-prev,
        .slick-next {
            background: transparent radial-gradient(circle, #fff 50%, transparent 50%) no-repeat center center;
            height: 30px;
            width: 30px;
            z-index: 2;

            &::before {
                color: $hormone-blue;
                font-size: 30px;
                opacity: 1;
            }

            &.slick-disabled {
                cursor: default;
                opacity: 0.5;
            }
        }

        .slick-prev {
            left: -15px;
        }

        .slick-next {
            right: -15px;
        }
    }

    &__content {}

    &__pricing-section {
        display: flex;
        flex-wrap: wrap;
    }

    &__pricing {
        flex: none;
        flex-basis: 305px;
        padding-right: 32px;

        // Have the regular price listing display when the sale price listing is display none
        &#prodOvPrng2[style="display: none;"]+&#prodOvPrng {
            flex: 1 100%;
            flex-basis: none;
        }

        li {
            padding-left: 0;
        }
    }

    &__cart-action {
        flex: none;

        .form {
            margin-bottom: 32px;
            width: auto;
        }

        .form-item {
            margin-bottom: 16px;
            margin-right: 0;

            input {
                width: 100px;
            }
        }
    }

    &__body {
        margin-bottom: 30px;
        padding: 0;
        width: 100% !important;
    }

    &__format-sale {
        width: 100%;

        tbody {
            tr {
                border-top: 1px solid $base-border-color;
            }

            th {
                text-align: right;
                width: 33%;
            }
        }
    }

    &__cart-container {
        .popover {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1010;
            display: none;
            padding: 10px;
            text-align: left;
            background-color: #ffffff;
            -webkit-background-clip: padding-box;
            -moz-background-clip: padding;
            background-clip: padding-box;
            border: 3px solid #d9d9d9;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            white-space: normal;
            width: 460px;
        }

        @media screen and (max-width: 460px) {
            .popover {
                width: 350px;
            }
        }

        .popover.bottom {
            margin-top: 20px;
        }

        .popover .arrow,
        .popover .arrow:after {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
        }

        .popover .arrow {
            border-width: 11px;
        }

        .popover .arrow:after {
            border-width: 10px;
            content: "";
        }

        .popover.bottom .arrow {
            left: 70%;
            margin-left: -11px;
            border-top-width: 0;
            border-bottom-color: #d9d9d9;
            border-width: 14px;
            top: -28px;
        }

        .popover.bottom .arrow:after {
            top: 4px;
            margin-left: -10px;
            border-top-width: 0;
            border-bottom-color: #ffffff;
        }

        .popover-title {
            font-size: 24px;
            font-weight: normal;
        }

        .popover-close {
            position: absolute;
            right: 10px;
            top: -10px;
        }
    }
}