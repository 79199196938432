.end-primary-nav {
    width: 100%;
    @include font(acumin, bold);
    font-size: rem(18);

    a,
    li > span {
        display: block;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        color: $black;

        .end-mobile-nav__primary-navigation & {
            color: $white;
        }
    }

    .end-primary-nav__menu-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .end-mobile-nav__primary-navigation & {
            border-bottom: 1px solid rgba($endocrine-light-blue, 0.5);
            margin-bottom: 20px;
            padding-bottom: 20px;
        }

        .end-primary-nav__get-started {
            @include font(acumin, light);
            display: none;
            font-size: rem(13);
            // padding-bottom: 25px;
            margin-top: -25px;

            @include media($bp-end-header-min-large) {
                display: block;

                .end-mobile-nav__primary-navigation & {
                    display: none;
                }
            }

            a {
                display: inline;
                color: $endocrine-dark-navy;
                font-weight: 600;
                text-decoration: underline;

                &:hover {
                    color: $endocrine-dark-blue;
                }
            }
        }
    }

    .end-primary-nav__menu {
        @include zero-list;
        display: flex;

        li:before {
            display: none;
        }

        .end-mobile-nav__primary-navigation & {
            display: block;
            width: 100%;
        }
    }

    .end-primary-nav__item {
        padding-left: 30px;
        padding-bottom: 25px;
        white-space: nowrap;

        .end-mobile-nav__primary-navigation & {
            padding: 10px 0;

            &.has-submenu {
                padding-right: 25px;
            }
        }

        &:first-child {
            padding-left: 0;

            .end-primary-nav__label--current:after,
            .end-primary-nav__label--current:before {
                transform: translateX(-50%);
            }
        }

        &--search {
            position: relative;
            width: 72px;
            height: auto;

            .end-primary-nav__icon {
                display: none;
                opacity: 0.5;
                position: absolute;
                top: 5px;
                left: 37px;
                width: 18px;
                height: 18px;
                vertical-align: middle;
                z-index: 1;
            }

            input {
                background: rgba(white, 0.9)
                    url("../img/common/svg-sprite/search_header.svg") no-repeat
                    left 5px center;
                background-size: 18px 18px;
                border: 1px solid transparent;
                border-radius: 30px;
                cursor: pointer;
                font-size: 15px;
                height: 28px;
                left: 30px;
                outline: none;
                padding: 15px;
                position: absolute;
                top: -2px;
                transition: all 0.5s ease;
                width: 29px;
                z-index: 2;

                &:hover,
                &:focus {
                    border: 1px solid gray;
                }
            }

            input:-moz-placeholder,
            input::-webkit-input-placeholder {
                color: transparent;
            }

            input::-webkit-search-decoration,
            input::-webkit-search-cancel-button {
                display: none;
            }

            input:focus {
                width: 250px;
                padding-left: 30px;
                cursor: auto;

                -webkit-box-shadow: 0px 0px 1px rgba(44, 42, 41, 0.5);
                -moz-box-shadow: 0px 0px 1px rgba(44, 42, 41, 0.5);
                box-shadow: 0px 0px 1px rgba(44, 42, 41, 0.5);
            }

            .search-btn {
                right: 0;
                position: absolute;
                background-color: #ffffff;
                border: none;
                outline: none;
            }
        }
    }
}

.end-primary-nav__label {
    &--current:after,
    &--current:before {
        content: "";
        display: block;
        border-style: solid;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(calc(-50% + 15px));
        z-index: 1;
    }

    &--current:before {
        border-color: #bdbdbd transparent transparent transparent;
        border-width: 11px;
        display: none;

        @include media($bp-end-header-min-large) {
            display: block;
        }
    }

    &--current:after {
        border-color: $white transparent transparent transparent;
        border-width: 10px;
        display: none;

        @include media($bp-end-header-min-large) {
            display: block;
        }
    }
}

.end-primary-nav__sub-item {
    margin-bottom: 10px;

    @include media($bp-end-header-min-large) {
        margin-bottom: 0;
    }
}

.end-mobile-nav__primary-navigation .end-primary-nav__item--search {
    display: none;
}

.end-mobile-nav__primary-navigation .end-secondary-nav {
    display: block;
}

.end-primary-nav__mobile-trigger {
    background-color: transparent;
    border: 3px solid $endocrine-light-blue;
    border-left-width: 0;
    border-top-width: 0;
    display: block;
    height: 14px;
    margin-left: 10px;
    padding: 0;
    position: absolute;
    top: 14px;
    right: 0;
    transform: rotate(45deg);
    transform-origin: 10.5px 10.5px;
    transition: transform 0.25s;
    width: 14px;
    z-index: 1;

    &.is-open {
        transform: rotate(-135deg);
    }

    @include media($bp-end-header-min-large) {
        display: none;
    }
}

.end-primary-nav__sub-drop {
    display: none;

    &:before {
        display: none;
        // left: 12px;
        left: 25%;
    }

    @include media($bp-end-header-min-large) {
        position: absolute;
        top: calc(100% + -15px);
        left: -30px;

        &:before {
            display: block;
        }

        .end-primary-nav__item:hover & {
            display: block;
        }
    }
}
