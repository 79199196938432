$search-facets__basic-padding: rem(16);
$search-facets__values-indent: rem(16);
.search-facets {
}

.search-facets__group-top,
.search-facets__group-more,
.search-facets__group-heading,
.search-facets__values-wrapper {
	//padding: $search-facets__basic-padding;
	padding-bottom: 0;
}

.search-facets__filter-close {
	display: none;
	margin-top: 20px;
	margin-bottom: 0;

	@media(max-width: $bp-max-medium) {
		display: block;
	}

	button {
		font-weight: 800;
		font-size: 15px;
		height: 40px;
		padding: 4px 20px 4px 58px;
		border: none;
		background: none;
		color: white;
		position: relative;
		background: $hormone-navy;
		text-decoration: none;
		margin-bottom: 0;

		@include media($bp-g-max-large) {
			display: inline-block;
			vertical-align: top;
		}

		@media(max-width: em(374)) {
			font-size: 14px;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			background: $hormone-dark-blue;
			width: 40px;
			height: 100%;
		}

		&:hover,
		&:focus,
		&:active {
			cursor: pointer;
			color: white;
			background: $hormone-navy;
			text-decoration: underline;
		}

		.icon {
			position: absolute;
			width: 20px;
	    height: 20px;
			top: 8px;
			left: 8px;
			color: white;
			z-index: 2;
		}
	}
}


.search-facets__clear-all {

	button {
		font-size: 15px;
		font-weight: 800;
		color: $hormone-blue-dark;
		padding: 0;
		border: none;
		background: transparent;
		display: inline-block;
		vertical-align: top;

		&:focus,
		&:hover {
			text-decoration: underline;
			outline: none;
		}
	}
}

.search-facets__group {
	@include clearfix;
	clear: both;
	background: #571C52;
	margin-bottom: 16px;

	// Root Group Heading
	.search-facets__group-heading {
    height: 40px;
    padding: 10px 20px 10px 58px;
    border: none;
    background: none;
    color: white;
    position: relative;
    background: #571C52;

		h4 {
			font-weight: 800;
			font-size: 15px;
			color: white;
			margin: 0;
		}

		button {
			position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      left: 0;
      background: $hormone-dark-pink;

      &:before,
      &:after {
        content: '';
        width: 12px;
        height: 3px;
        background: white;
        position: absolute;
        top: 18px;
        left: 14px;
        transition: transform .2s ease;
      }

			&:after {
				transform: rotate(90deg);
			}

			&:focus {
				background: $hormone-dark-pink;
			}
		}
	}


	&.is-open {
		.search-facets__group-heading {
			border-bottom: 0;
		}

		.search-facets__group-wrapper {
			.is-open.search-facets__group {
				.search-facets__group-heading {
					//border-bottom: 1px solid $grey-400;
				}
			}
		}
	}

	.search-facets__group {
		padding: 20px;
		margin-bottom: 0;

		.search-facets__group-heading,
		.search-facets__group-body {
			background: #461542;
		}
	}

	.search-facets__group + .search-facets__group {
		padding-top: 0;
	}

	.search-facets__group:last-child {
		padding-bottom: 0;
	}
}

// Child Group headings
.search-facets__group-wrapper {
	.search-facets__group-heading {
	}
}

.search-facets__group-more,
.search-facets__group-toggle,
.search-facets__group-clear,
.search-facets__value-expand {
	background: transparent;
	border: 0;
	cursor: pointer;
	color: white;
}

.search-facets__values-expand {
	transition: transform 0.2s;
	color: white;

	&.is-open {
		transform: rotate(180deg);
	}

	.icon {
		width: 18px;
		height: 16px;
		vertical-align: top;
		position: relative;
		top: 2px;
	}
}

.search-facets__group .search-facets__group-heading .search-facets__group-toggle.is-open:after {
  transform: rotate(0deg);
}

.search-facets__group-bottom {
	width: 100%;
	text-align: right;
	padding: 0 20px;
}

.search-facets__group-clear {
	display: inline-block;
	font-weight: 800;
	color: white;
	text-decoration: underline;
	padding: 0;
	font-size: rem(14);
	line-height: 1.71;

}

.search-facets__group-selected {
	padding:  11px 20px 16px;
	background: #72246C;
}

.search-facets__group-more {
	text-align: right;
  padding: 0 20px;

	button {
		font-size: rem(14);
		background: transparent;
		border: 0;
		cursor: pointer;
		padding: 0;
		text-decoration: underline;
		color: white;
		font-weight: 600;

		&:hover {
			text-decoration: none;
		}
	}
}

.search-facets__value-expand {
	float: right;

	&:focus {
		outline: none;
	}

	&.is-open .icon {
		transform: rotate(180deg);
		left: 6px;
		top: 9px;
	}

	.icon {
		width: 18px;
		height: 16px;
		vertical-align: top;
		position: relative;
		top: 2px;
	}
}

.search-facets__group-values {
	list-style: none;
	margin: 0;

	&.is-level-1,
	&.is-level-2 {
		margin-left: $search-facets__values-indent;
	}

	li {
		padding: 0;
		margin-top: 2px;

		&:before {
			display: none;
		}

		label {
			font-size: rem(14);
			line-height: 1.71;
			display: inline-block;
      vertical-align: top;
			position: relative;
			color: white;

			&:before {
				content: '';
				width: 22px;
				height: 22px;
				position: relative;
				display: inline-block;
				background: #fff;
				margin-right: 8px;
				top: 6px;
				border: 2px solid #571C52;
			}

			&:after {
				display: none;
				content: '';
				background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.684.02c.307-.056.586.009.835.195.25.186.403.432.461.74.058.306-.01.59-.202.85l-5.96 8.428c-.193.242-.438.39-.735.447a1.01 1.01 0 0 1-.82-.196c-.154-.111-.558-.507-1.21-1.186A99.866 99.866 0 0 1 1.21 7.331l-.893-.949A1.135 1.135 0 0 1 0 5.572c0-.316.115-.585.346-.809.23-.223.499-.33.806-.32.307.009.566.134.777.376l2.852 2.986L9.964.465a1.19 1.19 0 0 1 .72-.446z' fill='%23007DBA' fill-rule='evenodd'/%3E%3C/svg%3E");
				background-color: transparent;
				background-repeat: no-repeat;
				background-position: center center;
				width: 12px;
				height: 11px;
				background-size: cover;
				position: absolute;
				left: 5px;
				top: 11px;
			}

			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}

		input {
			border: 0;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}

		input:checked ~ label {
			font-weight: 800;
			&::after {
				display: block;
			}
		}

		input:focus ~ label::before {
			border-color: $hormone-pink;
		}
	}
}

.search-facets__group-wrapper {
	overflow: hidden;
}

.search-facets__group-body {
	transition: height 0.2s ease-in;
	padding-bottom: 20px;

	.search-facets__group-body {
		padding-left: 0;
	}
}

.search-facets__values-wrapper {
	overflow: hidden;
	padding: 16px 20px;

	// when a group has no direct values, but does have childGroups
	// we want to remove the top-padding from the first childGroup
	&.has-no-values {
		padding: 0;
	}

	.search-facets__values-wrapper {
		padding: 0;
	}
}
