$search-results__sort-arrow-size: 24;

.search-results {

	@include media($bp-g-max-large) {
		margin-left: auto;
		margin-right: auto;
		width: calc(100% - 140px);
	}

	@include media($bp-g-max-medium) {
		width: calc(100% - 80px);
	}

	&.is-refreshing {
		.search-results__item-wrapper * {
			color: $grey-200 !important;
		}
	}
}

.search-results__item-wrapper {
	position: relative;
	border-top: 1px solid $hormone-black;
	margin-top: 8px;
	padding-top: 54px;

}

.search-results__items {
	min-height: rem(350);
	margin-bottom: rem(30);

	.list-item {
		margin-bottom: rem(45);

    @media(max-width: $bp-max-small) {
      margin-bottom: rem(40);
    }

    &:last-child {
      margin-bottom: 0;
    }
	}
}


.search-results__sort-bar {
	@include clearfix;
	text-align: left;

	&>span {
		display: inline-block;
		vertical-align: top;
		font-weight: 800;
		font-size: 14px;
		letter-spacing: 1.5px;
		line-height: 1.29;
		text-transform: uppercase;
		top: 2px;
		position: relative;

		@media(max-width: em(500)) {
			display: block;
			top: 0;
			margin-bottom: 10px;
		}
	}

	ul {
		display: inline-block;
		vertical-align: top;
		list-style: none;
		margin: 0;

		@media(max-width: em(500)) {
			display: block;
		}
	}

	li {
		margin-left: 12px;
		padding: 0;
		display: inline-block;
		vertical-align: top;
		color: $hormone-blue-dark;

		@media(max-width: em(500)) {
			display: block;
			margin-left: 0;
		}

		&:before {
			content: '';
			display: inline-block;
			vertical-align: top;
			position: relative;
			width: 1px;
			background: $hormone-black;
			height: 23px;
			top: 0;
			left: 0;
			border-radius: 0;
			margin-right: 14px;

			@media(max-width: em(500)) {
				display: none;
			}
		}

		&:first-child {

			&:before {
				display: none;
			}
		}
	}

	@media(max-width: em(415)) {
		&>span {
			display: block;
			padding-bottom: rem(12);
		}
	}

	@media(max-width: em(380)) {

		ul {
			display: block;
		}

		li {
			@include clearfix;
			clear: both;
			width: 100%;
			margin-bottom: rem(12);
		}
	}
}

.search-results__sorter {
	cursor: pointer;
	position: relative;
	
	&.is-active {

		.search-results__sort-label {
			text-decoration: underline;
		}
	}
}

.search-results__sort-label {
	display: inline-block;
	vertical-align: top;
	font-size: 15px;
	font-weight: 800;
	border: none;
	background: none;
	color: $hormone-blue-dark;
}

.search-results__sort-dir {
	display: inline-block;
	vertical-align: top;
	position: relative;
	top: 8px;

	button,svg {
		width: rem($search-results__sort-arrow-size);
		height: rem($search-results__sort-arrow-size);
	}

	button {
		display: block;
		text-align: center;
		background: transparent;
		border: 0;
		padding: 0;
		margin-top: rem(-(32 / 2));
		padding-top: rem(3);

		&.is-selected {
			color: $hormone-blue;
		}
	}

	svg {
		fill: currentColor;
	}
}

.search-results__status {
	font-size: 15px;
	margin-bottom: 8px;

	@include media($bp-g-max-medium) {
		margin-bottom: 12px;
	}
}

.search-results__active-facets {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 13px;

	> span {
		margin-right: 9px;
		margin-bottom: 7px;
	}

	ul {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
	}

	li {
		background: $hormone-tan;
		position: relative;
		padding: 0 38px 0 12px;
		margin-right: 9px;
		margin-bottom: 9px;

		&:before {
			display: none;
		}

		span {
			font-weight: 800;
			font-size: 12px;
			color: #000000;
			letter-spacing: 1px;
			text-transform: uppercase;
			display: block;
			padding: 7px 0 6px;
			line-height: 1;
			background: transparent;
		}
	}

	button {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 0 13px;
		border: 0;
		background: transparent;
		cursor: pointer;
		color: white;
		background: $hormone-blue;
		transition: background .2s ease;

		.icon {
			display: block;
			width: 19px;
			height: 19px;
			vertical-align: top;
			position: absolute;
			top: 1px;
			right: 6px;
		}

		&:hover {
			background: $hormone-blue-light;
		}
	}
}

.search-results__load-more {
	transition: all 0.2s;
	margin-bottom: 60px;
	text-align: center;

	.search-results__spinner {
		position: static;
		display: inline;

		img {
			width: rem(24);
			height: rem(24);
			vertical-align: middle;
			margin-left: rem(14);
		}
	}
}


.search-results__pagination {
	margin-top: 40px;

	.btn {
		display: inline-block;
		vertical-align: middle;
		padding: 2px 10px;
		color: white;
		text-decoration: none;
		border: none;
		background-color: $hormone-blue;
		transition: color 0.35s, background-color 0.35s, border-color 0.35s;
		font-size: rem(16);
		font-weight: bold;
		position: relative;
		line-height: 1.6;
		margin-right: 5px;
		margin-bottom: 5px;

		&:after,
		&:before {
			display: none;
		}

		&[disabled] {
			background: none;
			color: $hormone-black;
			opacity: .3;
			font-weight: 600;

			&:hover {
				color: $hormone-black;
				background: none;
			}
		}

		&:hover {
			background: $hormone-blue-light;
		}
	}
}

.search-results__filter-open {
	display: none;
	font-weight: 800;
	font-size: 15px;
	height: 40px;
	padding: 4px 20px 4px 58px;
	border: none;
	background: none;
	color: white;
	position: relative;
	background: $hormone-navy;
	text-decoration: none;
	margin-bottom: 40px;

	@include media($bp-g-max-medium) {
		display: inline-block;
		vertical-align: top;
	}

	@media(max-width: em(374)) {
		font-size: 14px;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background: $hormone-dark-blue;
		width: 40px;
		height: 100%;
	}

	&:hover,
	&:focus,
	&:active {
		outline: none;
		cursor: pointer;
		color: white;
		background: $hormone-navy;
		text-decoration: underline;
	}

	.icon {
		position: absolute;
		width: 20px;
    height: 20px;
		top: 10px;
		left: 11px;
		color: white;
		z-index: 2;
	}
}

.search-results__spinner {
	position: absolute;
	top: rem(100);
	left: calc(50%  - 88px); // subtract half width of spinner icon
	text-align: center;

	img {
		width: rem(80);
		height: rem(80);
	}
}


.search-results--simple {
  .search-results__load-more {
  }
}
