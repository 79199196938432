.primary-nav{
    width: 100%;
    margin-top: 40px;

    @media(max-width: 1120px) {
      margin-top: 50px;
    }

    @media(max-width: $bp-max-medium) {
      margin-top: 0;
    }

    a, li > span {
        display: block;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
    }

    .utility-nav {
      display: none;

      @media(max-width: $bp-max-medium) {
        display: block;
      }
    }
}

.primary-nav__toggle {
    display: none;
    cursor: pointer;
    padding: 0 0 23px;

    @media(max-width: $bp-max-medium) {
        display: block;
    }
}

.primary-nav__menu-wrap {
    display: block;

    @media(max-width: $bp-max-medium) {
        display: none;
        background: $hormone-navy;
        padding: 26px 40px;
        width: calc(100% + 80px);
        position: relative;
        left: -40px;
    }
}

.primary-nav__menu {
    @include zero-list;
    display: flex;

    li:before {
      display: none;
    }

    @media(max-width: $bp-max-medium) {
        flex-direction: column;
    }
}


.primary-nav__item {
    position: relative;
    transition: background-color 0.36s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
    flex-shrink: 1;
    padding: 0 40px 0 0;

    @media(max-width: $bp-max-medium) {
      padding: 6px 0;
      width: 100%;
    }

    &:focus {
      @media(max-width: $bp-max-medium) {
        outline: none;
      }
    }

    &:last-child {
      padding-right: 0;
    }

    > span,
    > a,
    > span > a {
      display: block;
      height: 70px;

      @media(max-width: $bp-max-medium) {
        height: auto;
      }
    }

    > span > a,
    > a {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.22;
      padding-bottom: 28px;
      position: relative;

      @media(max-width: 1120px) {
        font-size: 16px;
      }

      @media(max-width: $bp-max-medium) {
        padding-top: 16px;
        padding-bottom: 16px;
        display: inline;
        vertical-align: top;
      }

      &:after {
        content: '';
        width: 100%;
        height: 6px;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.323C0 .546.6 0 1.438 0c.84 0 1.416.546 1.416 1.323 0 .756-.552 1.321-1.44 1.321C.574 2.644 0 2.08 0 1.323' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E");
        background-color: transparent;
        background-repeat: repeat-x;
        background-position: top left;
        position: absolute;
        bottom: 18px;
        left: 0;
        opacity: 0;
        transition: opacity 0.4s ease-out;

        @media(max-width: $bp-max-medium) {
          bottom: 8px;
        }
      }
    }

    &.is-open, &:hover {

      > span > a,
      > a {
        text-decoration: none;

        &:after {
          opacity: 1;

          @media(max-width: $bp-max-medium) {
            opacity: 0;
          }
        }
      }
    }

    &.is-active {
      > span > a,
      > a {
        text-decoration: none;

        &:after {
          opacity: 1;
        }
      }
    }

    &.is-open {
        .primary-nav__submenu {
            height: auto;
            opacity: 1;
            visibility: visible;
        }

        .primary-nav__submenu-trigger {
          &:before {
            transform: rotate(135deg);
          }

          &:after {
            transform: rotate(-135deg);
          }
        }
    }

}

.primary-nav__submenu {
    @include zero-list;
    position: absolute;
    display: block;
    background-color: $hormone-dark-navy;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 0.4s ease-out, visibility 0.4s ease-out;
    z-index: 100;
    top: 58px;
    padding: 15px;
    width: calc(100% - 38px);
    min-width: 220px;

    @media(max-width: $bp-max-medium) {
      width: 100%;
      position: relative;
      transition: none;
      height: auto;
      visibility: visible;
      top: auto;
      opacity: 1;
      padding: 0;
      display: none;
      background-color: transparent;
    }

    li {
        display: block;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.2;
        margin: 0;
        padding: 0;

        @media(max-width: $bp-max-medium) {
          font-size: 14px;
        }

        a {
          padding: 8px 12px;
          display: block;

          @media(max-width: $bp-max-medium) {
            padding-left: 20px;
          }
        }

        &.is-active {
          > span > a,
          > a {
            text-decoration: underline;
          }
        }
    }

}

.primary-nav__toggle {
    border: 0;
    background: 0;
    position: relative;

    &:focus {
      outline: none;
    }
}

.primary-nav__label-open,
.primary-nav__label-close {
  font-size: rem(16);
  font-weight: bold;
  line-height: 1;
  color: white;
}

.primary-nav__label-open {
    display: none;
    @media(max-width: $bp-max-medium) {
        display: block;
    }
}

.primary-nav.is-open .primary-nav__label-open {
    display: none;
}


.primary-nav__label-close {
    display: none;
}

.primary-nav.is-open .primary-nav__label-close {
    @media(max-width: $bp-max-medium) {
        display: block;
    }
}

.primary-nav {
  &__submenu-trigger {
    background: $hormone-blue;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 2px;
    right: 0;
    border-radius: 50%;
    display: none;

    @media(max-width: $bp-max-medium) {
      display: block;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 9px;
      left: 14px;
      transition: transform .35s ease;
      width: 3px;
      height: 8px;
      background: white;
    }

    &:before {
      top: 9px;
      left: 14px;
      transform: rotate(45deg);
    }

    &:after {
      top: 9px;
      left: 10px;
      transform: rotate(-45deg);
    }
  }
}


// Icon style and animation code cribbed from
// http://codepen.io/pedrocampos/pen/gufrx/
.primary-nav__toggle-icon {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: rem(30);
    height: rem(30);
    position: absolute;
    top: -7px;
    right: -42px;

    span {
        margin: 0 auto;
        position: relative;
        top: 12px;
        transition-duration: 0s;
        transition-delay: 0.2s;

        &::before, &::after {
            position: absolute;
            content: '';
            transition-property: margin, transform;
            transition-duration: 0.2s;
            transition-delay: 0.2s, 0s;
        }

        &, &::before, &::after {
            display: block;
            width: rem(26);
            height: 3px;
            background-color: white;
        }

        &::before {
            margin-top: -9px;
        }

        &::after {
            margin-top: 9px;
        }
    }
}

.primary-nav.is-open .primary-nav__toggle-icon {
    span {
        background-color: rgba(0, 0, 0, 0.0);
        transition-delay: 0.2s;

        &::before, &::after {
            margin-top: 0;
            transition-duration: 0.2s;
            transition-delay: 0.2s, 0s;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }

    }
}

.about-link-mobile {
  display: none;
  border-top: 1px solid $hormone-blue-dark;
  margin-top: 10px;
  padding-top: 15px;

  @media(max-width: $bp-max-medium) {
    display: block;
  }

  a {
    font-weight: 500;
    font-size: 14px;
    display: block;
    padding: 8px 0;
  }
}
