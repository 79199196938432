.end-newsletter-subscribe {
    // background-image: linear-gradient(
    //     to right,
    //     transparent 25%,
    //     $endocrine-gray 40%),
    // url(/assets/img/common/dna-signup.png);
    
    background-repeat: no-repeat;
    background-size: contain;
    padding: 80px 0 80px 0;

    @media(max-width: $bp-max-medium) {
        background: $endocrine-gray !important; // overrides inline styling required for replacing image
    }

    @media(max-width: $bp-max-small) {
        padding-top: 45px;
        padding-bottom: 45px;
        background: $endocrine-gray !important;
    }

    &__inside {
        display: flex;
        @include outer-container;

        .secondary-cta {
            margin-top: 20px;
            margin-bottom: 0;
            cursor: pointer;

            a {
                color: $endocrine-dark-navy;
                font-weight: bold;
                text-decoration: underline;

                &:hover {
                    color: $endocrine-dark-blue;
                }
            }
        }
    }

    &__text {
        width: 80%;
        margin-left: auto;
        // border-bottom: 1px solid $endocrine-light-blue;
        // padding-bottom: 45px;

        @media(max-width: $bp-max-large) {
            width: 100%;
            padding-left: 70px;
            padding-right: 70px;

        }

        @media(max-width: $bp-max-medium) {
            width: 100%;
            padding-left: 40px;
            padding-right: 40px;
        }

        // @media(max-width: $bp-max-small) {
        //     width: 100%;
        //     padding-left: 40px;
        //     padding-right: 40px;
        // }
    }

    &__eyebrow {
        @include font(acumin, condensed-bold);
        font-size: rem(20);
        letter-spacing: 1.67px;
        text-transform: uppercase;
        color: $endocrine-darker-blue;
        // margin-bottom: 10px;

        @media(max-width: $bp-max-small) {
            font-size: rem(16);
        }
    }

    &__title {
        margin-bottom: 5px;

        @media(max-width: $bp-max-small) {
            font-size: rem(24);
        }
    }

    &__description {
        font-size: rem(17);
        margin-bottom: 20px;
    }

    &__form-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 25px;

        @media(max-width: $bp-max-medium) {
            flex-direction: column;
        }
    }

    &__form-item {
        flex: auto;
        padding: 0 15px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        input {
            font-size: rem(16);
            color: $black;
            border: 2px solid #CBD8E0;
            padding: 12px;
            width: 100%;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $endocrine-dark-gray;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: $endocrine-dark-gray;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: $endocrine-dark-gray;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: $endocrine-dark-gray;
            }
            &:placeholder {
                color: $endocrine-dark-gray;
            }
        }

        @media(max-width: $bp-max-medium) {
            padding: 0;
            margin-bottom: 12px;
        }
    }
    
    &__checkbox-item {
        input {
            opacity: 0;
            position: absolute;
        }

        label {
            position: relative;
            
            a {
                text-decoration: underline;

                &:hover {
                    color: $endocrine-dark-blue;
                }
            }
        }

        label#terms-label:before {
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            background: $white;
            border: 2px solid #CBD8E0;
            position: relative;
            margin-right: 8px;
            top: 5px;
            cursor: pointer;
        }

        input:checked ~ label:after {
            content: '';
            display: block;
            position: absolute;
            width: 6px;
            height: 10px;
            top: 3px;
            margin-left: 7px;
            border: solid $endocrine-dark-navy;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    &__bottom-area {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: rem(13);
        border-bottom: 1px solid $endocrine-light-blue;
        padding-bottom: 45px;

        &-left {
            @media(max-width: $bp-max-medium) {
                margin-bottom: 24px;
            }
        }

        @media(max-width: $bp-max-medium) {
            flex-direction: column;
        }

    }
    
    .secondary-cta {
        font-size: rem(15);
    }

    label.error {
        color: red;
        font-size: 0.875rem;
        line-height: 1.71;
        display: inherit;
    }
}