$container-bottom-space: rem(80);
$container-top-space: rem(60);
$container-bleed-max: rem(2790);
$container-outer-gutter: rem(40);

@mixin horizontal-container-padding {
    padding-right: 80px;
    padding-left: 80px;

    @include media($bp-g-max-large) {
        padding-left: 70px;
        padding-right: 70px;
    }

    @media (max-width: $bp-max-medium) {
        padding-left: 40px;
        padding-right: 40px;
    }

    // @media (max-width: $bp-max-small) {
    //     padding-left: 20px;
    //     padding-right: 20px;
    // }
}

.container {
    position: relative;
    margin-bottom: $container-bottom-space;

    @media (max-width: $bp-max-small) {
        margin-bottom: rem(48);
    }

    &.is-adjacent {
        margin-bottom: 0;
    }
}

.container--small-margin-bottom {
    margin-bottom: rem(59);

    &.is-adjacent {
        margin-bottom: 0;
    }
}

.container--top-space {
    margin-top: $container-top-space;

    @media (max-width: $bp-max-small) {
        margin-top: rem(48);
    }

    &.is-adjacent-top {
        margin-top: 0;
    }
}

.container--bleed {
    max-width: $container-bleed-max;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    & > .container__col {
        position: relative;

        .container:last-child {
            margin-bottom: 0;
        }
    }

    &.has-image,
    &.has-color {
        padding-top: $container-bottom-space;
        padding-bottom: $container-bottom-space;
    }

    // outer padding is only on the outer
    // container now
    .container--100,
    .container--70-30,
    .container--50-50,
    .container--30-70 {
        @media (min-width: $bp-min-large) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.container--100,
.container--70-30,
.container--50-50,
.container--30-70 {
    @include outer-container();
    @media (max-width: $bp-max-large) {
        // margin-left: $container-outer-gutter;
        // margin-right: $container-outer-gutter;
    }
}

.container--50-50,
.container--70-30,
.container--30-70 {
    .container__col:first-child {
        margin-bottom: $container-bottom-space;
    }
    @include media($bp-g-min-large) {
        display: flex;

        .container__col {
            flex: none;
        }

        .container__col:first-child {
            margin-bottom: 0;
            margin-right: 25px;
        }

        .container__col:last-child {
            margin-left: 25px;
        }
    }
}

.container--70-30 {
    @include media($bp-g-min-large) {
        .container__col:first-child {
            width: calc(66.66% - 50px);
        }

        .container__col:last-child {
            width: 33.33%;
        }
    }
}

.container--50-50 {
    @include media($bp-g-min-large) {
        display: flex;

        .container__col {
            margin-bottom: 0;
            width: calc(50% - 25px);
        }
    }
}

.container--30-70 {
    @include media($bp-g-min-large) {
        .container__col:first-child {
            width: 33.33%;
        }

        .container__col:last-child {
            width: calc(66.66% - 50px);
        }
    }
}

.container-wide--50-50 {
    max-width: none;
    @include horizontal-container-padding();

    @media (max-width: $bp-max-large) {
        display: block;
    }

    .container__col {
        @media (max-width: $bp-max-large) {
            width: 100%;

            &:last-child {
                margin-left: 0;
            }
        }
    }
}

/* Colors */
.container--connect {
    background-color: $grey-200;
}

/* horizontal padding for all screen sizes */
.container--horizontal-padding {
    @include horizontal-container-padding;
}

/* horizontal padding just on the largest screen size */
.container--outer-padding {
    padding-right: 100px;
    padding-left: 100px;
}

// put a line inbetween the two columns
.container--50-50-line-gutter {
    @include media($bp-g-max-large) {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 140px);
    }

    @include media($bp-g-max-medium) {
        width: calc(100% - 80px);
    }
}

.container--70-30-line-gutter {
    @include media($bp-g-max-large) {
        width: calc(100% - 140px);
    }

    @include media($bp-g-max-medium) {
        width: calc(100% - 80px);
    }

    // @include media($bp-g-max-small) {
    // 	width: calc(100% - 80px);
    // }
}

.container--50-50--line-gutter,
.container--70-30-line-gutter {
    .container__col {
        position: relative;

        &:before {
            content: "";
            height: 100%;
            width: 1px;
            background: $hormone-black;
            position: absolute;
            top: 0;

            @media (max-width: $bp-max-medium) {
                display: none;
            }
        }

        &:first-child {
            padding-right: 50px;

            @media (max-width: $bp-max-medium) {
                padding-right: 0;
                border-bottom: 1px solid $hormone-black;
                margin-bottom: 32px;
                padding-bottom: 20px;
            }

            &:before {
                right: 0;
            }
        }

        &:last-child {
            padding-left: 50px;

            @media (max-width: $bp-max-medium) {
                padding-left: 0;
            }

            &:before {
                left: -1px;
            }
        }
    }
}

// this is the grid for the interior pages - with the secondary nav in the right rail.
.interior-container {
    &__inside {
        display: flex;
        max-width: 1340px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @include media($bp-g-max-large) {
            display: block;
        }
    }

    &__col {
        &:first-child {
            padding-right: 48px;
            padding-left: 70px;
            margin-bottom: 60px;
            flex-grow: 1;

            @include media($bp-g-max-large) {
                padding-left: 70px;
                margin-bottom: 80px;
                padding-right: 70px;
            }

            @media (max-width: $bp-max-medium) {
                padding-left: 40px;
                padding-right: 40px;
            }

            .secondary-nav {
                display: none;

                @include media($bp-g-max-large) {
                    display: block;
                }
            }
        }

        &:last-child {
            flex-basis: 357px;
            flex-shrink: 0;
            flex-grow: 0;

            @include media($bp-g-max-large) {
                display: block;
            }

            .secondary-nav {
                @include media($bp-g-max-large) {
                    display: none;
                }
            }
        }
    }
}

// this is the grid for the search page - with search facits in a left rail
.search-container {
    &__inside {
        max-width: 1340px;
        padding-left: 70px;
        padding-right: 70px;
        margin-left: auto;
        margin-right: auto;
        display: flex;

        @include media($bp-g-max-large) {
            display: block;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__col {
        &:first-child {
            flex-basis: 357px;
            flex-shrink: 0;
            flex-grow: 0;

            @include media($bp-g-max-large) {
                display: block;
                margin-bottom: 30px;
            }
        }

        &:last-child {
            padding-left: 70px;
            margin-bottom: 60px;
            flex-grow: 1;

            @include media($bp-g-max-large) {
                padding-left: 70px;
                margin-bottom: 80px;
                padding-right: 70px;
            }

            @media (max-width: $bp-max-medium) {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
}
