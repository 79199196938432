.end-contacts {
    padding: 70px 50px 90px;

    @include media($bp-g-max-medium) {
		padding-top: 0;
	}

    &__title {
        @include font(acumin, condensed-bold);
        letter-spacing: 1.5px;
		font-size: 17px;
        color: $endocrine-light-blue;
        text-transform: uppercase;
    }

    &__subtitle {
        margin-bottom: 22px;
    }

    &__contact {
        display: flex;
        flex-direction: column;

        font-size: 15px;

        span:first-child {
            font-weight: bold;
        }

        a {
            text-decoration: underline;

            &:hover {
                color: $endocrine-dark-blue;
            }
        }
    }

}