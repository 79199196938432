.footer-nav {
	display: flex;
	flex-shrink: 0;
	margin-bottom: 10px;

	@media(max-width: $bp-max-medium) {
		flex-wrap: wrap;
	}

	@media(max-width: $bp-max-small) {
		display: block;
	}

	li {
		&:before {
      display: none;
    }
	}
}

.footer-nav__section {
	padding-right: rem(32);
	padding-bottom: rem(32);
	min-width: 10px;

	@media(max-width: $bp-max-medium) {
		flex-basis: percentage(1/3);
	}

	@media(max-width: em(700)) {
		flex-basis: percentage(1/2);
	}

	@media(max-width: $bp-max-small) {
		padding-bottom: rem(50);
	}
}

.footer-nav__col-wrapper {
	display: flex;

	@media(max-width: $bp-max-small) {
		display: block;
	}
}

.footer-nav__col {
	@include zero-list;
	margin-top: 22px;

	@media(max-width: $bp-max-small) {
		display: block;
		width: 100%;
	}

	li {
		padding-left: 0;
		margin-bottom: 14px;
	}

	a {
		font-weight: 400;
    font-size: rem(14);
    line-height: 1.2;
		text-decoration: none;
		display: block;
	}
}

.footer-nav__heading {
	width: 100%;
	font-weight: 800;
  font-size: rem(16);
  line-height: 1.19;

	a {
		text-decoration: none;
	}
}
