.table-list {
    width: 100%;

    @media (max-width: $bp-max-medium) {
        display: block;

        tbody,
        tr,
        td {
            border: 0;
            display: block;
            width: 100%;
        }

        thead {
            display: none;
        }

        td {
            font-size: 15px;
            padding: 0;
            padding-bottom: 8px;
        }

        td:before {
            content: attr(data-label);
            font-weight: 700;
            margin-right: 0.25em;
        }
    }
}
