.end-mobile-get-started {
    &__button {
        border-bottom: 1px solid rgba($endocrine-light-blue, 0.5);
        padding-bottom: 40px;
        margin-bottom: 35px;

        a {
            text-decoration: none;

            .btn__text {
                font-weight: bold;
                font-size: rem(16);
                color: $white;
            }
    
            .btn__box {
                border-color: $endocrine-light-blue;
            }
        }
        
    }    
}