// end-image-block.scss

.container--50-50 .container__inner > .end-image-block {
    @include media($bp-g-max-medium) {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 80px);
    }
}

// base
.end-image-block {
    // image media
    margin-bottom: 20px;
    max-width: 100%;
    width: 100%;

    @media (max-width: $bp-max-small) {
        margin-bottom: 0;
    }

    &__media {
        // image
        img {
            display: block;
            width: 100%;
        }

        // figcaption
        figcaption {
            font-weight: 400;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 1.6;
        }
    }

    &__image {
        position: relative;
        margin-bottom: 30px;

        @media (max-width: em(500)) {
            margin-bottom: 20px;
        }
    }

    &__zoom {
        padding: 17px 17px 0 0;
        background: white;
        position: absolute;
        bottom: 0;
        left: 0;

        @media (max-width: em(700)) {
            padding: 10px 10px 0 0;
            display: none;
        }

        // .btn {
        //   padding-top: 22px;
        //   padding-bottom: 22px;
        //   padding-right: 22px;
        //   padding-left: 82px;

        //   @media (max-width: em(700)) {
        //     width: 60px;
        //     box-sizing: border-box;
        //     overflow: hidden;
        //     font-size: 1px;
        //     padding-right: 0;
        //     padding-left: 0;
        //     height: 60px;
        //   }

        //   &:before {
        //     width: 60px;
        //   }
        // }
    }

    &__view-btn {
        .btn--primary {
            width: 180px;
            padding: 10px 10px 10px 15px;
            background: linear-gradient(
                105deg,
                #246aaa 0%,
                #246aaa 75%,
                #418fde 75%
            );

            &__icon-box {
                color: $white;
            }

            &:hover {
                background: linear-gradient(
                    105deg,
                    #2e84d3 0%,
                    #2e84d3 75%,
                    #418fde 75%
                );
            }
        }
    }

    &__downloads {
        border-bottom: 1px solid $hormone-black;
        margin-bottom: 30px;

        @media (max-width: em(700)) {
            border-bottom: none;
            margin-bottom: 10px;
        }

        ul,
        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        a {
            font-weight: 800;
            font-size: 15px;
            color: $hormone-black;
            text-decoration: none;

            &:hover {
                color: $hormone-pink-dark;
                text-decoration: underline;
            }
        }

        li {
            border-right: 1px solid $hormone-black;
            padding: 10px 24px 18px 0;
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;

            @media (max-width: em(500)) {
                display: block;
                border: none;
                padding: 0 0 10px 0;
                margin: 0;
            }

            &:before {
                display: none;
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
                margin-right: 0;
            }
        }
    }

    &__caption-label {
        @include font(acumin, condensed-bold);
        font-size: 15px;
        color: $endocrine-light-blue;
        letter-spacing: 1.5px;
        position: relative;
        text-transform: uppercase;
        margin-right: 4px;
    }

    &__caption {
        color: #000000;
        opacity: 0.5;
        font-weight: 300;

        body & .scWebEditInput {
            display: inline;
        }
    }
}

.jpg-download,
.pdf-download {
    position: relative;
    padding-right: 18px;

    span {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-right: 12px;
        top: -2px;
    }

    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translatey(-50%);
        transition: transform 0.35s ease;
        width: 3px;
        height: 8px;
        background: $hormone-dark-pink;
    }

    &:before {
        transform: rotate(45deg);
        margin-top: -2px;
    }

    &:after {
        transform: rotate(-45deg);
        margin-top: -6px;
    }
}

.pdf-icon {
    width: 27px;
    height: 26px;
    background: transparent
        url("/assets/img/common/svg-sprite/download-pdf.svg") no-repeat center
        center;
    background-size: cover;
}

.jpg-icon {
    width: 26px;
    height: 26px;
    background: transparent
        url("/assets/img/common/svg-sprite/download-jpg.svg") no-repeat center
        center;
    background-size: cover;
}

.end-image-block__zoomed {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    opacity: 0;
    padding: 60px;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s ease;

    @include media($bp-g-max-large) {
        padding: 35px;
    }

    @media (max-width: $bp-max-medium) {
        padding: 15px;
    }

    @media (max-width: em(700)) {
        display: none;
    }

    &.is-open {
        opacity: 1;
        visibility: visible;
    }

    &-overlay {
        background: $hormone-green;
        opacity: 0.83;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    &-media {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        picture {
            display: inline;
        }

        img {
            width: auto;
            max-width: calc(100vw - 120px);
            max-height: calc(100vh - 120px);
            display: block;
            margin: 0 auto;
            box-shadow: 10px 16px 40px rgba(0, 0, 0, 0.25);

            @include media($bp-g-max-large) {
                max-width: calc(100vw - 70px);
                max-height: calc(100vh - 70px);
            }

            @media (max-width: $bp-max-medium) {
                max-width: calc(100vw - 30px);
                max-height: calc(100vh - 30px);
            }
        }
    }

    &-close {
        -webkit-appearence: none;
        position: absolute;
        top: -20px;
        right: -20px;
        display: block;
        z-index: 3;
        transition: opacity 0.2s ease, background 0.2s ease, transform 0.2s ease,
            visibility 0.2s ease;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $endocrine-light-blue;
        border: none;

        @media (max-width: $bp-max-medium) {
            top: -10px;
            right: -10px;
        }

        &:hover {
            background: $hormone-pink-dark;
        }

        &:focus {
            outline: none;
        }

        &-icon {
            width: 16px;
            height: 17px;
            position: absolute;
            top: 11px;
            left: 12px;
            z-index: 1;
            color: #fff;
        }
    }
}
