// from global-footer.scss
// Define global footer styles here

// base
.end-global-footer {
	.container--bleed {
		.container__col {
			@include outer-container();
		}
	}
}

.end-blue-container {
	@include horizontal-container-padding;
	background: #031D45;
	color: $white;

	@include media($bp-g-max-large) {
		padding-left: 0;
		padding-right: 0;
	}

	&__inside {
		display: flex;
		width: 100%;
		max-width: 1240px;
		margin-left: auto;
		margin-right: auto;

		@include media($bp-g-max-large) {
			display: flex;
			flex-direction: column;
		}
	}

	a {
		color: $white;

		&:hover,
		&:focus {
			text-decoration: underline;
			color: $white;
		}
	}

	&__col {
		vertical-align: top;
		padding-top: 60px;
		padding-bottom: 60px;
		border-top: 1px solid rgba(65,143,222, 0.5);

		@include media($bp-g-max-large) {
			@include horizontal-container-padding;
			display: block;
		}

		@include media($bp-g-max-medium) {
			// override horizontal-container-padding
			// padding-left: 70px;
			padding-left: 40px;
		}

		@include media($bp-g-max-small) {
			// override horizontal-container-padding
			// padding-left: 40px;
		}

		&--1 {
			padding-right: 16px;
			width: calc(100% - 260px);
			position: relative;

			@include media($bp-g-max-large) {
				padding-right: 0;
				order: 2;
				width: 100%;
			}

			&:after {
				content: '';
				height: 70%;
				width: 1px;
				background: rgba(65,143,222, 0.5);
				display: block;
				position: absolute;
				top: 0;
				right: 0;

				@media(max-width: $bp-max-large) {
					width: 0;
				}
			}

			.footer-copyright-and-links {
				display: flex;
				// flex-direction: column;

				@include media($bp-g-max-medium) {
					flex-direction: column;
				}
			}
		}

		&--2 {
			background: $endocrine-dark-navy;
			position: relative;
			left: 0;
			z-index: 0;
			padding-left: 40px;
			padding-right: 0;
			width: 260px;
			border-top: 1px solid rgba(65,143,222, 0.5);

			@include media($bp-g-max-large) {
				padding-left: 70px;
				padding-right: 70px;
				overflow: hidden;
				order: 1;
				width: 100%;
				border-top: none;
			}

			@include media($bp-g-max-medium) {
				padding-left: 40px;
				padding-right: 40px;
			}

			@include media($bp-g-max-small) {
				// padding-left: 40px;
				// padding-right: 40px;
			}

			&:after {
				content: "";
				background: #031D45;
				height: 100%;
				top: 0;
				left: 0;
				width: 100vw;
				position: absolute;
				z-index: -2;
			}
		}
	}
}

.end-footer-partner-wrap {
	margin-top: 12px;

	&:before {
		display: none;
	}
}

// .end-footer-partners-ctas {
// 	padding-top: 2px;
// 	padding-bottom: 16px;

// 	&__title {
// 		font-weight: 800;
// 		font-size: rem(24);
// 		line-height: 1.25;
// 	}

// 	&__description {
// 		font-size: rem(15);
// 		line-height: 1.6;
// 		margin: 6px 0 28px;
// 	}

// 	.btn {
// 		margin-bottom: 12px;
// 		margin-right: 24px;

// 		&:last-child {
// 			margin-right: 0;
// 		}
// 	}
// }

.end-branding {
	display: flex;
	width: 100%;
	padding-top: 6px;
	padding-bottom: 16px;

	@media(max-width: em(1100)) {
		display: block;
	}

	@media(max-width: $bp-max-medium) {
		display: flex;
	}

	@media(max-width: em(600)) {
		display: block;
	}

	&__media {
		flex-basis: 220px;
		flex-shrink: 0;
		max-width: 220px;

		img {
			width: 100%;
			display: block;
		}
	}

	&__tag-line {
		border-top: 1px solid $endocrine-light-blue;
		color: $endocrine-light-blue;
		display: block;
		text-align: right;
	}

	&__description {
		padding-left: 32px;

		@media(max-width: em(1100)) {
			padding-left: 0;
			margin-top: 10px;
		}

		@media(max-width: $bp-max-medium) {
			padding-left: 32px;
			margin-top: 0;
		}

		@media(max-width: em(600)) {
			padding-left: 0;
			margin-top: 10px;
		}

		h4 {
			margin-bottom: 4px;
		}

		p {
			line-height: 1.6;
			margin: 0;
			font-size: rem(16);
		}
	}
}

.end-footer-menu {
	padding: 50px 0;

	@media(max-width: $bp-max-large) {
		display: none;
	}
}
