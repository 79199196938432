// mixins.scss
// Define custom mixins here

// color variables
$base-outline-color: $grey-500 !default;
$base-fill-color: $grey-300 !default;
$base-component-whitespace: rem(30);

// General whitespace rules for individual components
@mixin component-whitespace {
    margin-bottom: $base-component-whitespace;
}

/// Adds an outline for debugging purposes
/// @author Andrew Rodgers
/// @param {color} $outline-color [$base-outline-color] - Element’s `outline-color`
/// @example scss - usage
///     .foo {
///         @include debug-outline();
///     }
///
///     .bar {
///         @include debug-outline($grey-900);
///     }
///
///     .foo-bar {
///         @include debug-outline(#f5f5f5);
///     }
///
/// @example css - CSS output
///     .foo {
///         outline: 1px solid #9e9e9e; /* default value */
///     }
///
///     .bar {
///         outline: 1px solid #212121;
///     }
///
///     .foo-bar {
///         outline: 1px solid #f5f5f5;
///     }
@mixin debug-outline($outline-color: $base-outline-color) {
    outline: 1px solid $outline-color;
}

/// fills background-color for debugging purposes
/// @author Andrew Rodgers
/// @param {color} $fill-color [$base-fill-color] - Element’s `background-color`
/// @example scss - usage
///     .foo {
///         @include debug-bg();
///     }
///
///     .bar {
///         @include debug-bg($grey-900);
///     }
///
///     .foo-bar {
///         @include debug-bg(#f5f5f5);
///     }
///
/// @example css - CSS output
///     .foo {
///         background-color: 1px solid #e0e0e0; /* default value */
///     }
///
///     .bar {
///         background-color: 1px solid #212121;
///     }
///
///     .foo-bar {
///         background-color: 1px solid #f5f5f5;
///     }
@mixin debug-bg($fill-color: $base-fill-color) {
    background-color: $fill-color;
}

//// adds outline, background-color, and min-height for testing layouts
/// @author Andrew Rodgers
/// @param {color | color | length} $outline-color [$base-outline-color], $fill-color [$base-fill-color], $layout-height [8] - Element’s `outline-color`, `background-color` and `min-height` in pixels
/// @example scss - usage
///     .foo {
///         @include debug-layout();
///     }
///
///     .bar {
///         @include debug-layout($grey-300, $grey-900, 10);
///     }
///
/// @example css - CSS output
///     .foo {
///         outline-color: #9e9e9e; /* default value */
///         background-color: 1px solid #e0e0e0; /* default value */
///         min-height: 8px; /* default value */
///     }
///
///     .bar {
///         outline-color: #e0e0e0;
///         background-color: 1px solid #212121;
///         min-height: 10px;
///     }
@mixin debug-layout($outline-color: $base-outline-color, $fill-color: $base-fill-color, $layout-height: 8) {
    @include debug-outline($outline-color);
    @include debug-bg($fill-color);
    min-height: $layout-height+px;
}

/// omega reset
/// @author Josh Fry
/// @link https://github.com/joshfry/omega-reset-for-bourbon-neat
/// @param {expression} $nth
/// 1. This should match the value of the previous `omega()` argument
/// @example scss - usage
///     .foo {
///         @include span-columns(6);
///         @include omega(2n); // 1
///         @media(max-width 600px){
///             @include omega-reset(2n); // 1
///             @include span-columns(4);
///             @include omega(3n);
///         }
///     }
@mixin omega-reset($nth) {
    &:nth-child(#{$nth}) {
        margin-right: flex-gutter($grid-columns, $gutter);
    }

    &:nth-child(#{$nth}+1) {
        clear: none;
    }
}

// zero out padding and margins
@mixin zero() {
    margin: 0;
    padding: 0;
}

// zero out list-style-type NOTE: Becareful with nested list styles
@mixin zero-list() {
	margin: 0;
    padding-left: 0;
    list-style-type: none;
}
