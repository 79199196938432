.event-card {
	padding: 1rem;
	background-color: $grey-100;
}

.event-card__date {
	@include font(bitter, bold);
}

.event-card__date,
.event-card__location,
.event-card__information {
	margin-bottom: rem(20);
}

.event-card__heading {
	@include font(montserrat, bold);
	font-size: .875rem;
	text-transform: uppercase;
  margin-bottom: rem(8);

}

.event-card__date {
	display: inline-block;
	padding: 0;

  span {
    display: block;
  }

  span:nth-child(2) {
    font-size: rem(20);
  }
}

.event-card__time {
	display: block;
	time {
		font-weight: 400;
	}
}

.event-card__location {
	font-style: normal;
  font-size: rem(14);
	span {
		display: block;
		&>span,
		&>div {
			// For EE-generated contenteditable spans
			display: inline;
            vertical-align: middle;
		}
	}
}

.event-card__city-state-zip {

}

.event-card__information {
	font-size: 1rem;
	line-height: 1.6;
}

