.newsletter-signup {
  background: #571C52;
  color: #fff;
  @include horizontal-container-padding;
  padding-top: 62px;
  padding-bottom: 70px;

  @media(max-width: $bp-max-small) {
    padding-top: 48px;
    padding-bottom: 56px;
  }

  &__inside {
    @include outer-container();
    position: relative;
  }

  &__media {
    position: absolute;
    top: -14px;
    left: -78px;

    @include media($bp-g-max-large) {
      width: 151px;
      top: -12px;
      left: auto;
      right: 86%;
    }

    @media(max-width: $bp-max-medium) {
      display: none;
    }

    img {
      max-width: 100%;
      display: block;
    }
  }

  &__text {
    margin-left: percentage(2/12);
    max-width: 870px;

    @media(max-width: $bp-max-medium) {
      margin-left: 0;
    }
  }

  &__title {
    margin-bottom: 7px;
  }

  &__description {
    font-weight: 500;
    font-size: rem(16);
    line-height: 1.5;
    margin-bottom: 2.1rem;
  }

  &__form-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 12px;

    @include media(new-breakpoint(max-width (em(650)))) {
      display: block;
      margin-bottom: 12px;
    }
  }

  &__form-item {
    flex-grow: 1;
    padding: 0 20px;

    @include media($bp-g-max-large) {
      padding: 0 10px;
    }

    @include media(new-breakpoint(max-width (em(650)))) {
      padding: 0;
      margin-bottom: 12px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    input {
      -webkit-appearance: none;
      border-radius: 0;
      font-weight: 500;
      font-size: rem(16);
      color: $hormone-black;
      border: 2px solid #571C52;
      padding: 12px;
      width: 100%;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $hormone-dark-blue;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $hormone-dark-blue;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $hormone-dark-blue;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $hormone-dark-blue;
      }
      &:placeholder {
        color: $hormone-dark-blue;
      }

      &:focus {
        outline: none;
        border-color: $hormone-pink;
      }
    }
  }

  &__checkbox {
    font-size: rem(14);
    line-height: 1.71;
    margin-top: 12px;

    &-group-label,
    &-item {
      display: inline-block;
      vertical-align: top;

      @include media(new-breakpoint(max-width (em(650)))) {
        display: block;
      }
    }

    &-group-label {
      margin-right: 6px;
      position: relative;
      top: 6px;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    &-item {

      input {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      input:checked ~ label::after {
        display: block;
      }

      input:focus ~ label::before {
        border-color: $hormone-pink;
      }

      label:not(.error) {
        position: relative;

        &:before {
          content: '';
          width: 22px;
          height: 22px;
          position: relative;
          display: inline-block;
          background: #fff;
          margin-right: 8px;
          top: 6px;
          border: 2px solid #571C52;
        }

        &:after {
          display: none;
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.684.02c.307-.056.586.009.835.195.25.186.403.432.461.74.058.306-.01.59-.202.85l-5.96 8.428c-.193.242-.438.39-.735.447a1.01 1.01 0 0 1-.82-.196c-.154-.111-.558-.507-1.21-1.186A99.866 99.866 0 0 1 1.21 7.331l-.893-.949A1.135 1.135 0 0 1 0 5.572c0-.316.115-.585.346-.809.23-.223.499-.33.806-.32.307.009.566.134.777.376l2.852 2.986L9.964.465a1.19 1.19 0 0 1 .72-.446z' fill='%23007DBA' fill-rule='evenodd'/%3E%3C/svg%3E");
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center center;
          width: 12px;
          height: 11px;
          background-size: cover;
          position: absolute;
          left: 5px;
          top: 4px;
        }

        a {
          color: #fff;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__bottom-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @include media(new-breakpoint(max-width (em(650)))) {
      display: block;
    }

    &-left {
      padding-right: 40px;

      @include media(new-breakpoint(max-width (em(650)))) {
        padding-right: 0;
        margin-bottom: 24px;
      }
    }
  }

  label.error {
    color: red;
    font-size: 0.875rem;
    line-height: 1.71;
    display: inherit;
  }
}
