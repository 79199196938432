$copyright-symbol-size: rem(13) !default;
$copyright-text-size: rem(13) !default;

.end-copyright {
    @include zero;
    display: inline-block;
    font-size: $copyright-text-size;
    line-height: $copyright-symbol-size;
    text-align: left;
    font-weight: 400;

    @media(max-width: $bp-max-small) {
        line-height: 1.2;
    }
}

.end-copyright__symbol {
    display: inline-block;
    font-size: $copyright-symbol-size;
    vertical-align: top;
}
