// REMOVE - FOR TESTING ONLY
// .event-calendar {
//   margin-top: 60px;
// }

@mixin min640 {
  @media screen and (min-width: 40rem) {
    @content;
  }
}

.visually-hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

:root {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #002e59;
  --fc-button-border-color: #002e59;
  --fc-button-hover-bg-color: #002e59;
  --fc-button-hover-border-color: #002e59;
  --fc-button-active-bg-color: #002e59;
  --fc-button-active-border-color: #002e59;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: transparent;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: #eaedef;
  --fc-now-indicator-color: red;
}

.fc-listMonth-button.fc-button.fc-button-primary {
  color: $hormone-navy-dark;
  background-color: #eaedef;
  border: #eaedef;

  &.fc-button-active {
    color: #fff;
    background-color: $hormone-dark-navy;
    border: $hormone-dark-navy;
  }
}

.fc-dayGridMonth-button.fc-button.fc-button-primary {
  color: $hormone-dark-navy;
  background-color: #eaedef;
  border: #eaedef;

  &.fc-button-active {
    color: #fff;
    background-color: $hormone-dark-navy;
    border: $hormone-dark-navy;
  }
}

.fc .fc-toolbar {
  align-items: stretch;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @include min640() {
    flex-direction: row;
    align-items: initial;
  }
}

.fc .fc-button-primary {
  font-weight: 700;
}

.fc-toolbar-chunk {
  flex: 1 1 0;
  display: flex;
  width: 100%;

  @include min640() {
    width: initial;
  }

  &:nth-of-type(1) {
    order: 3;
    z-index: 100;

    @include min640() {
      order: 1;
    }
  }

  &:nth-of-type(2) {
    flex: 2 2 0;
    justify-content: center;
    order: 2;
  }

  &:nth-of-type(3) {
    justify-content: center;
    order: 2;

    @include min640() {
      justify-content: flex-end;
      order: 3;
    }
  }
}

.fc-next-button.fc-button.fc-button-primary {
  margin: 0;
}

.fc-toolbar-title {
  font-family: $acumin;
  color: $hormone-dark-navy;
  text-align: center;
  min-width: 12.625rem; // so arrows stay in one place, longest month name
}

#fc-dom-1 {
  margin: 0 2rem;
}

.fc-direction-ltr .fc-toolbar {
  .custom-select-container {
    margin-left: 0;
  }
}

.fc .fc-list-empty {
  align-items: flex-start;
  height: auto;
}

.fc .fc-list-empty-cushion {
  color: $hormone-dark-navy;
  font-size: 1.125rem;
  margin: .5rem 0;
}

.event-calendar--list .fc-view-harness-active {
  height: auto !important;

  & > .fc-list {
    position: static;
  }
}

.custom-select-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-family: $acumin;
  font-weight: 700;
  color: $hormone-dark-navy;
  margin: 0;

  @media screen and (min-width: 40rem) {
    max-width: 200px;
  }

  &.is-open {
    .custom-select-opener {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &:after {
        transform: translateY(calc(50% - .75rem)) rotate(-135deg);
        transition: .2s;
      }
    }
    .custom-select-panel {
      border-bottom: 2px solid $hormone-navy-dark;
      max-height: 12em;
      overflow-y: auto;
    }
  }
}

.custom-select-container * {
  box-sizing: border-box;
  user-select: none;
}

.custom-select-container.is-disabled {
  opacity: 0.333;
}

.custom-select-opener {
  position: relative;
  color: #fff;
  background-color: $hormone-dark-navy;
  border-radius: 0.25rem;
  padding: 0.5em;
  display: block;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    right: 1rem;
    top: 50%;
    width: 0.75rem;
    height: 0.75rem;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform-origin: center center;
    transform: translateY(calc(50% - .75rem)) rotate(45deg);
    transition: .2s;
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: rgba(76, 91, 106, 0.5) 0px 0px 0px 0.2rem;
  }
}

.custom-select-container select {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-select-panel {
  max-height: 0;
  transition: max-height 0.5s ease-out, overflow-y 0.1s 0.5s;
  overflow: hidden;
  background-color: #fff;
  position: absolute;
  top: 100%;
  width: 100%;
  border-left: 2px solid $hormone-dark-navy;
  border-right: 2px solid $hormone-dark-navy;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.custom-select-option {
  cursor: pointer;
  position: relative;
  padding: 0.5em;
  user-select: none;
  color: #fff;
  border-top: 2px solid #fff;
  background-color: $hormone-dark-navy;


}

.custom-select-option.has-focus {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .05);
    z-index: 1;
  }
}

.custom-select-option.is-selected:after {
  content: "";
  position: absolute;
  display: block;
  right: 1.2rem;
  top: 50%;
  width: 8px;
  height: 15px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  padding-left: 0.5em;
  transform-origin: right bottom;
  transform: translateY(-50%) rotate(36deg);
}
