.hamburger {
  align-items: center;
  background-color: $endocrine-navy;
  border: none;
  color: $white;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 900;
  height: 60px;
  letter-spacing: 1.25px;
  line-height: 15px;
  justify-content: center;
  margin-left: 20px;
  padding: 0 15px;
  text-transform: uppercase;
}


.hamburger__toggle-icon {
  color: $endocrine-light-blue;
  cursor: pointer;
  height: 26px;
  position: relative;
  width: 30px;

  span {
    margin: 0 auto;
    position: relative;
    top: calc(50% - 1.5px);

    &::before,
    &::after {
      position: absolute;
      content: "";
    }

    &,
    &::before,
    &::after {
      display: block;
      width: 30px;
      height: 3px;
      background-color: currentColor;
    }

    &::before {
      margin-top: -6px;
    }

    &::after {
      margin-top: 6px;
    }
  }
}
