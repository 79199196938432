// global-footer.scss
// Define global footer styles here

// base
.global-footer {
	.container--bleed {
		.container__col {
			@include outer-container();
		}
	}
}

.blue-container {
	@include horizontal-container-padding;
	background: $hormone-dark-navy;
	color: $white;

	@include media($bp-g-max-large) {
		padding-left: 0;
		padding-right: 0;
	}

	&__inside {
		display: flex;
		width: 100%;
		@include outer-container();

		@include media($bp-g-max-large) {
			display: flex;
			flex-direction: column;
		}
	}

	a {
		color: $white;

		&:hover,
		&:focus {
			text-decoration: underline;
			color: $white;
		}
	}

	&__col {
		vertical-align: top;
		padding-top: 60px;
		padding-bottom: 60px;

		@include media($bp-g-max-large) {
			@include horizontal-container-padding;
			display: block;
		}

		&--1 {
			padding-right: 16px;
			width: calc(100% - 260px);

			@include media($bp-g-max-large) {
				padding-right: 0;
				order: 2;
				width: 100%;
			}
		}

		&--2 {
			background: $hormone-navy;
			position: relative;
			left: 0;
			z-index: 0;
			padding-left: 40px;
			padding-right: 0;
			width: 260px;

			@include media($bp-g-max-large) {
				padding-left: 40px;
				padding-right: 40px;
				overflow: hidden;
				order: 1;
				width: 100%;
			}

			&:after {
				content: "";
				background: $hormone-navy;
				height: 100%;
				top: 0;
				left: 0;
				width: 100vw;
				position: absolute;
				z-index: -2;
			}
		}
	}

	.btn.btn--secondary:hover {
		color: white;
	}
}

.footer-partners {
	padding-top: 2px;
	padding-bottom: 16px;

	&__title {
		font-weight: 800;
		font-size: rem(24);
		line-height: 1.25;
	}

	&__description {
		font-size: rem(15);
		line-height: 1.6;
		margin: 6px 0 28px;
	}

	.btn {
		margin-bottom: 12px;
		margin-right: 24px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.branding {
	display: flex;
	width: 100%;
	padding-top: 6px;
	padding-bottom: 16px;

	@media(max-width: em(1100)) {
		display: block;
	}

	@media(max-width: $bp-max-medium) {
		display: flex;
	}

	@media(max-width: em(600)) {
		display: block;
	}

	&__media {
		flex-basis: 220px;
		flex-shrink: 0;
		max-width: 220px;

		img {
			width: 100%;
			display: block;
		}
	}

	&__description {
		padding-left: 32px;

		@media(max-width: em(1100)) {
			padding-left: 0;
			margin-top: 10px;
		}

		@media(max-width: $bp-max-medium) {
			padding-left: 32px;
			margin-top: 0;
		}

		@media(max-width: em(600)) {
			padding-left: 0;
			margin-top: 10px;
		}


		p {
			font-size: rem(15);
			line-height: 1.6;
			margin: 0;
		}
	}
}
