.end-lp-news-list {
    padding-top: 40px;
    padding-bottom: 120px;

    @include media($bp-g-max-large) {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 140px);
        padding-top: 0;
    }

    @include media($bp-g-max-medium) {
        width: calc(100% - 80px);
    }

    @include media($bp-g-max-small) {
        padding-bottom: 0;
    }
    
    .header {
        &__heading {
            border-top: 1px solid $endocrine-light-blue;
            color: #9D968D;
            margin-bottom: 60px;

            h6 {
                @include font(acumin, condensed-bold);
                font-size: rem(18);
                margin-top: 16px;
                letter-spacing: 1.7px;
            }
        }
    }

    &__title {
        font-size: rem(30);
        letter-spacing: -0.5px;
    }

    &__preview {
        margin-bottom: 50px;
    }

    &__description {
        font-size: rem(17);
    }

    &__view-btn {
        .btn--primary {
            width: 160px;
            padding: 10px 12px 10px 25px;
            background: linear-gradient(105deg,
                            #246AAA 0%,
                            #246AAA 70%,
                            #418FDE 70%);

            &__icon-box {
                color: $white;
            }

            &:hover {
                background: linear-gradient(105deg,
                                #2e84d3 0%,
                                #2e84d3 70%,
                                #418FDE 70%);
            }
        }


    }
}