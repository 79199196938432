.member-login {
    display: flex;
    flex: none;
    justify-content: flex-end;
    margin-left: auto;
    min-height: 40px;

    &__button {
        background: transparent;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;

        &:after {
            content: "";
            border: 3px solid $endocrine-light-blue;
            border-left-width: 0;
            border-top-width: 0;
            display: block;
            width: 14px;
            height: 14px;
            transform: rotate(45deg);
            transition: transform 0.25s;
            position: absolute;
            top: 10px;
            right: -210px;

            @include media($bp-end-header-min-small) {
                display: none;
            }
        }
    }

    &__greeting {
        align-self: flex-end;
    }

    &__wrap {
        display: none;
        border-right: 1px solid #418fde;
        padding-right: 15px;
        position: relative;

        @include media($bp-end-header-min-small) {
            display: flex;
        }
    }

    &__menu {
        display: none;
        position: absolute;
        right: -60px;
        top: calc(100% + 16px);
        width: 550px;

        @include media($bp-end-header-min-small) {
            right: -165px;
        }

        @include media($bp-end-header-min-medium) {
            right: -60px;
            width: 700px;
        }

        &:before {
            left: calc(100% - 120px);

            @include media($bp-end-header-min-small) {
                left: calc(100% - 260px);
            }

            @include media($bp-end-header-min-medium) {
                left: calc(100% - 180px);
            }
        }

        &-top {
            display: flex;
            border-bottom: 1px solid $endocrine-light-blue;
            padding-bottom: 30px;
            margin-bottom: 14px;

            .list {
                flex: auto;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }

        &-bottom {
            display: flex;

            .account-link,
            .join-link {
                flex: auto;
            }

            .logout-link,
            .login-link {
                flex: 2;
            }

            a {
                display: flex;
                align-items: center;
            }

            span {
                margin-left: 8px;

                @include media($bp-end-header-min-small) {
                    font-size: 12px;
                }

                @include media($bp-end-header-min-medium) {
                    font-size: 14px;
                }
            }

            svg {
                width: 22px;
                height: 22px;
            }
        }
    }

    &__options {
        font-weight: 600;
        font-size: 17px;
        color: $endocrine-dark-navy;
    }

    &__icon {
        display: flex;
        align-items: center;

        a {
            display: flex;
            align-items: center;
        }

        svg {
            width: 20px;
            height: 20px;
            margin-left: 20px;
        }

        svg + span {
            margin-left: 8px;
        }

        svg + span + span {
            margin-left: 2px;
        }
    }

    .end-mobile-nav__primary-navigation-inner & {
        justify-content: flex-start;
        // margin-bottom: 40px;
        margin-left: 0;

        &__wrap {
            display: block;
            border: 0;
        }

        @include media($bp-end-header-min-small) {
            display: none;
        }

        &__greeting {
            align-self: flex-start;
        }

        &__button {
            color: $white;
            padding: 0;
        }

        &__options {
            color: $white;
        }

        &__icon {
            display: none;
        }
    }
}
