.end-manual-ad {
    display: flex;

    & > * {
        flex: none;
    }

    @include media($bp-g-max-medium) {
        flex-direction: column;
    }

    &__cta-btn {
        margin-right: 30px;

        .btn.btn--secondary {
            width: 250px;
        }

        @include media($bp-g-max-medium) {
            margin-bottom: 15px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__title {
        color: $endocrine-light-blue;
    }

    &__subtitle {
        text-transform: uppercase;
        font-size: rem(14);
    }

    &__link {
        width: 100%;
    }

    &__image {
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}
