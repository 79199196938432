.CoveoResult:not(.increase-specificity) {
    &.coveo-list-layout {
        padding: 40px 0;
    }

    .coveo-title {
        .CoveoResultLink {
            color: $endocrine-dark-navy;
            font-size: 32px;
            line-height: 1.22;
            transition: color 0.2s ease;
            text-decoration: none;

            &:visited,
            &:hover {
                color: $endocrine-dark-navy;
                text-decoration: underline;
            }
        }

        .coveo-date {
            font-size: 1rem;
        }
    }

    .coveo-summary {
        font-size: 18px;
        line-height: 1.58;
    }
}
