.end-related-links {
    @media (max-width: $bp-max-large) {
        width: calc(100% - 120px);
        margin: 0 auto;
    }

    @media (max-width: $bp-max-medium) {
        width: calc(100% - 80px);
        margin: 0 auto;
    }

    &__header {
        display: flex;
        border-bottom: 1px solid $endocrine-light-blue;
        margin-bottom: 40px;

        @media (max-width: $bp-max-small) {
            border-bottom: $white;
            display: block;
            width: 100%;
        }
    }

    &__heading {
        position: relative;
        top: 1px;
        // padding: 14px 30px 10px 0;
        padding: 10px 30px 0 0;
        background-color: $white;
        border-top: 1px solid $endocrine-light-blue;
        // color: #9D968D;
        color: #9e9e9e;

        &:after {
            content: "";
            position: absolute;
            top: -1px;
            right: 0;
            width: 20px;
            height: calc(100% + 1px);
            background: linear-gradient(
                to right top,
                $white calc(50% - 1px),
                $endocrine-light-blue 50%,
                $endocrine-light-blue 50%,
                $white calc(50% + 1px)
            );

            @media (max-width: $bp-max-small) {
                display: none;
            }
        }

        h6 {
            @include font(acumin, condensed-bold);
            font-size: rem(17);
            margin: 0;

            @media (max-width: $bp-max-medium) {
                font-size: rem(16);
            }
        }
    }

    &__links {
        li {
            padding-left: 0;
            margin-bottom: 30px;

            h4 {
                display: inline-block;
                color: $endocrine-black;
                letter-spacing: -0.25px;

                &:hover {
                    text-decoration: underline;
                    // color: $endocrine-dark-navy;
                }

                @media (max-width: $bp-max-medium) {
                    font-size: rem(20);
                }

                @media (max-width: $bp-max-small) {
                    font-size: rem(16);
                }
            }

            a:hover {
                text-decoration: none;
            }
        }

        .see-more-arrow {
            display: inline-block;
            border-right: 2px solid $endocrine-purple;
            border-bottom: 2px solid $endocrine-purple;
            transform: rotate(-45deg);
            width: 12px;
            height: 12px;
        }
    }
}
