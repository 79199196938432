.end-select-box {
    position: relative;
    display: block;
    width: 100%;

    h6 {
        text-transform: none;
        letter-spacing: 1px;
        font-size: rem(17);
    }
  
    &__current {
        background-color: #EAEDEF;
        position: relative;
        cursor: pointer;
        outline: none;
        display: flex;
        border-radius: 5px;

        &:focus {
            & + .end-select-box__list {
                opacity: 1;
                animation-name: none;
                .end-select-box__option {
                    cursor: pointer;
                }
            }
        
            .end-select-box__arrow-icon {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &__separator {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 45px;
        width: 1px;
        height: 30px;
        background-color: #98A4AE;
        opacity: 0.3;
    }
  
    &__arrow-icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        width: 15px;
        transition: 0.2s ease;
        filter: invert(69%) sepia(57%) saturate(5565%) hue-rotate(188deg) brightness(93%) contrast(86%);
    }
  
    &__input {
        display: none;
    
        &:checked + .end-select-box__input-text {
            display: block;
        }
    }
  
    &__input-text {
        display: none;
        width: 100%;
        margin: 0;
        padding: 15px;
        font-size: rem(17);
        line-height: 1.2;
    }
  
    &__list {
        position: absolute;
        width: 100%;
        padding: 0;
        list-style: none;
        opacity: 0;
        z-index: 1;
        animation-name: HideList;
        animation-duration: 0.5s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: step-start;
        box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);

        li {
            padding: 0;
        }
    }
  
    &__option {
        display: block;
        padding: 15px;
        background-color: #fff;
        
        &:hover,
        &:focus {
            background-color: lighten(#EAEDEF, 5%);
        }
    }
}

@keyframes HideList {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0);
    }
}
