.end-hero-menu {
    font-weight: bold;
    color: $white;
    position: relative;

    @media (max-width: $bp-max-medium) {
        padding-left: 40px;
        padding-right: 40px;
    }

    &__title {
        margin-bottom: 50px;
        padding-left: 60px;
        padding-right: 60px;
        letter-spacing: -0.3px;

        @media (max-width: $bp-max-large) {
            padding-left: 0;
            padding-right: 0;
            font-size: rem(28);
        }

        @media (max-width: $bp-max-small) {
            font-size: rem(26);
            margin-bottom: 30px;
        }
    }

    a,
    li > span {
        display: block;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        color: $white;
    }

    &__arrow-btn--scrollLeft,
    &__arrow-btn--scrollRight {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: solid $endocrine-light-blue;
        display: inline-block;
        position: absolute;
        top: 50%;
    }

    &__arrow-btn--scrollLeft {
        border-width: 0 0 3px 3px;
        transform: rotate(45deg) translateY(-50%);
        left: -3px;

        @media (max-width: $bp-max-medium) {
            left: 67px;
        }
    }

    &__arrow-btn--scrollRight {
        border-width: 0 3px 3px 0;
        transform: rotate(-45deg) translateY(-50%);
        right: -3px;

        @media (max-width: $bp-max-medium) {
            right: 67px;
        }
    }

    &__list-wrap {
        display: block;
        // overflow-x: scroll;
        margin: 0 60px;
    }

    &__list {
        @include zero-list;
        display: flex;
        flex-wrap: nowrap;

        li:before {
            display: none;
        }

        .slick-list {
            background-color: $endocrine-navy;
            padding-right: 80px;
            position: relative;
        }

        .slick-list.draggable:after {
            background: linear-gradient(
                to left,
                $endocrine-navy 20%,
                $endocrine-navy 0%,
                rgba($endocrine-navy, 0) 100%
            );
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
        }

        .slick-track {
        }

        .slick-arrow {
            border: solid $endocrine-light-blue;
            top: 20%;

            &:before {
                content: "";
            }
        }

        .slick-prev {
            border-width: 0 0 3px 3px;
            transform: rotate(45deg) translateY(-50%);
            left: -63px;

            &.slick-disabled {
                opacity: 0.5;
            }
        }

        .slick-next {
            border-width: 0 3px 3px 0;
            transform: rotate(-45deg) translateY(-50%);
            right: -63px;

            &.slick-disabled {
                opacity: 0.4;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        flex: 0 0 100px;
        padding: 0 16px;
    }

    &__icon {
        align-items: center;
        display: flex;
        flex: none;
        height: 48px;
        width: 48px;

        img {
            flex: none;
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }

    &__label {
        display: block;
        margin-top: 15px;
        font-size: rem(17);

        &:hover {
            text-decoration: underline;
        }
    }

    &__list {
        svg {
            cursor: default;
        }
    }
}
