.end-newsletter-signup {
    margin-left: auto;


    .end-mobile-nav__primary-navigation & {
      margin-left: 0;
      margin-bottom: 50px;
    }

  	@include media($bp-end-header-min-xlarge) {
        // margin-left: 30px;
    }

    &__link {
        display: block;

        .end-mobile-nav__primary-navigation & {
          display: none;
        }

      	@include media($bp-end-header-min-medium) {
            display: none;
        }

    }

    &__input {
        display: none;

        .end-mobile-nav__primary-navigation & {
          display: flex;
          flex-wrap: wrap;
        }

      	@include media($bp-end-header-min-medium) {
            display: flex;
        }

        label {
            width: 80px;
            line-height: 1.1;
            text-align: right;
            display: flex;
            align-items: flex-end;
            margin-right: 10px;
            font-size: 11px;

            .end-mobile-nav__primary-navigation & {
              color: $white;
              font-size: 15px;
              font-weight: 400;
              margin-bottom: 5px;
              width: 100%;
            }
        }

        input {
            @include font(acumin, light);
            font-size: rem(14);
            border: none;
            background-color: $endocrine-gray;
            color: black;
            padding: 8px 6px;
            border-radius: 0;
            height: 100%;
            margin-right: 8px;

            .end-mobile-nav__primary-navigation & {
              margin-bottom: 10px;
              width: 100%;
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(105deg,
                                #5F2167 0%,
                                #5F2167 70%,
                                #9636A3 70%);
        font-family: inherit;
        font-size: 13px;
        border: 0;
        border-radius: 5px;
        cursor: pointer;
        min-height: 33px;
        padding: 0 10px;
        width: 125px;

        &:hover {
          background: linear-gradient(105deg,
                              #ad3ebd 0%,
                              #ad3ebd 70%,
                              #9636A3 70%);
        }

        &-text {
            display: inline-block;
            color: white;
            text-transform: uppercase;
            @include font(acumin, condensed-bold);
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 1.25px;
            border-radius: 10px;
        }

        &-icon-box {
            display: flex;
            align-items: center;
        }
    }
}
