.end-breadcrumbs {
    padding-top: 15px;
    margin-bottom: 40px;
    width: 100%;

    @include media($bp-g-max-large) {
      margin-left: auto;
      margin-right: auto;
      width: calc(100% - 140px);
    }

  	@include media($bp-g-max-medium) {
      width: calc(100% - 80px);
    }

    &__list {
      @include zero-list;
      font-weight: 500;
      font-size: 12px;
    }

    // breadcrumbs item
    &__item {
      display: inline-block;
      margin-bottom: 0;

      &:first-child {
        position: relative;
        top: 3px;


        a {
          color: $hormone-navy;

          svg {
            width: 15px;
            height: 16px;
            position: relative;
            top: 0px;
          }

          &:hover {
            text-decoration: none;
            color: $hormone-dark-blue;;
          }
        }
      }

      &:before {
        display: none;
      }

      a {
        text-decoration: none;
        color: $hormone-black;

        &:hover {
          text-decoration: underline;
          color: $hormone-dark-blue;
        }
      }

      &:first-of-type {
        .end-breadcrumbs__divider {
          position: relative;
          top: -3px;
        }
      }
    }

    &__divider {
      display: inline-block;
      border-right: 2px solid #2067A7;
      border-bottom: 2px solid #2067A7;
      transform: rotate(-45deg);
      width: 9px;
      height: 9px;
      margin-right: 8px;
      margin-left: 6px;
    }
  }
  