$page-banner__caption-offset: 30;

.page-banner {
	position: relative;
	color: $white;
	overflow: hidden;

	&.page-banner__no-image {
		background: $hormone-dark-blue;
		height: auto;
		padding-top: 118px;
		padding-bottom: 62px;

		@media(max-width: $bp-max-medium) {
			padding-top: 80px;
			padding-bottom: 40px;
		}

		@media(max-width: $bp-max-small) {
			padding-top: 60px;
			padding-bottom: 32px;
		}

	  &:before {
	    content: '';
	    width: 100%;
	    height: 100%;
	    z-index: 1;
	    position: absolute;
	    top: 0;
	    left: 0;
	    background: #004B87;
	    opacity: .8;
	  }

	  &:after {
	    content: '';
	    z-index: 2;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    position: absolute;
	    background: transparent url('/assets/img/common/svg-sprite/hero-background_2.svg') no-repeat center -100px;
	    background-size: 3000px 560px;
	  }

		.page-banner__caption {
			bottom: auto;
			position: relative;
		}

		.page-banner__overlay {
			display: none;
		}

		.page-banner__figure {
			width: 100%;
			margin-left: 0;
		}
	}
}

.page-banner__figure {
	margin: 0;

	@media(max-width: $bp-max-medium) {
		width: calc(100% + 200px);
		margin-left: -100px;
	}

	// @media(max-width: $bp-max-small) {
	// 	width: calc(100% + 400px);
	// 	margin-left: -200px;
	// }

	img {
		width: 100%;
		display: block;
		margin: 0 auto;
		object-fit: cover;
	}

	video {
		width: 100%;
		display: block;
		margin: 0 auto;
		object-fit: cover;
	}
}

.has-height .page-banner__figure {
	width: 100%;
	height: 100%;

	picture {
		height: 100%;
	}

	img {
		height: 100%;
	}

	video {
		height: 00%;
	}
}

.page-banner__caption {
		@include horizontal-container-padding;
  	position: absolute;
		z-index: 3;
	  left: 0;
		bottom: 60px;
	  width: 100%;

		@media(max-width: $bp-max-medium) {
      bottom: 40px;
    }

    @media(max-width: $bp-max-small) {
        bottom: 24px;
    }

		@media(max-width: em(374)) {
      left: -20px;
      width: calc(100% + 40px);
    }
}

.page-banner__caption-inner {
	max-width: $max-width;
	margin: 0 auto;
	text-shadow: 2px 4px 16px rgba(0,0,0,0.30);

	p {
		margin-bottom: 0;
	}
}

.page-banner__eyebrow {
	text-transform: uppercase;
	margin-bottom: 0;
	font-weight: 800;
	font-size: 16px;
	letter-spacing: 1.5px;
}

.page-banner__title {
	margin: 0 0 0;
}

.page-banner__subtitle {
	font-weight: 300;
	font-size: 32px;
	letter-spacing: -0.53px;
	line-height: 40px;
}

.page-banner__action {
	display: inline-block;
	color: $white;
	transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
	background-color: darken($white, 10);
	text-decoration: none;
	padding: rem(10) rem(20);

	&:hover {
		background-color: darken($white, 45);
		color: $white;
	}
}

.container--100 {
	.page-banner__caption-inner {
		left: rem($page-banner__caption-offset);
	}
}

.page-banner__overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 180px;
	z-index: 2;
	background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 3%, rgba(0,0,0,0.13) 20%, #000000 100%);

	@media(max-width: $bp-max-medium) {
		height: 120px;
	}

	@media(max-width: $bp-max-small) {
		height: 120px;
	}
}
