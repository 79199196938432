.end-footer-nav {
	display: flex;
	flex-shrink: 0;
	margin-bottom: 30px;

	li {
		&:before {
      		display: none;
    	}
	}

	@media(max-width: $bp-max-medium) {
		flex-wrap: wrap;
}
}

.end-footer-nav__section {
	padding-right: rem(32);
	padding-bottom: rem(32);
	min-width: 10px;
	flex: auto;

	@media(max-width: $bp-max-medium) {
		flex-basis: 33.33333%;
		flex-grow: 0;
	}
	
	@media(max-width: em(700)) {
		flex-basis: 50%;
	}

	@media(max-width: $bp-max-small) {
		flex-basis: 100%;
		flex-grow: 0;
	}
}

.end-footer-nav__heading {
	width: 100%;
	font-weight: 800;
	font-size: rem(15);
	line-height: 1.2;

	a {
		text-decoration: none;
	}
}

.end-footer-nav__col-wrapper {
	display: flex;
}

.end-footer-nav__col {
	@include zero-list;
	margin-top: 22px;
	font-size: 13px;

	@media(max-width: $bp-max-small) {
		margin-top: 12px;
	}

	li {
		padding-left: 0;
		margin-bottom: 14px;
	}

	a {
    	line-height: 1.2;
		text-decoration: none;
		display: block;
	}
}