// end-global-header.scss
// Define global header styles for Endocrine Society site

// general styles

.end-global-header {
    background-color: $white;
    padding: 0;
    width: 100%;

    &__global-nav-background {
        height: 48px;
        width: 100%;
        background: linear-gradient(
            105deg,
            $endocrine-gray 0%,
            $endocrine-gray 50%,
            $white 50%
        );
        margin-bottom: 0;
        position: absolute;
        left: 0;
        top: 0;

        @include media($bp-end-header-min-small) {
            background: linear-gradient(
                105deg,
                $endocrine-gray 0%,
                $endocrine-gray 60%,
                $white 60%
            );
        }

        @include media($bp-end-header-min-medium) {
            background: linear-gradient(
                105deg,
                $endocrine-gray 0%,
                $endocrine-gray 50%,
                $white 50%
            );
        }
    }

    &__top {
        display: flex;
        padding: 8px 0;
        margin-bottom: 20px;
    }

    &__middle {
        border-bottom: solid 1px #bdbdbd;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        padding-bottom: 25px;

        @include media($bp-end-header-min-large) {
            align-items: flex-start;
            margin-bottom: 25px;
            border: 0;
        }
    }

    &__bottom {
        display: none;

        @include media($bp-end-header-min-large) {
            display: block;
            border-bottom: solid 1px #bdbdbd;
            //   padding-bottom: 25px;
        }
    }

    .container {
        margin-bottom: 0;
    }
}
