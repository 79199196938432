.site-logo {
	display: table;

	@media(max-width: 600px) {
		display: block;
	}

	a {
		text-decoration: none;
	}

	img {
		max-width: 100%;
		display: block;
	}
}

.site-logo__link {
	display: table-cell;
	vertical-align: middle;
	padding-right: 38px;

	@media(max-width: 1360px) {
		padding-right: 30px;
	}

	@media(max-width: 600px) {
		display: block;
		padding: 0;
	}
}

.site-logo__branding-title {
	font-weight: 500;
	font-style: italic;
	font-size: rem(15);
	line-height: 1.28;
	color: $hormone-lightest-blue;
	width: 207px;
	display: table-cell;
	vertical-align: middle;
	position: relative;
	top: 0px;

	@media(max-width: 1360px) {
		width: 170px;
		font-size: rem(13);
	}

	@media(max-width: 600px) {
		width: 100%;
		display: block;
		top: 0;
		margin-top: 12px;
		max-width: 353px;
	}
}
