$promo-media-margin: rem(30);

.end-promo {
    background-color: $endocrine-gray;
    padding: 50px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;

    &.is-left,
    &.is-right {
        align-items: top;

        @media (max-width: $bp-max-medium) {
            align-items: flex-start;
        }
    }

    &.is-left {
        @media (min-width: $bp-min-medium) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &.is-right {
        @media (min-width: $bp-min-medium) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .container--70-30 .container__col:last-child & {
        margin-bottom: 90px;

        @media (min-width: $bp-min-large) {
            display: block; //override flex
        }
    }

    .container--70-30 .container__col:last-child & + & {
        margin-top: -90px;
    }

    .container--70-30 .container__col:first-child & {
        margin-bottom: rem(60);

        @media (max-width: $bp-max-small) {
            margin-bottom: rem(5);
        }
    }

    &.is-right &__top-header,
    &.is-left &__top-header {
        @media (min-width: $bp-min-medium) {
            display: none;
        }

        @media (min-width: $bp-min-large) {
            .container--70-30 .container__col:last-child & {
                display: block;
            }
        }
    }

    &__eyebrow {
        @include font(acumin, condensed-bold);
        font-weight: 800;
        font-size: 13px;
        color: #9d968d;
        letter-spacing: 1px;
        text-transform: uppercase;
        width: 100%;
    }

    &__title {
        font-weight: 700;
        font-size: 24px;
        text-align: left;
        line-height: 1.25;
        letter-spacing: -0.25px;
        margin-bottom: 26px;
        width: 100%;

        a {
            color: $endocrine-black;
            display: block;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &__media {
        font-size: 0;
        margin-bottom: 26px;

        a,
        img {
            display: inline-block;
            max-width: 100%;
        }
    }

    &.is-left &__media,
    &.is-right &__media {
        @media (min-width: $bp-min-medium) and (max-width: $bp-max-medium) {
            max-width: 150px;
        }

        @media (min-width: $bp-min-medium) {
            flex: none;
            margin-bottom: 0;
            margin-top: 4px;
            width: calc(50% - #{$promo-media-margin / 2});
        }

        .container--70-30 .container__col:last-child & {
            @media (min-width: $bp-min-large) {
                margin: 0;
                margin-bottom: 26px;
                width: 100%;
            }
        }
    }

    &.is-left &__media {
        @media (min-width: $bp-min-medium) {
            margin-right: $promo-media-margin;
        }
    }

    &.is-right &__media {
        @media (min-width: $bp-min-medium) {
            order: +1;
            margin-left: $promo-media-margin;
            text-align: right;
        }
    }

    &.is-right &__content,
    &.is-left &__content {
        flex-grow: 1;
        width: 100%;

        @media (min-width: $bp-min-medium) {
            width: calc(50% - #{$promo-media-margin / 2});
        }

        @media (min-width: $bp-min-large) {
            .container--70-30 .container__col:last-child & {
                width: 100%;
            }
        }
    }

    &__content-header {
        display: none;
    }

    &.is-right &__content-header,
    &.is-left &__content-header {
        @media (min-width: $bp-min-medium) {
            display: block;
        }

        @media (min-width: $bp-min-large) {
            .container--70-30 .container__col:last-child & {
                display: none;
            }
        }
    }

    &__description {
        max-width: 800px;
        margin: 0 0 22px;
        line-height: 1.47;
        font-size: rem(15);
    }

    &__subtitle {
        @include font(montserrat);
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 13px;
    }

    &__cta {
        &-text {
            font-weight: bold;
            color: $endocrine-dark-navy;
        }

        .see-more-arrow {
            display: inline-block;
            border-right: 2px solid $endocrine-purple;
            border-bottom: 2px solid $endocrine-purple;
            transform: rotate(-45deg);
            width: 8px;
            height: 8px;
        }
    }
}
