
.homepage-promos {
	padding: 0 70px;

	@include media($bp-g-max-large) {
		padding: 0;
	}

	&__inside {
		display: flex;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;

		@include media($bp-g-max-large) {
			display: block;
		}
	}

	&__col {

		&--1 {
			padding: 96px 70px 96px 0;
			width: percentage(8/12);

			@include media($bp-g-max-large) {
				padding-left: 70px;
				width: 100%;
			}

			@media(max-width: $bp-max-medium) {
				padding-left: 40px;
				padding-right: 40px;
			}

			@media(max-width: $bp-max-small) {
				padding-top: 48px;
				padding-bottom: 48px;
			}

			.content-list {
				padding-bottom: 0;
			}

			.content-list__item {
				margin-bottom: rem(70);

				@media(max-width: $bp-max-small) {
					margin-bottom: rem(40);
				}

				&:last-child {
		      margin-bottom: 0;
		    }
			}
		}

		&--2 {
			background: #571C52;
			color: $white;
			position: relative;
			z-index: 0;
			padding: 90px 0 90px 70px;
			width: percentage(4/12);
			display: flex; // to vertically center the promo inside

			@include media($bp-g-max-large) {
				padding-left: 70px;
				padding-right: 70px;
				width: 100%;
				overflow: hidden;
			}

			@media(max-width: $bp-max-medium) {
				padding-left: 40px;
				padding-right: 40px;
			}

			@media(max-width: $bp-max-small) {
				padding-top: 48px;
				padding-bottom: 48px;
			}

			&:after {
				content: '';
				background: #571C52;
				height: 100%;
				top: 0;
				left: 100%;
				width: 100vw;
				position: absolute;
				z-index: -2;
			}

			&:before {
				content: '';
				width: 636px;
				height: 610px;
				position: absolute;
				top: 0;
				left: 0;
				background: transparent url('/assets/img/common/home-purple-bg.jpg') no-repeat center center;
				background-size:cover;
				z-index: -1;
			}

			.promo {
				margin-top: auto;
				margin-bottom: auto;
				position: relative;
				top: -20px;
				padding: 0;
				background: transparent;
				width: 100%;
				flex: auto;
				flex-basis: 100%;

				@include media($bp-g-max-large) {
					top: 0;
				}

				&__title {
					font-size: 32px;
					letter-spacing: -0.75px;

					a {
						color: #fff;
					}
				}

				&__description {
					font-size: rem(16);
					line-height: 1.5;
					color: #fff;
					margin-top: 30px;
				}
			}

			.btn--box-arrow {
		    color: $hormone-light-blue;

		    &:hover {
		      color: $hormone-lightest-blue;

		      &:after {
		        background-color: $hormone-light-blue;
		      }
		    }
		  }
		}
	}
}
