$end-filtered-item-media-margin: rem(30);

.end-filtered-item {
    // align-items: left;
    display: flex;
    // flex-wrap: wrap;

    @media (max-width: em(960)) {
        .end-filtered-item__media {
            display: none;
        }
    }
}

.end-filtered-item__media {
    flex: none;
    width: 160px;

    img {
        display: block;
    }
}

.end-filtered-item__content {
    flex: auto;
    padding-left: 190px;
    width: 100%;

    @media (max-width: em(960)) {
        padding-left: 0;
    }
}

.end-filtered-item__media + .end-filtered-item__content {
    padding-left: 30px;

    @media (max-width: em(960)) {
        padding-left: 0;
    }
}

.end-filtered-item__eyebrow {
    margin-bottom: 5px;
    margin-top: 2px;

    //  Exceed 100% width to fix IE bug where the eyebrow does not trigger flex-wrap
    //  For some reason, the computed layout box slightly exceeds the value of width: 100%
    width: 100.5%;

    span {
        margin-right: rem(8);
    }
}

.end-filtered-item__category {
    @include font(acumin, condensed-bold);
    // font-weight: 800;
    letter-spacing: 1.25px;
    background-color: #418fde;
    font-size: rem(12);
    padding: 1px 8px 2px;
    color: $white;
    text-transform: uppercase;
}

.end-filtered-item__type {
    color: $grey-500;
    font-weight: normal;
    font-size: rem(16);
}

.end-filtered-item__title {
    font-size: rem(30);
    letter-spacing: -0.5px;

    @media (max-width: $bp-max-medium) {
        font-size: rem(28);
        letter-spacing: 0;
    }

    a {
        text-decoration: none;
        color: $black;

        &:hover,
        &:active {
            text-decoration: underline;
            color: $black;
        }
    }
}

.end-filtered-item__description {
    max-width: 800px;
    font-size: rem(16);
    line-height: 1.5;
}
