.end-lp-promo-grid {
    @include component-whitespace;
    margin-bottom: 60px;
    padding-bottom: 50px;
    padding-left: 60px;
    padding-right: 60px;

    .container--70-30 .container__col:first-child &,
    .container--30-70 .container__col:last-child & {
        @include media($bp-g-max-large) {
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
            padding-right: 0;
            width: calc(100% - 140px);
        }

        @include media($bp-g-max-medium) {
            width: calc(100% - 80px);
        }
    }

    &__container {
        @media (min-width: 640px) {
            display: flex;
        }
    }

    &__content {
        @media (min-width: 640px) {
            flex: none;
            margin-left: 60px;
            width: calc(50% - 30px);

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
