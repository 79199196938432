
.ad {
	margin-bottom: 70px;
	margin-top: 70px;

	@media(max-width: 470px) {
		margin-top: 48px;
		margin-bottom: 32px;
	}

	img {
		max-width: 100%;
		margin: 0 auto;
	}

	&.ad--landing {
		margin-top: 0;

		@include media($bp-g-max-large) {
			margin-top: 70px;
		}

		@media(max-width: 470px) {
			margin-top: 48px;
		}
	}

	.interior-container__col:last-child & {
		margin-top: 60px;
		padding-right: 60px;

		@media(max-width: 1200px) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @media(max-width: 910px) {
      padding-left: 40px;
      padding-right: 40px;
    }

		img {
			@media(max-width: 1200px) {
				margin-left: 0;
			}
		}
	}
}

.ad__desktop-rail {
	display: block;

	@media(max-width: 1200px) {
		display: none;
	}
}

.ad__desktop {
	display: block;

	@media(max-width: 868px) {
		display: none;
	}

	.ad--detail & {
		display: none;

		@media(max-width: 1200px) {
			display: block;
		}

		@media(max-width: 868px) {
			display: none;
		}
	}
}

.ad__tablet {
	display: none;

	@media(max-width: 868px) {
		display: block;
	}

	@media(max-width: 548px) {
		display: none;
	}
}

.ad__mobile {
	display: none;

	@media(max-width: 548px) {
		display: block;
	}
}
