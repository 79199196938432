
.endocrinologist-list-item {
	&__title {
		font-size: rem(32);
		letter-spacing: -0.75px;

		@media(max-width: $bp-max-medium) {
			font-size: rem(28);
			letter-spacing: 0;
		}
	}

	&__description {
		max-width: 800px;
		font-size: rem(16);
		line-height: 1.5;
	}

	&__area {
		display: inline-block;
		vertical-align: top;
		margin-right: 32px;

		&-title {
			font-weight: 800;
			font-size: 14px;
			letter-spacing: 1.5px;
			line-height: 1.29;
			text-transform: uppercase;
		}

		&-description {
			font-size: rem(15);
			line-height: 1.5;
		}
	}

	&__top {
		display: flex;
		width: 100%;

		@media(max-width: em(660)) {
			display: block;
		}
	}

	&__contact {
		margin-bottom: 1.1875rem;
		padding-left: 40px;

		@media(max-width: em(660)) {
			padding-left: 0;
		}

		p {
			font-size: rem(16);
			line-height: 1.5;
			margin: 0;

			strong {
				font-weight: 800;
				font-size: 14px;
				letter-spacing: 1.5px;
				line-height: 1.29;
				text-transform: uppercase;
			}
		}
	}
}
