.pagination {
    margin-top: 40px;
    margin-bottom: 0;

    &__container {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        margin-left: -3px;
        margin-top: -3px;
        padding: 0;
    }

    &__link {
        flex: none;
        padding: 0;
        margin-left: 3px;
        margin-top: 3px;

        // remove any custom bullets
        &:before {
            display: none;
        }

        a {
            display: inline-block;
            vertical-align: middle;
            padding: 2px 10px;
            color: white;
            text-decoration: none;
            border: none;
            background-color: $hormone-blue;
            transition: background-color 0.35s;
            font-size: rem(16);
            font-weight: bold;
            position: relative;

            &:hover {
                background: $hormone-blue-light;
            }
        }

        &.PagedList-ellipses a,
        &--ellipses a {
            background-color: transparent;
            color: inherit;

            &:hover {
                background-color: transparent;
            }
        }

        &--first {
        }

        &--previous {
        }

        &--next {
        }

        &--last {
        }

        &--disabled a {
            cursor: default;
        }

        &.active a,
        &--active a {
            background-color: $hormone-dark-navy;
            cursor: default;

            &:hover {
                background-color: $hormone-dark-navy;
            }
        }
    }

    &__first-controls {
    }

    &__last-controls {
    }
}
