.product-details {
    margin-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;

    .container--70-30 .container__col:first-child &,
    .container--30-70 .container__col:last-child & {
        @include media($bp-g-max-large) {
            margin-left: auto;
            margin-right: auto;
            width: calc(100% - 140px);
            padding-left: 0;
            padding-right: 0;
        }

        @include media($bp-g-max-medium) {
            width: calc(100% - 80px);
        }
    }

    &__overview {
        li {
            padding-left: 0;
        }
    }

    &__body {
        padding: 0;
        width: 100% !important;
    }
}
