.end-page-banner {
    // background-image: url(/assets/img/common/miami-beach-banner.png);
    // background-size: cover;
    // background-position: 50% 75%;
    // background-repeat: no-repeat;
    // width: 100%;
    height: 400px;
    transform: translateY(-1px);

    &__figure {
        margin: 0;

        img {
            height: 100%;
            width: 100%;
            display: block;
            object-fit: cover;
        }
    }
    &.has-height {
        height: 100vh; // something large for max-height to restrict
        max-height: 400px;

        @media (max-width: $bp-max-medium) {
            height: auto;
            max-height: none !important; // reset inline max-height set by Sitecore
        }

        .end-page-banner__figure {
            width: 100%;
            height: 100%;

            picture {
                height: 100%;
            }

            img {
                height: 100%;
            }
        }
    }
}
