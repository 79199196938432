.CoveoSearchInterface:not(.increase-specificity).coveo-small-facets
    .coveo-facet-dropdown-header {
    color: white;
    border: 1px solid $hormone-blue;
    background-color: $secondary-btn-bg;
    padding: 13px 30px 15px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(14);
    font-weight: 700;
    height: auto;
    letter-spacing: 0;
    line-height: 1.58;
}

.CoveoSearchInterface:not(.increase-specificity) .coveo-search-section {
    margin-top: 15px;
}

.CoveoSearchInterface:not(.increase-specificity) .coveo-results-column {
	padding: 10px 10px 10px 55px;
}

.CoveoSearchbox:not(.increase-specificity) {
    padding-left: 10px;
	padding-top: 8px;

    .magic-box {
        border: 2px solid #cbd8e0;
        border-right: 0;
        border-radius: 0;
    }

    .CoveoSearchButton {
        border: 2px solid #cbd8e0;
        border-radius: 0;
        color: $endocrine-black;
        height: 52px;

        &:hover {
            background-color: $hormone-blue;
            border-color: $hormone-blue;
            color: white;

            .coveo-magnifier-circle-svg {
                fill: white;
            }
        }

        .coveo-magnifier-circle-svg {
            transition: fill 200ms ease;
        }

        .coveo-search-button-svg {
            color: currentColor;
        }
    }
}

.CoveoFacet:not(.increase-specificity) {
    .coveo-facet-value-label-wrapper {
        input[type="checkbox"]:checked + .coveo-facet-value-checkbox::after {
            display: block;
        }

        input[type="checkbox"]:focus + .coveo-facet-value-checkbox::before {
            border-color: $hormone-blue;
        }

        .coveo-facet-value-checkbox {
            border: 0;
            border-radius: 0;
            height: 22px;
            position: relative;
            width: 22px;

            &:before {
                content: "";
                width: 22px;
                height: 22px;
                position: relative;
                display: inline-block;
                background: #fff;
                top: 0;
                border: 2px solid #cbd8e0;
            }

            &:after {
                display: none;
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.684.02c.307-.056.586.009.835.195.25.186.403.432.461.74.058.306-.01.59-.202.85l-5.96 8.428c-.193.242-.438.39-.735.447a1.01 1.01 0 0 1-.82-.196c-.154-.111-.558-.507-1.21-1.186A99.866 99.866 0 0 1 1.21 7.331l-.893-.949A1.135 1.135 0 0 1 0 5.572c0-.316.115-.585.346-.809.23-.223.499-.33.806-.32.307.009.566.134.777.376l2.852 2.986L9.964.465a1.19 1.19 0 0 1 .72-.446z' fill='%23007DBA' fill-rule='evenodd'/%3E%3C/svg%3E");
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center center;
                width: 12px;
                height: 11px;
                background-size: cover;
                position: absolute;
                left: 5px;
                top: 6px;
            }

            svg {
                display: none;
            }
        }
    }
	.coveo-facet-footer.coveo-facet-empty {
		display: block;
	}
}

.CoveoLogo:not(.increase-specificity) {
    display: none;
}
